import Container from '../Container'
import { useState, useEffect} from "react"
import { Box, Divider, Grid, TextField, MenuItem, InputLabel, Select, SelectChangeEvent, Typography} from '@mui/material'
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import {Button as MuiButton} from '@mui/material'
import Button from '../Button';
import { DataProvincia, DentalRecordsState, DataDepartamento, DataLocalidad, DetailPieceOpturacion, DentalRecords } from "../../interfaces/DentalRecords";
import Odontogram from "./Odontogram/Odontogram";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'moment/locale/es';
import moment from 'moment';
import ModalImportOdontogram from './ModalImportOdontogram/ModalImportOdontogram';
import ModalCheckHealthyMounth from './ModalCheckHealthyMounth/ModalCheckHealthyMounth';
import { useParams } from 'react-router-dom';

const TextFieldStyled = styled(TextField)(() => ({
    '.MuiInputLabel-root': {
        color: '#3B72FF',
        'span': {
            color: 'red'
        }
    },
}));

const InputLabelStyled = styled(InputLabel)(() => ({
    color: '#3B72FF',
    'span': {
        color: 'red'
    }
}));


interface Props {
    state: DentalRecordsState
    handleChangeInputAdd: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleClickCheck: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleClickCheckHealthyMouth: (event: React.ChangeEvent<HTMLInputElement>) => void
    saveDentalRecords: (insuranceId: number) => void
    handleChangeSelect: (event:SelectChangeEvent<string | number>) => void
    handleChangeUbicacion: (value: any, name: string) => void
    getLocalidades: (state: string, department: string) => void
    pushProviders: (pieceId: number, providerType: number, description: string, details?:DetailPieceOpturacion ) => void
    handleChangeDatePicker: (value: string, name: string) => void
    provinciaSelected: DataProvincia | undefined
    departamentSelected: DataDepartamento | undefined
    localidadSelected: DataLocalidad | undefined
    localidadesFormat: any[]
    insuranceId: number
    customGetDentalRecordsById: (id: number) => void
    providerByAffiliateCode:  DentalRecords[]
    setDentalRecordsId: (id: number) => void 
    dentalRecordsId: number
    modifyDentalRecord?: (dentalRecordId: number, status: number) => void
    setModalImportOdontogram?: (boolean: boolean) => void 
}

const ViewOdontogram = (props: Props) => {
     const {id, status} = useParams();
    const [modalCheckHealthyMounth ,setModalCheckHealthyMounth ] = useState(false)

    const fieldsTreatmentsRequired = (props.state.items.treatmentsItems.pieceId && props.state.items.treatmentsItems.faceId && props.state.items.treatmentsItems.code) || props.state.items.treatments.length
    const fieldsRequiredInAttentionSheet = fieldsTreatmentsRequired && (props.state.professionalItems.provincialEnrollment || props.state.items.professionalDetail.enrollmentType) && props.state.items.professionalDetail.professionalLastname && props.state.items.professionalDetail.professionalName 
    useEffect(() => {
        if(!fieldsRequiredInAttentionSheet){
            setMessageAttentionSheetRequired('')
        }
    }, [props.state.providerDetail.length])

    const [messageAttentionSheetRequired, setMessageAttentionSheetRequired] = useState('')
    const [messageOdontogramRequired, setMessageOdontogramRequired] = useState('')

    if(Number(props.localidadSelected?.postalCode) !== 0){
        props.state.affiliateData.postalCode = props.localidadSelected?.postalCode ? props.localidadSelected.postalCode : ''
    }

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        // si tiene seteados todos los datos obligatorios de la ficha de atencion y del odontograma lo guardo
        if(fieldsRequiredInAttentionSheet){


            if( props.state.providerDetail.length == 0 && !props.state.items.healthyMouth) {
               return setMessageOdontogramRequired("Debe completar el odontograma o chequear Boca Sana")
            }
            setMessageOdontogramRequired('')
            if(props?.modifyDentalRecord) {
                props.modifyDentalRecord(Number(id),Number(status) )
            }
            else{
                props.saveDentalRecords(props.insuranceId);
            }
        } else {
            setMessageAttentionSheetRequired('Para guardar los cambios primero debes completar la ficha de atención')
        }
    }
    return <Container
        child={
            <>
            <Box 
                component='form' 
                onSubmit={handleSubmit}
            >
                <h4>Datos Personales del Paciente</h4>
                <Divider sx={{borderColor: '#64AAB2', mb: 2}} />

                <Grid container spacing={3} mb={3}>
                    <Grid item container xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            label="Nombre"
                            name='name'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.name}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item container xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            label="Apellido"
                            name='lastname'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.lastname}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item container xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            label="N° Afiliado"
                            name='affiliateId'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.affiliateId}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item container xs={6} sm={6} md={2}> 
                        <FormControl fullWidth variant='outlined' required>
                            <InputLabelStyled>Tipo de Documento</InputLabelStyled>
                            <Select
                                label="Tipo de Documento"
                                name='documentTypeId'
                                value={props.state.affiliateData.documentType ? props.state.affiliateData.documentType : ''} 
                                onChange={props.handleChangeSelect}
                            >
                                {props.state.documentTypesList.map((item) => (
                                    <MenuItem key={item.documentTypeId} value={item.documentTypeId}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item container xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="N° de Documento"
                            name='documentValue'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.documentValue}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item container xs={6} sm={6} md={2}>
                        <FormControl fullWidth variant='outlined' required>
                            <InputLabelStyled>Sexo</InputLabelStyled>
                            <Select
                                label="Sexo"
                                name='sexTypeId'
                                value={props.state.affiliateData.sexTypeId ? props.state.affiliateData.sexTypeId : ''} 
                                onChange={props.handleChangeSelect}
                            >
                                {props.state.sexTypesList.map((item) => (
                                    <MenuItem key={item.sexTypeId} value={item.sexTypeId}>
                                        {item.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item container xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="Teléfono / Celular"
                            name='phone'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.phone}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item container xs={6} sm={2} md={2}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Fecha de Nacimiento"
                                value={props.state.affiliateData.bornDate ? props.state.affiliateData.bornDate : null}
                                onChange={(newValue) => props.handleChangeDatePicker(moment(newValue).format('YYYY-MM-DD'), 'bornDate')}
                                renderInput={(params) => <TextFieldStyled {...params} required variant='outlined' fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} sm={2} md={2}>
                        <FormControl fullWidth variant='outlined' required>
                            <InputLabelStyled>Condición Fiscal</InputLabelStyled>
                            <Select
                                label="Condición Fiscal"
                                name='ivaTypeId'
                                value={props.state.affiliateData.ivaTypeId ? props.state.affiliateData.ivaTypeId : ''} 
                                onChange={props.handleChangeSelect}
                            >
                                {props.state.ivaTypesData.map((item) => (
                                    <MenuItem key={item.ivaTypeId} value={item.ivaTypeId}>
                                        {item.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                value={{
                                    statesId: props.provinciaSelected?.statesId ? props.provinciaSelected.statesId : 0,
                                    name: props.provinciaSelected?.name ? props.provinciaSelected.name : ''
                                }}
                                onChange={(event, newValue) =>
                                    props.handleChangeUbicacion(newValue?.statesId ? newValue.statesId : 0, "state")
                                }
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                options={props.state.provincias}
                                renderInput={(params) => (
                                    <TextFieldStyled
                                        {...params}
                                        key={params.id}
                                        required
                                        label="Provincia"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                value={{
                                    departmentsId: props.departamentSelected?.departmentsId ? props.departamentSelected.departmentsId : 0,
                                    name: props.departamentSelected?.name ? props.departamentSelected.name : ''
                                }}
                                onChange={(event, newValue) =>
                                    props.handleChangeUbicacion(newValue?.departmentsId ? newValue.departmentsId : 0, "department")
                                }
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                options={props.state.departamentos}
                                renderInput={(params) => (
                                    <TextFieldStyled
                                        {...params}
                                        key={params.id}
                                        required
                                        label="Partido"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                value={{
                                    townsId: props.localidadSelected?.townsId ? props.localidadSelected.townsId : 0,
                                    name: props.localidadSelected?.name ? props.localidadSelected.name.charAt(0) + props.localidadSelected.name.slice(1).toLowerCase() : ''
                                }}
                                onOpen={!props.state.localidades.length ? () => props.getLocalidades(props.state.affiliateData.state, props.state.affiliateData.department) : () => {}}
                                onChange={(event, newValue) =>
                                    props.handleChangeUbicacion(newValue?.townsId ? newValue.townsId : 0, "town")
                                }
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                options={props.localidadesFormat}
                                renderInput={(params) => (
                                    <TextFieldStyled
                                        {...params}
                                        key={params.id}
                                        required
                                        label="Localidad"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item container xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            label="Calle"
                            name='street'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.street}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="Número"
                            name='streetNumber'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.streetNumber && props.state.affiliateData.streetNumber !== "0" ? props.state.affiliateData.streetNumber : ''}
                            onChange={props.handleChangeInputAdd}
                        />
                        <FormControlLabel control={<Checkbox onChange={props.handleClickCheck} checked={props.state.affiliateData.streetNumber === 'S/N'} />} label="Sin Número" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="Piso"
                            name='floor'
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.floor && props.state.affiliateData.floor !== "0" ? props.state.affiliateData.floor : ''}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="Depto"
                            name='apartment'
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.apartment && props.state.affiliateData.apartment !== "0" ? props.state.affiliateData.apartment : ''}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="Código Postal"
                            name='postalCode'
                            required
                            type='number'
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.postalCode ? props.state.affiliateData.postalCode : ' '}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                </Grid>
                
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'end',
                }}>
                <TextFieldStyled
                            label="Cantidad de Piezas"
                            name='amountPieces'
                            required
                            type='number'
                            variant="outlined"
                            size='small'
                            sx={{mr:2 ,mt:1}}
                            value={props.state.items.amountPieces}
                            onChange={props.handleChangeInputAdd}
                />
                      <FormControlLabel control={<Checkbox onChange={(evt) => {
                        evt?.target?.checked ? setModalCheckHealthyMounth(evt?.target?.checked) : props.handleClickCheckHealthyMouth(evt) }} checked={props.state.items.healthyMouth } />}   label="Boca Sana" />
                    
                    <MuiButton variant='contained' disabled={!props?.setModalImportOdontogram} sx={{
                        alignSelf: 'flex-end'
                       
                    }} onClick={()=>{ if(props?.setModalImportOdontogram) props?.setModalImportOdontogram(true)}}>Importar Odontograma</MuiButton>
                 
                  
                </Box>
                  <Box id="capture" sx={{width: '100%', marginTop: 7}}>
                        <Odontogram 
                            pushProviders={props.pushProviders}
                            providers={props.state.listProviderTypes} 
                            bornDate={props.state.affiliateData.bornDate}
                        />
                    </Box>
                <Grid container justifyContent='center' spacing={2} mt={3}>
                    <Grid item xs={4} sm={4} md={4}>
                        <Button 
                            child='Guardar' 
                            type="submit"
                            variant='contained' 
                            color='primary'
                        />
                    </Grid>
                </Grid>
                {messageAttentionSheetRequired ? <Typography color='error' align="center" mt={2}>{messageAttentionSheetRequired}</Typography> : null}
                {messageOdontogramRequired ? <Typography color='error' align="center" mt={2}>{messageOdontogramRequired}</Typography> : null}
            </Box>
   
             <ModalCheckHealthyMounth 
                openModal={modalCheckHealthyMounth}
                handleCloseModal={() => setModalCheckHealthyMounth(!modalCheckHealthyMounth)} 
                handleClickCheckHealthyMouth={props.handleClickCheckHealthyMouth}/>
             </>
        }
       
    />
}

export default ViewOdontogram