import { useContext, useEffect } from 'react';
import Tooth from './Tooth';
import DentalRecordsContext from '../../context/DentalRecords/DentalRecordsContext';
const types= [
    {
        "description": "Pieza no erupcionada",
        "providerTypesId": 1,
        key : "piezaNoErupcionada"  
     },
    {
        "description": "Extracción",
        "providerTypesId": 2,
        key : "extraccion"    
    },
    {
        "description": "Pieza ausente",
        "providerTypesId": 3,
        key : "piezaAusente"    
    },
    {
        "description": "Prótesis fija",
        "providerTypesId": 4,
        key : "protesisFija"    
    },
    {
        "description": "Prótesis removible",
        "providerTypesId": 5,
        key : "protesisRemovible"    
    },
    {
        "description": "Corona",
        "providerTypesId": 6,
        key : "corona"    
    },
    {
        "description": "Endodoncia",
        "providerTypesId": 7,
        key : "endodoncia"    
    },
    {
        "description": "Obturacion",
        "providerTypesId": 8,
        key : "opturacion"    
    },
    {
        "description": "Implante",
        "providerTypesId": 9,
        key : "implante"    
    }
]
function Teeth({ start, end, x, y, handleChange, providers, pushProviders , disabled}) {
    let tooths = getArray(start, end);
    const { 
        state,
        setProviders,
        cleanProviders,
        changeStateLoadProvider
    } = useContext(DentalRecordsContext)

    useEffect(() => {
        // creo array de piezas dentales
        const loadProvider = state?.items?.loadProvider
        if(loadProvider) cleanProviders();
        const provider = state?.items?.providers;
        const initialStatesProviders = state?.providerState;
        if(state?.providerState?.length >= 8 || !state?.dentalDetail?.pieces || state?.dentalDetail?.pieces?.length == 0 ){
      if (Array.isArray( provider)) {
         setProviders(initialStatesProviders.map((array1)=> {
             return array1?.map((details) => {
                   const piece = provider.find(i => i.pieceId === details.pieceId);
                    if(piece){
                      const type= types.find(({providerTypesId}) =>piece?.providerTypeId == providerTypesId );
                      if(type?.providerTypesId == 8){
                        let faces= {}
                        piece?.faces?.forEach((face) => faces[face.facesId] = {...face, active:true})
                        if(loadProvider) pushProviders(details?.pieceId, type.providerTypesId,type.description, faces,piece?.required, piece?.providersId )
                            return {
                            ...details,
                            extraccion: 0,
                            piezaAusente: 0,
                            protesisFija: 0,
                            protesisRemovible: 0,
                            corona: 0,
                            piezaNoErupcionada: 0,
                            endodoncia : 0,
                            opturacion : 0,
                            implante : 0,
                            saveTreatment:true,
                            prestacionExistenteSelected:!piece?.required ? type?.providerTypesId: null,
                            prestacionRequeridaSelected:piece?.required ? type?.providerTypesId: null,
                            [type?.key]: piece?.required ? 2: 1, 
                            faces: piece?.faces
                        }
                      }
                       if(loadProvider) pushProviders(details?.pieceId, type.providerTypesId,type.description, {}, piece?.required , piece?.providersId)
                       return {
                         ...details,
                        extraccion: 0,
                         piezaAusente: 0,
                         protesisFija: 0,
                         protesisRemovible: 0,
                         corona: 0,
                         piezaNoErupcionada: 0,
                         endodoncia : 0,
                         opturacion : 0,
                         implante : 0,
                         saveTreatment:true,
                         prestacionExistenteSelected: !piece?.required ? type?.providerTypesId: null,
                         prestacionRequeridaSelected: piece?.required ? type?.providerTypesId: null,
                         [type?.key]: piece?.required ? 2: 1
                       }
                    }
                    if(state?.items?.loadProvider){
                        return {
                            ...details,
                            extraccion: 0,
                            piezaAusente: 0,
                            protesisFija: 0,
                            protesisRemovible: 0,
                            corona: 0,
                            piezaNoErupcionada: 0,
                            endodoncia : 0,
                            opturacion : 0,
                            implante : 0,
                            prestacionExistenteSelected:null,
                            prestacionRequeridaSelected:null
                        }
                    }
                    else {
                        return details
                    }
                })}));

             if(loadProvider) changeStateLoadProvider(false)
            }
            return ;
        }
        initialStatesProviders.push(tooths.map((tooth) => {
        //recorro array de piezas e igualo al array de tooth para cargar su correspondiente pieceId
        const pieceArr = state.dentalDetail.pieces.find(i => i.pieceNumber === tooth);
        const piece = provider.find(i => i.pieceNumber === tooth);
        const type= types.find(({providerTypesId}) =>piece?.providerTypeId == providerTypesId );
        if(type){
            if(type?.providerTypesId == 8){
                return {
                    pieceId: pieceArr.piecesId,   
                    pieceNumber: tooth,
                    extraccion: 0,
                    piezaAusente: 0,
                    protesisFija: 0,
                    protesisRemovible: 0,
                    corona: 0,
                    piezaNoErupcionada: 0,
                    endodoncia : 0,
                    opturacion : 0,
                    implante : 0,
                    saveTreatment:true,
                    prestacionExistenteSelected: !piece?.required ? type?.providerTypesId: null,
                    prestacionRequeridaSelected: piece?.required ? type?.providerTypesId: null,
                    [type?.key]: piece?.required ? 2: 1, 
                    faces: piece?.faces
                }
            }
            return {
                pieceId: pieceArr.piecesId,   
                pieceNumber: tooth,
                extraccion: 0,
                piezaAusente: 0,
                protesisFija: 0,
                protesisRemovible: 0,
                corona: 0,
                piezaNoErupcionada: 0,
                endodoncia : 0,
                opturacion : 0,
                implante : 0,
                saveTreatment:true,
                prestacionExistenteSelected: !piece?.required ? type?.providerTypesId: null,
                prestacionRequeridaSelected: piece?.required ? type?.providerTypesId: null,
                [type?.key]: piece?.required ? 2: 1, 
            }
        }
        return {
            pieceId: pieceArr.piecesId, 
            pieceNumber: tooth,
            extraccion: 0,
            piezaAusente: 0,
            protesisFija: 0,
            protesisRemovible: 0,
            corona: 0,
            piezaNoErupcionada: 0,
            endodoncia : 0,
            opturacion : 0,
            implante : 0,
            prestacionExistenteSelected:null,
            prestacionRequeridaSelected:null
        }}))

      setProviders(initialStatesProviders)
      if(initialStatesProviders?.length >= 8)  settingProviders(setProviders, provider, initialStatesProviders, loadProvider, pushProviders, state, changeStateLoadProvider )
    }, [state?.items?.providers, state?.dentalDetail?.pieces ])
    return (
        //recorro array de piezas dinamicas para cargar pieceId correspondiente a su pieceNumber
        tooths.map((i) => {
        const pieceArr = state.dentalDetail.pieces.find(item => item.pieceNumber === i)
            return (
                <Tooth onChange={handleChange}
                    key={pieceArr?.piecesId}
                    pieceId={pieceArr?.piecesId}
                    number={pieceArr?.pieceNumber}
                    positionY={y}
                    positionX={Math.abs((i - start) * 25) + x}
                    providers={providers}
                    pushProviders={pushProviders}
                    disabled={disabled}
                    faces= {pieceArr?.faces}
                />
            )
        }
        )
    )
}
const settingProviders = (setProviders, provider, initialStatesProviders, loadProvider, pushProviders, state, changeStateLoadProvider ) =>{
   
    if (Array.isArray( provider)) {
         setProviders(initialStatesProviders.map((array1)=> {
             return array1?.map((details) => {
                   const piece = provider.find(i => i.pieceId === details.pieceId);
                    if(piece){
                      const type= types.find(({providerTypesId}) =>piece?.providerTypeId == providerTypesId );
                      if(type?.providerTypesId == 8){
                        let faces= {}
                        piece?.faces?.forEach((face) => faces[face.facesId] = {...face, active:true})
                        if(loadProvider) pushProviders(details?.pieceId, type.providerTypesId,type.description, faces,  piece?.required,  piece?.providersId)
                            return {
                            ...details,
                            extraccion: 0,
                            piezaAusente: 0,
                            protesisFija: 0,
                            protesisRemovible: 0,
                            corona: 0,
                            piezaNoErupcionada: 0,
                            endodoncia : 0,
                            opturacion : 0,
                            implante : 0,
                            saveTreatment:true,
                            prestacionExistenteSelected:!piece?.required ? type?.providerTypesId: null,
                            prestacionRequeridaSelected:piece?.required ? type?.providerTypesId: null,
                            [type?.key]: piece?.required ? 2: 1, 
                            faces: piece?.faces
                        }
                      }
                       if(loadProvider) pushProviders(details?.pieceId, type.providerTypesId,type.description, {}, piece?.required ,  piece?.providersId)
                       return {
                         ...details,
                        extraccion: 0,
                         piezaAusente: 0,
                         protesisFija: 0,
                         protesisRemovible: 0,
                         corona: 0,
                         piezaNoErupcionada: 0,
                         endodoncia : 0,
                         opturacion : 0,
                         implante : 0,
                         saveTreatment:true,
                         prestacionExistenteSelected: !piece?.required ? type?.providerTypesId: null,
                         prestacionRequeridaSelected: piece?.required ? type?.providerTypesId: null,
                         [type?.key]: piece?.required ? 2: 1
                       }
                    }
                    if(state?.items?.loadProvider){
                        return {
                            ...details,
                            extraccion: 0,
                            piezaAusente: 0,
                            protesisFija: 0,
                            protesisRemovible: 0,
                            corona: 0,
                            piezaNoErupcionada: 0,
                            endodoncia : 0,
                            opturacion : 0,
                            implante : 0,
                            prestacionExistenteSelected:null,
                            prestacionRequeridaSelected:null
                        }
                    }
                    else {
                        return details
                    }
                })}));

             if(loadProvider) changeStateLoadProvider(false)
            }
            return ;
        
}
function getArray(start, end) {
    if (start > end) return getInverseArray(start, end);

    let list = [];
    for (var i = start; i <= end; i++) {
        list.push(i);
    }

    return list;
}

function getInverseArray(start, end) {
    let list = [];

    for (var i = start; i >= end; i--) {
        list.push(i);
    }

    return list;
}

export default Teeth;