import { OfficesState, OfficesItems } from '../../../interfaces/Offices';
import { CellPhoneFormat } from '../../../utils/CellPhoneFormat';

type Action = {type: 'getOffices', payload: OfficesItems[]}
    |{type: 'getOfficesById', payload: OfficesItems}
    | {type: 'clearConsultorioValues', payload: OfficesItems}
    | {type: 'openConfirm', payload: boolean}
    | {type: 'clearFilter'}
    | {type: 'getProvincias', payload: []}
    | {type: 'getDepartamentos', payload: []}
    | {type: 'getLocalidades', payload: []}
    | {type: 'getAllLocalidades', payload: []}
    | {type: 'changeUbicacion', payload: {name: string, value: string}}
    | {type: 'handleChangeInputConsultorio', payload: {name: string, value: string}}
    | {type: 'setLoading', payload: boolean}
    | {type: 'handleChangeHasXRay', payload: boolean | string}
    | {type: 'handleClickCheck', payload: boolean}
    | {type: 'setLatAndLng', payload: string}
    | {type: 'openAlert', payload: boolean}
    | {type: 'loadingDatosDemograficos', payload: boolean}
    | {type: 'loadingAllDatosDemograficos', payload: boolean}
    | {type: 'messageError', payload: string[]}
    | {type: 'changeDatePicker', payload: {name: string, value: string}}

export default function OfficesReducer (state: OfficesState, action: Action): OfficesState {
    switch (action.type) {
        case 'getOffices':
            return { 
                ...state, 
                offices: action.payload,
            }
        case 'getOfficesById':
            return { 
                ...state, 
                officesItems: action.payload,
            }
        case 'clearConsultorioValues':
            return { 
                ...state,
                officesItems: action.payload
            }
        case 'openConfirm':
            return { 
                ...state,
                openConfirm: action.payload
            }
        case 'getProvincias':
            return { 
                ...state, 
                provincias: action.payload
            }
        case 'getDepartamentos':
            return { 
                ...state, 
                departamentos: action.payload
            }
        case 'getLocalidades':
            return { 
                ...state, 
                localidades: action.payload,
                loadingDatosDemograficos: false
            }
        case 'getAllLocalidades':
            return { 
                ...state, 
                allLocalidades: action.payload,
                loadingAllDatosDemograficos: false
            }
        case 'changeUbicacion':
            state.officesItems[action.payload.name] = action.payload.value
            return { 
                ...state
            }
        case 'handleChangeInputConsultorio':
            state.officesItems[action.payload.name] = action.payload.value

            if(action.payload.name === 'phone'){
                state.officesItems.phone = CellPhoneFormat(action.payload.value)
            }
            if(action.payload.name === 'cellPhone'){
                state.officesItems.cellPhone = CellPhoneFormat(action.payload.value)
            }
            if(action.payload.name === 'postalCode'){
                state.officesItems.postalCode = action.payload.value.replaceAll('-', '')
            }
            if(action.payload.name === 'height'){
                state.officesItems.height = action.payload.value.replaceAll('-', '')
            }
            return {
                ...state
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'handleChangeHasXRay':
            state.officesItems.hasXRay = action.payload
            return {
                ...state
            }
        case 'handleClickCheck':
            state.officesItems.height = action.payload ? 'S/N' : 0
            return {
                ...state
            }
        case 'setLatAndLng':
            state.officesItems.googlePlaceId = action.payload
            return {
                ...state
            }
        case 'openAlert':
            return {
                ...state,
                openAlert: action.payload
            }
        case 'loadingDatosDemograficos':
            return {
                ...state,
                loadingDatosDemograficos: action.payload
            }
        case 'loadingAllDatosDemograficos':
            return {
                ...state,
                loadingAllDatosDemograficos: action.payload
            }
        case 'messageError':
            return { 
                ...state, 
                messageError: action.payload
            }
        case 'changeDatePicker':
        state.officesItems[action.payload.name] = action.payload.value
            return {
                ...state,
                officesItems: state.officesItems,
            }
        default:
            return state;
    }
}