import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ImgFondo from '../../img/g12.png'
import PaperContainer from '../PaperContainer';
import EmpadronamientoContext from "../../components/context/Empadronamiento/EmpadronamientoContext"
import { useContext, useEffect } from "react"
import Loader from '../Loader';
import Container from '../Container';

interface Props {
    section: string
}

const Start = (props: Props) => {

    const { state, getProfessionals, getInsurances, handleChangeView } = useContext(EmpadronamientoContext)

    useEffect(() => {
        getProfessionals()
        getInsurances()
    }, [])

    if(state.loading){
        return <Loader />
    }

    const cargoDatosDeProfesional = state.dataProfessional

    const content = <Box textAlign='center' mb={6} mt={2} pt={2} pr={1} pl={1}>
        <img src={ImgFondo} alt='ImgFondo' />
        <Box sx={{width: '60%', marginLeft: '20%', marginTop: 6}}>
            <Typography>Ya casi estamos. Solo vamos a pedirte que completes unos últimos datos profesionales.</Typography>
            <Typography sx={{fontWeight: 700}}>Son 5 pasos.</Typography>
        </Box>
        <Button 
            type='submit' 
            variant='contained' 
            // si ya cargo datos de profesional lo mando directo al step1
            onClick={() => handleChangeView(cargoDatosDeProfesional ? 'step1' : 'professionalData')}
            sx={{
                background: '#42C5B7',
                '&:hover': {background: '#389b90'},
                borderRadius: 30,
                padding: '5px 40px 5px 40px',
                margin: '65px 0px 30px 0px',
            }}
        >
            Empecemos
        </Button> 
    </Box>

    return (
        // esto se hace ya que se reutiliza el componente y para el empadronamiento se muestra como modal y para el reempadronamiento no
        props.section === 'empadronamiento' ? <PaperContainer>{content}</PaperContainer> : <Container child={content}/>
    )
}

export default Start