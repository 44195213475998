import { createContext } from 'react'
import { UserState } from '../../../interfaces/User';
import { SelectChangeEvent } from '@mui/material';

export interface ContextProps {
    userState: UserState,
    getUser: () => void
    getSecurityQuestions: () => void
    editUser: (iva: boolean) => void
    getProvincias: () => void
    getDepartamentos: () => void
    getLocalidades: () => void
    getCalles: () => void
    handleChangeSelect: (event: SelectChangeEvent<string | number>) => void
    handleChangeUbicacion: (value: any, name: string) => void
    handleChangeInput: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleUserChangeSelect: (event:SelectChangeEvent<string | number>) => void
    handleChangeDatePicker: (value: string, name: string) => void
    handleChangeAnswerField: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleOpenConfirm: () => void
    handleCloseConfirm: () => void
    closeAlert: () => void
    getIvaTypes: () => void
    clearValues: () => void
    getProfessionals: () => void
    handleClickCheck: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const UserContext = createContext<ContextProps>({} as ContextProps);

export default UserContext;