import { createContext } from 'react'
import { DentalRecordsState, ProviderState } from '../../../interfaces/DentalRecords';
import { SelectChangeEvent } from '@mui/material';

export interface ContextProps {
    state: DentalRecordsState,
    getDentalRecords: () => void
    getDentalRecordsFilter: () => void
    getDentalRecordsById: (id: number) => void
    getDentalDetail: () => void
    getDentalRecordStates: () => void
    modifyDentalRecordStatus: (dentalRecordId: number, status: number, comment?: string) => void
    modifyDentalRecord: (dentalRecordId: number, status: number) => void
    getInsurances: () => void
    getInsuranceStatus: () => void
    getCirles: () => void
    getSexTypes: () => void
    getDocumentTypes: () => void
    getProvincias: () => void
    getDepartamentos: (state: string) => void
    getLocalidades: (state: string, department: string) => void
    getAffiliates: (code: string,insurancesIdFopbaInsider: number, insuranceId: number) => void
    getProfessionals: () => void
    getEnrollment: () => void
    getProviderTypes: () => void
    saveDentalRecords: (insuranceId: number) => void
    getUserCircles: () => void
    getEnrollmentDetail: (enrollmentId: number) => void
    handleChangeUbicacion: (value: any, name: string) => void
    handleChangeInput: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeInputAdd: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeSelect: (event:SelectChangeEvent<string | number | number[] | string[]> ) => void
    handleChangeDatePicker: (value: string, name: string) => void
    handleOpenConfirm: () => void
    handleCloseConfirm: () => void
    handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    clearFilter: () => void
    pushTreatmentsTable: () => void
    removeTreatments: (idToRemove: number) => void
    pushProviders: (pieceId: number, providerType: number, description: string) => void
    closeAlert: () => void
    closeAlertAffiliateNotFound: () => void
    setProviders: (value: ProviderState[]) => void
    handleClickCheck: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleClickCheckHealthyMouth: (event: React.ChangeEvent<HTMLInputElement>) => void
    getPlanCodes: (insuranceId: number) => void
    getIvaTypes: () => void
    getProviderByAffiliateCode: (code: string) => void
    customGetDentalRecordsById: (id: number) => void
    cleanProviders: () => void
    changeStateLoadProvider:(value: boolean) => void
}

const DentalRecordsContext = createContext<ContextProps>({} as ContextProps);

export default DentalRecordsContext;