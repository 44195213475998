import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material';
import { useContext, useEffect } from "react"
import { Link } from 'react-router-dom'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CirculoContext from "../context/Circles/CircleContext"
import Divider from '@mui/material/Divider';
import Loader from '../Loader';
import Container from '../Container';
import Typography from '@mui/material/Typography';
import Button from '../Button';
import { CuitConGuiones } from '../../utils/CuitFormat';

const TypographyStyled = styled(Typography)(() => ({
    color: '#3B72FF',
    fontWeight: 'bolder',
    marginRight: 8
}));

const DividerStyled = styled(Divider)(() => ({
    borderColor: '#64AAB2',
    marginBottom: 35
}));

const Detail = () => {

    const { 
        getCirculoById,
        getProvincias,
        getDepartamentos,
        getLocalidades,
        state 
    } = useContext(CirculoContext)

    const {id} = useParams();

    useEffect(() => {
        getCirculoById(id)        
    }, [])

    useEffect(() => {
        if(!state.provincias.length){
            getProvincias()
        }
        if(state.items.state && state.items.department){
            getDepartamentos()
            getLocalidades()
        }
    }, [state.items.state, state.items.department])

    if(!state.items.idFopbaInsider || state.loading){
        return <Loader />
    }

    const cuitTypeString = state.items.cuit.toString()
    const cuit = CuitConGuiones(cuitTypeString)

    const provinciaSelected = state.provincias.find(p => p.statesId === Number(state.items.state))
    const departamentSelected = state.departamentos.find(d => d.departmentsId === Number(state.items.department))
    const localidadSelected = state.localidades.find(l => l.townsId  === Number(state.items.town))

    return (
        <Container 
            child={
                <Box>
                    <h4 style={{marginTop: 35}}>Datos Generales</h4>
                    <DividerStyled />
                    <Grid container spacing={3}>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Nombre:</TypographyStyled>{state.items.name}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Código:</TypographyStyled>{state.items.idFopbaInsider}
                        </Grid>
                        <Grid item container xs={12} sm={12} md={4}>
                            <TypographyStyled>Cuit:</TypographyStyled>{cuit}
                        </Grid>
                    </Grid>
                    <h4 style={{marginTop: 35}}>Datos Demográficos</h4>
                    <DividerStyled />
                    <Grid container spacing={3}>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Localidad:</TypographyStyled>{localidadSelected?.name}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Partido:</TypographyStyled>{departamentSelected?.name}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Provincia:</TypographyStyled>{provinciaSelected?.name}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Dirección:</TypographyStyled>
                            {`${state.items.street} ${state.items.height}${state.items.floor ? 'Piso ' + state.items.floor + ' ' +  state.items.apartment : ''} `}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Código Postal:</TypographyStyled>{state.items.postalCode}
                        </Grid>
                    </Grid>
                    <h4 style={{marginTop: 35}}>Datos de Contacto</h4>
                    <DividerStyled />
                    <Grid container spacing={3}>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Teléfono / Celular:</TypographyStyled>{state.items.phone}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Teléfono / Celular:</TypographyStyled>{state.items.cellPhone}
                        </Grid>
                        <Grid item container xs={12} sm={12} md={4}>
                            <TypographyStyled>Email:</TypographyStyled>{state.items.email}
                        </Grid>
                    </Grid>
                    <h4 style={{marginTop: 35}}>Autoridades</h4>
                    <DividerStyled />
                    <Grid container spacing={3}>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Nombre de Autoridad: </TypographyStyled>{state.items.authorityName ? state.items.authorityName : '-'}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Nombre de Secretaria/o: </TypographyStyled>{state.items.secretaryName ? state.items.secretaryName : '-'}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='end' mt={8}>
                        <Grid item xs={4} sm={4} md={3}>
                            <Button 
                                child={
                                    <Link style={{textDecoration: 'none', color: '#4e4e4e', width: '100%'}} to={'/circulos'}>
                                        Volver
                                    </Link>
                                } 
                                variant='outlined' 
                                color='secondary'
                            />
                        </Grid>
                    </Grid>
                </Box>
            }
        />
    )
}

export default Detail