import EmpadronamientoContext from "../../components/context/Empadronamiento/EmpadronamientoContext"
import { useContext, useEffect } from "react"
import Step1 from "../Empadronamiento/Step1";
import Step2 from "../Empadronamiento/Step2";
import Step3 from "../Empadronamiento/Step3";
import Step4 from "../Empadronamiento/Step4";
import Step5 from "../Empadronamiento/Step5";
import Summary from "../Empadronamiento/Summary";
import { useParams } from "react-router-dom";
import Loader from "../Loader";

const Index = () => {

    const { state, getAllEnrollmentDetail, autocompleteEnrollmentFields } = useContext(EmpadronamientoContext)

    const {id} = useParams();

    useEffect(() => {
        if(!state.allEnrollmentsDetail.name){
            getAllEnrollmentDetail(Number(id))
        }
        if(state.allEnrollmentsDetail.name && !state.autocompleteEnrollmentFields){
            autocompleteEnrollmentFields(state.allEnrollmentsDetail)
        }
    }, [state.allEnrollmentsDetail])

    if(state.loadingAllEnrollments ){
        return <Loader />
    }

    const viewToRender = () => {
        switch (state.viewToShow) {
            case 'step1':
                return <Step1 section='reempadronamiento' propData={state.allEnrollmentsDetail} />
            case 'step2':
                return <Step2 section='reempadronamiento' propData={state.allEnrollmentsDetail} />
            case 'step3':
                return <Step3 section='reempadronamiento' propData={state.allEnrollmentsDetail} />
            case 'step4':
                return <Step4 section='reempadronamiento' propData={state.allEnrollmentsDetail} />
            case 'step5':
                return <Step5 section='reempadronamiento' propData={state.allEnrollmentsDetail} />
            case 'summary':
                return <Summary section='reempadronamiento' propData={state.allEnrollmentsDetail} />
            default:
                return <Step1 section='reempadronamiento' propData={state.allEnrollmentsDetail} />
        }
    }

    return viewToRender()
}

export default Index