import EmpadronamientoContext from "../../components/context/Empadronamiento/EmpadronamientoContext"
import { useContext } from "react"
import Start from './Start';
import ProfessionalData from './professionalData';
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import Summary from "./Summary";

const Index = () => {

    const { state } = useContext(EmpadronamientoContext)

    const viewToRender = () => {
        switch (state.viewToShow) {
            case 'start':
                return <Start section='empadronamiento' />
            case 'professionalData':
                return <ProfessionalData />
            case 'step1':
                return <Step1 section='empadronamiento' />
            case 'step2':
                return <Step2 section='empadronamiento' />
            case 'step3':
                return <Step3 section='empadronamiento' />
            case 'step4':
                return <Step4 section='empadronamiento' />
            case 'step5':
                return <Step5 section='empadronamiento' />
            case 'summary':
                return <Summary section='empadronamiento' />
            default:
                return <Start section='empadronamiento' />
        }
    }

    return viewToRender()
}

export default Index