import CardMui from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

interface Props {
    code: number
    planId: number
    insuranceId: number
    planIdSelected: number
    getInsurancePlanById: (insuranceId: number, insurancePlanId: number) => void 
}

const CardStyled = styled(CardMui)(() => ({
    background: 'linear-gradient(180deg, #3B73FD 0%, #50CBAD 100%)',
    border: '1px solid #4DBEB9',
    boxShadow: '0px 4px 4px rgba(156, 223, 219, 0.6)',
    borderRadius: 20,
    color: '#FFF',
    opacity: 0.5,
    '&:hover': {
        opacity: 1,
        cursor: 'pointer'
    }
}));

const CardContentStyled = styled(CardContent)(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}));

const TypographyStyled = styled(Typography)(() => ({
    fontWeight: 'bolder',
}));

const Card = ({code, planId, insuranceId, planIdSelected, getInsurancePlanById}: Props) => {

    const cardActive =  planIdSelected === planId

    return (
        <CardStyled onClick={() => cardActive ? {} : getInsurancePlanById(insuranceId, planId)} sx={{opacity: cardActive ? 1 : 0.5}}>
            <CardContentStyled>
                <TypographyStyled gutterBottom>Plan {code}</TypographyStyled>
            </CardContentStyled>
        </CardStyled>
    )
}

export default Card