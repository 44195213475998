import { useContext, useEffect } from "react"
import ContactContext from "../context/Contact/ContactContext"
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Loader from '../Loader';
import RoomIcon from '@mui/icons-material/Room';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Map from "./Map";

const BoxStyled = styled(Box)(() => ({
    display: 'flex',
    margin: '20px 0px 30px',
    'p': {
        marginLeft: 10
    }
}));

const PaperStyled = styled(Paper)(() => ({
    margin: '18px 16px 120px 16px', 
    padding: '10px 30px 40px', 
    borderRadius: 10,
    position: 'relative'
}));

const Index = () => {

    const { 
        getContact,
        state 
    } = useContext(ContactContext)

    useEffect(() => {
        getContact()       
    }, [])

    if(state.loading){
        return <Loader />
    }

    // separo la direccion de la localidad y provincia
    const address = state.contact.street.split(',')[0]
    
    let floorText = ''
    if(state.contact.floor && state.contact.apartment){
        floorText = ` - ${state.contact.floor}° ${state.contact.apartment}`
    } else if(state.contact.floor){
        floorText = ` - ${state.contact.floor}° Piso`
    }

    return (
        <PaperStyled elevation={5}>
            <h4>Contacto</h4>
            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />

            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={5}>
                    <BoxStyled>
                        <RoomIcon color="secondary" />
                        <Typography>{`${address} ${floorText}`}</Typography>
                    </BoxStyled>
                    <BoxStyled>
                        <LocalPhoneIcon color="secondary" />
                        <Typography>{`${state.contact.phone} ${state.contact.cellPhone ? ' / ' + state.contact.cellPhone : ''}`}</Typography>
                    </BoxStyled>
                    <BoxStyled>
                        <AccessTimeIcon color="secondary" />
                        <Typography>{`Horarios de atención: ${state.contact.availability}`}</Typography>
                    </BoxStyled>
                </Grid>
                <Grid item xs={12} sm={12} md={7}>
                    <Map latAndLng={state.contact.placeId} />
                </Grid>
            </Grid>
        </PaperStyled>
    )
}

export default Index