import { useReducer } from 'react'
import { OfficesState } from '../../../interfaces/Offices';
import OfficesContext from './OfficesContext';
import OfficesReducer from './OfficesReducer';
import axios from 'axios';
import moment from 'moment';

const INITIAL_STATE: OfficesState = {
    openConfirm: false,
    offices: [],
    loading: false,
    provincias: [],
    departamentos: [],
    localidades: [],
    allLocalidades: [],
    openAlert: false,
    officesItems: {
        officesId: 0,
        street: '',
        height: 0,
        floor: '',
        apartment: '',
        postalCode: '',
        town: '',
        department: '',
        state: '',
        country: '',
        googlePlaceId: '',
        phone: '',
        cellPhone: '',
        hasXRay: '',
        requiredFullAddress: false,
        betweenStreet: '',
        observations: '',
        attentionHours: '',
        availability: '',
        availabilityDate: moment().format("DD/MM/YYYY"),
        email: '',
        secondEmail: '',
        officeStateId: 0
    },
    loadingDatosDemograficos: false,
    loadingAllDatosDemograficos: false,
    messageError: []
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const OfficesProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(OfficesReducer, INITIAL_STATE)

    const getOffices = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}offices/professional`, {headers})
        .then(response => {
            dispatch({type: 'getOffices', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getOfficesById = async (id: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}offices/${id}`, {headers})
        .then(response => {
            dispatch({type: 'getOfficesById', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const saveOffices = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            street: state.officesItems.street,
            height: state.officesItems.height,
            floor: state.officesItems.floor,
            apartment: state.officesItems.apartment,
            postalCode: state.officesItems.postalCode,
            town: `${state.officesItems.town}`,
            department: `${state.officesItems.department}`,
            state:  `${state.officesItems.state}`,
            country: 'Argentina',
            googlePlaceId: state.officesItems.googlePlaceId,
            phone: state.officesItems.phone,
            cellPhone: state.officesItems.cellPhone,
            hasXRay: state.officesItems.hasXRay === 'true' ? true : false,
            requiredFullAddress: false,
            betweenStreet: state.officesItems.betweenStreet,
            observations: state.officesItems.observations,
            attentionHours: state.officesItems.attentionHours,
            email: state.officesItems.email,
            secondEmail: state.officesItems.secondEmail,
            availabilityDate: state.officesItems.availabilityDate
        }

        dispatch({type: 'setLoading', payload: true})
        await axios.post(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}offices`, data, { headers })
        .then(response => {
            if(response.status === 201){
                dispatch({type: "messageError", payload: []});
                dispatch({type: 'setLoading', payload: false})
                dispatch({type: 'openAlert', payload: true})
                clearConsultorioValues()
            }
        })
        .catch(error => {
            if(error.response?.status === 401){
                window.location.href = '/'
            }
            if (error.response.status === 500) {
                dispatch({type: "messageError", payload: ["Error interno del servidor al agregar regla general"]});
                dispatch({ type: "setLoading", payload: false });
            }
            dispatch({type: "messageError", payload: error.response.data?.errors});
            dispatch({type: 'setLoading', payload: false})
        })
    }

    const updateOffices = async (id: number, deleteOffice: boolean) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            street: state.officesItems.street,
            height: state.officesItems.height,
            floor: state.officesItems.floor,
            apartment: state.officesItems.apartment,
            postalCode: state.officesItems.postalCode,
            town: state.officesItems.town,
            department: state.officesItems.department,
            state: state.officesItems.state,
            country: state.officesItems.country,
            googlePlaceId: state.officesItems.googlePlaceId,
            phone: state.officesItems.phone,
            cellPhone: state.officesItems.cellPhone,
            hasXRay: state.officesItems.hasXRay === 'true' ? true : false,
            requiredFullAddress: false,
            betweenStreet: state.officesItems.betweenStreet,
            observations: state.officesItems.observations,
            attentionHours: state.officesItems.attentionHours,
            email: state.officesItems.email,
            secondEmail: state.officesItems.secondEmail,
            availabilityDate: state.officesItems.availabilityDate
        }

        const dataToSend = deleteOffice ? {...data, officeStateId: 3} : data

        dispatch({type: 'setLoading', payload: true})

        await axios.put(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}offices/${id}`, dataToSend, { headers })
        .then(() => {
            if(deleteOffice){
                window.location.href = '/consultorios'
            } else {
                dispatch({type: 'setLoading', payload: false})
                dispatch({type: 'openAlert', payload: true})
            }
        })
        .catch(error => {
            if(error.response?.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false})
        })
    }

    const clearConsultorioValues = () => {
        dispatch({type: 'clearConsultorioValues', payload: {
            officesId: 0,
            street: '',
            height: 0,
            floor: '',
            apartment: '',
            postalCode: '',
            town: '',
            department: '',
            state: '',
            country: '',
            googlePlaceId: '',
            phone: '',
            cellPhone: '',
            hasXRay: '',
            requiredFullAddress: false,
            betweenStreet: '',
            observations: '',
            attentionHours: '',
            availability: '',
            availabilityDate: '',
            email: '',
            secondEmail: '',
            officeStateId: 0
        }})
    }

    const getProvincias = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}states`, {headers})
        .then(response => {
            dispatch({type: 'getProvincias', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    
    const getDepartamentos = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}departments?stateId=${state.officesItems.state}&offset=0&limit=200`, {headers})
        .then(response => {
            dispatch({type: 'getDepartamentos', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    
    const getLocalidades = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        dispatch({ type: "loadingDatosDemograficos", payload: true });
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}towns?stateId=${state.officesItems.state}&departmentId=${state.officesItems.department}&offset=0&limit=200`, {headers})
        .then(response => {
            dispatch({type: 'getLocalidades', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
          dispatch({ type: "loadingDatosDemograficos", payload: false });
        })
    }

    const getAllLocalidades = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({ type: "loadingAllDatosDemograficos", payload: true });
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}towns?statesId=1&offset=0&limit=1000`, {headers})
        .then(response => {
            dispatch({type: 'getAllLocalidades', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({ type: "loadingAllDatosDemograficos", payload: false });
        })
    }

    const handleChangeUbicacion = (value: any, name: string) => {
        dispatch({type: 'changeUbicacion', payload: {value: value, name: name}})
    };

    const handleChangeInputConsultorio = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInputConsultorio', payload: {value: event.target.value, name: event.target.name}})
    };

    const openAlert = () => {
        dispatch({ type: "openAlert", payload: true });
    };
    
    const closeAlert = () => {
        dispatch({ type: "openAlert", payload: false });
    };
    

    const handleChangeHasXRay = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeHasXRay', payload: event.target.value})
    };

    const handleClickCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: 'handleClickCheck', payload: event.target.checked})
    }

    const setLatAndLng = (latAndLng: string) => {
        dispatch({type: 'setLatAndLng', payload: latAndLng})
    }

    const handleChangeDatePicker = (value: string, name: string) => {
        dispatch({
          type: "changeDatePicker",
          payload: { value: value, name: name },
        });
      };

    return(
        <OfficesContext.Provider value={{
            state,
            getOffices,
            getOfficesById,
            saveOffices,
            updateOffices,
            clearConsultorioValues,
            getProvincias,
            getDepartamentos,
            getLocalidades,
            getAllLocalidades,
            handleChangeUbicacion,
            handleChangeInputConsultorio,
            openAlert,
            closeAlert,
            handleChangeHasXRay,
            handleClickCheck,
            setLatAndLng,
            handleChangeDatePicker
        }}>
            {children}
        </OfficesContext.Provider>
    )
}

export default OfficesProvider