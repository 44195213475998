import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
    width: '100%',
    height: '400px'
};

interface Props {
    latAndLng: string
}

const Map = ({latAndLng}: Props) => {

    // la latitud y longitud se guardo en el campo placeId separados por coma
    // y lo que hago aca es separarlos para obtener la lat y lng por otro lado
    const latAndLngSplit = latAndLng?.split(',')
    const lat = Number(latAndLngSplit[0])
    const lng = Number(latAndLngSplit[1])
    
    const center = {
        lat: lat,
        lng: lng
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ? process.env.REACT_APP_GOOGLE_API_KEY : ''
    })

    const [map, setMap] = React.useState(null)
    
    const onLoad = React.useCallback(function callback(map: any) {
        setMap(map)
    }, [])
    
    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={16}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <Marker position={center}/>
        </GoogleMap>
    ) : <></>
}

export default Map