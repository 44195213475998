import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { TextField, Typography, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import { useState } from 'react';

interface Props {
    openModal: boolean,
    id: number,
    status: number,
    approved: boolean,
    handleCloseModal: () => void
    modifyDentalRecordStatus: (dentalRecordId: number, status: number, comment?: string) => void
}

export default function ConfirmModal({ openModal, id, status, approved, handleCloseModal, modifyDentalRecordStatus }:Props) {

    const [comment, setComment] = useState('')

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setComment(event.target.value)
    }

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        modifyDentalRecordStatus(id, status, comment)
    }

    return (
        <Dialog
            open={openModal}
            onClose={handleCloseModal}
        >
            <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} mt={6}>
                {approved ?
                    <ThumbUpAltIcon color='success' sx={{fontSize: '70px'}}/>
                :
                    <ThumbDownIcon color='error' sx={{fontSize: '70px'}}/>
                }
                <Typography variant='h5'>{approved ? 'Aprobar' : 'Rechazar'} ficha</Typography>
                <DialogContent>
                    <DialogContentText>
                        Dejanos un comentario explicando el motivo {approved ? 'de la aprobación' : 'del rechazo'}.
                    </DialogContentText>
                </DialogContent>
            </Box>
            <DialogActions sx={{flexDirection: 'column'}}>
                <Box component='form' onSubmit={handleSubmit}>
                    <TextField 
                        label="Comentario"
                        name='comment'
                        variant="outlined"
                        value={comment}
                        multiline
                        fullWidth
                        rows={3}
                        required={!approved}
                        onChange={(e) => handleChange(e)}
                    />
                    <Grid container spacing={1} justifyContent='center'>
                        <Button 
                            onClick={handleCloseModal} 
                            variant='contained' 
                            sx={{margin: '25px', justifyContent: 'center', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                        >
                            Cancelar
                        </Button>
                        <Button 
                            type='submit'
                            variant='contained' 
                            sx={{margin: '25px', justifyContent: 'center', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                        >
                            {approved ? 'Aprobar' : 'Rechazar'}
                        </Button>
                    </Grid>
                </Box>
            </DialogActions>
        </Dialog>
    );
}