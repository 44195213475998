import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    filtered?: boolean;
    noMoreResults?: boolean;
    nextPage?: () => void;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number,
    ) => void;
}
  
export default function Pagination(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, filtered, noMoreResults, onPageChange, nextPage } = props;
  
    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      // si es la ultima pagina
      if(page + 1 >= Math.ceil(count / rowsPerPage) - 1){
        // si viene la funcion nextPage y si no se filtro paso de pagina y ejecuto nextPage para traer 50 nuevos resultados
        if(nextPage && !filtered){
          nextPage()
          onPageChange(event, page + 1);
        } else {
          onPageChange(event, page + 1);
        }
      } else {
        onPageChange(event, page + 1);
      }
    };
  
    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      if(nextPage && !filtered){
        nextPage()
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      } else {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
      }
    };
  
    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={(page >= Math.ceil(count / rowsPerPage) - 1 && filtered) || (page >= Math.ceil(count / rowsPerPage) - 1 && (noMoreResults !== undefined ? noMoreResults : true))}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </Box>
    );
}