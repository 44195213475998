import Box from '@mui/material/Box';
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext"
import { useContext, useEffect, useState } from "react"
import PaperContainer from '../PaperContainer';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Pagination from '../Pagination';
import Checkbox from '@mui/material/Checkbox';
import Loader from '../Loader';
import Container from '../Container';
import { EnrollmentDetailItems } from '../../interfaces/Empadronamiento';
import GeneralRuleModal from '../GeneralRuleModal';
import { LegalTermsIomaModal } from './LegalTermsIomaModal';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3B72FF',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

interface Props {
    section: string
    propData?: EnrollmentDetailItems
}

const Step4 = (props: Props) => {

    const { state, getInsurances, handlePrevStep, handleNextStep, handleChangeFilter, getInsuranceFilter, clearFilter, handleClickCoverage, setInsuranceResponse } = useContext(EmpadronamientoContext)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [notCoverageCheck, setNotCoverageCheck] = useState(false)
    const [openGeneralRuleModal, setOpenGeneralRuleModal] = useState(false)
    const [generalRules, setGeneralRules] = useState({particularRegulations: '', arancelNomenclature: '', generalInformation: ''})
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {
        if(state.insuranceFiltered.length < 10) {
            setPage(0)
        }
    }, [state.insuranceFiltered])

    useEffect(() => {
        if(props.propData && !state.insuranceData.length){
            getInsurances()
        }

        if(state.insuranceData.length && props.propData && !state.setInsuranceResponse){
            state.insuranceData.forEach(d => {
                const insurancesChecked = props.propData?.insurancesResponse.filter(item => item.insurancesId === d.insurancesId)
                if(insurancesChecked?.length){
                    // en la data viene generalCoveragesId en (PLURAL) y en el detail generalCoverageId en (SINGULAR) por eso le cambio el nombre por que despues sino rompe
                    const newArray = insurancesChecked[0].generalCoverages.map(item => {
                        return {
                            generalCoveragesId: item.generalCoverageId,
                            description: item.description,
                            status: item.status ? true : false
                        };
                    });
                    d.generalCoverages = newArray
                    setInsuranceResponse(true)
                }
            })
        }

    }, [state.insuranceData])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // habilito el boton de filtrar cuando se haya escrito al menos 3 caracteres
    const FilterDisabled = state.nameFilter.length >= 3

    state.insuranceData.forEach((item) => {
        const coverageIds = item.generalCoverages.map((coverage: any) => coverage.generalCoveragesId);
        // verifico si el id 1 esta dentro del array de coberturas si no esta lo seteo
        if (!coverageIds.includes(1)) {
          item.generalCoverages.push({ generalCoveragesId: 1, description: 'disabled' });
        }
        if (!coverageIds.includes(2)) {
          item.generalCoverages.push({ generalCoveragesId: 2, description: 'disabled' });
        }
        if (!coverageIds.includes(3)) {
          item.generalCoverages.push({ generalCoveragesId: 3, description: 'disabled' });
        }

        // ordeno las coberturas generales por id
        item.generalCoverages.sort((a: any, b: any) => a.generalCoveragesId - b.generalCoveragesId);
    });

    const coverageCheck = state.insuranceData.filter(insurance => insurance.generalCoverages.some(coverage => coverage.status === true))

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const iomaChecked = coverageCheck.find(i => String(i.idFopbaInsider) === "20")
        if(iomaChecked){
            setOpenModal(true)
        } else {
            if(coverageCheck.length){
                setNotCoverageCheck(false)
                handleNextStep()
            } else {
                setNotCoverageCheck(true)
                window.scrollTo(0, 0)
            }
        }
        
    }

    const data = state.insuranceFiltered.length ? state.insuranceFiltered : state.insuranceData 

    const handleOpenGeneralRuleModal = (insuranceId: number) => {
        setOpenGeneralRuleModal(true)
        const insuranceSelected = data.filter(d => d.insurancesId === insuranceId)[0]
        setGeneralRules({particularRegulations: insuranceSelected.particularRegulations, arancelNomenclature: insuranceSelected.arancelNomenclature, generalInformation: insuranceSelected.generalInformation })
    }

    const handleCloseGeneralRuleModal = () => {
        setOpenGeneralRuleModal(false)
    }

    const content = <Box textAlign='center' mb={6} mt={2} pt={2} pr={1} pl={1}>
        <Box sx={{width: '60%', marginLeft: '20%', marginTop: 1}}>
            <Typography variant='h5' sx={{fontWeight: 700}}>Obras Sociales</Typography>
            <Typography mt={2}>Indícanos las Obras Sociales que vas atender.</Typography>
        </Box>

        <Box 
            component='form' 
            onSubmit={handleSubmit}
            className='formContainer'
            sx={{width: '96%', marginLeft: '2%', marginTop: 5}}
        >
            <Grid container justifyContent='space-between' alignItems='center' spacing={3} mb={3}>
                <Grid xs={8} sm={8} md={10} item>
                    <TextField fullWidth value={state.nameFilter} name='nameFilter' label='Indícanos que Obra Social buscas' onChange={(e) => handleChangeFilter(e)} />
                </Grid>
                <Grid xs={4} sm={4} md={2} item>
                    <Button variant="outlined" size={state.nameFilter ? 'small' : 'large'} fullWidth startIcon={<FilterAltIcon />} onClick={getInsuranceFilter} disabled={!FilterDisabled}>Filtrar</Button>
                    {state.nameFilter ? 
                        <Button variant="outlined" size='small' fullWidth color='error' startIcon={<FilterAltOffIcon />} onClick={clearFilter}>Limpiar</Button>
                    : null}
                </Grid>
            </Grid>

            {notCoverageCheck ? <Typography color='error' align='left' mb={1}>Debes seleccionar al menos una cobertura para avanzar</Typography> : null}

            {state.loadingInsurance ?
                <Loader />
            :
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Código</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="left">Obra Social</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Datos Prestacionales</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Odontología General</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Prótesis</StyledTableCell>
                                <StyledTableCell sx={{padding: '12px'}} align="center">Ortodoncia</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {(rowsPerPage > 0 
                                ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : data
                            ).map((row) => {
                                const particularRulesDisabled = !row.particularRegulations && !row.arancelNomenclature && !row.generalInformation
                                return <StyledTableRow
                                    key={row.insurancesId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.idFopbaInsider}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">{row.name}</StyledTableCell>
                                    <StyledTableCell sx={{padding: '5px'}} align="left">
                                        <LibraryBooksIcon 
                                            onClick={particularRulesDisabled ? () => {} : () => handleOpenGeneralRuleModal(row.insurancesId)} 
                                            style={particularRulesDisabled ? {color: 'gray', textDecoration: 'none', width: '100%'} : {color: '#3C78F8', textDecoration: 'none', width: '100%', cursor: 'pointer'}} 
                                        />
                                    </StyledTableCell>
                                    {row.generalCoverages.map((coverage, index) => {
                                        return <StyledTableCell 
                                            key={index} 
                                            sx={{padding: '5px'}} 
                                            align="center"
                                        >
                                            <Checkbox 
                                                checked={coverage.status ? coverage.status : false}
                                                inputProps={{ 'aria-label': 'controlled' }} 
                                                onChange={(evt) => {handleClickCoverage(evt, row.insurancesId, coverage.generalCoveragesId ? coverage.generalCoveragesId : 0); setNotCoverageCheck(false)}} 
                                                disabled={coverage.description === 'disabled'}  
                                            />
                                        </StyledTableCell>
                                    })}
                                </StyledTableRow>
                            })}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                    colSpan={3}
                                    count={data.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': 'Filas por pagina',
                                        },
                                        native: true,
                                    }}
                                    labelRowsPerPage='Filas por Página'
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={() => <Pagination count={data.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            }
        
            <Stepper nonLinear activeStep={state.activeStep} sx={{marginTop: 5}}>
                {state.steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Grid container justifyContent='center' spacing={2}>
                <Grid item>
                    <Button 
                        type='button' 
                        variant='contained' 
                        sx={{
                            background: '#42C5B7',
                            '&:hover': {background: '#389b90'},
                            borderRadius: 30,
                            padding: '5px 40px 5px 40px',
                            margin: '45px 0px 30px 0px',
                        }}
                        onClick={handlePrevStep}
                    >
                        Volver
                    </Button> 
                </Grid>
                <Grid item>
                    <Button 
                        type='submit' 
                        variant='contained' 
                        sx={{
                            background: '#42C5B7',
                            '&:hover': {background: '#389b90'},
                            borderRadius: 30,
                            padding: '5px 40px 5px 40px',
                            margin: '45px 0px 30px 0px',
                        }}
                    >
                        Siguiente
                    </Button> 
                </Grid>
            </Grid>
        </Box>
        <GeneralRuleModal 
            handleCloseGeneralRuleModal={handleCloseGeneralRuleModal}
            openGeneralRuleModal={openGeneralRuleModal}
            particularRegulations={generalRules.particularRegulations}
            arancelNomenclature={generalRules.arancelNomenclature}
            generalInformation={generalRules.generalInformation}
        />
        <LegalTermsIomaModal
            openModal={openModal}
            handleCloseModal={() => setOpenModal(false)}
            handleNextStep={()=> {
                state.enrollmentDetail.acceptTerms = true
                handleNextStep()
            }} 
        />
    </Box>
    return (
        props.section === 'empadronamiento' ? <PaperContainer>{content}</PaperContainer> : <Container child={content}/>
    )
}

export default Step4