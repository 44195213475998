import { Typography, TextField, Button, Box, Grid } from "@mui/material"
import LoginContext from "../context/Login/LoginContext"
import { Link } from "react-router-dom"
import { useContext } from "react"
import Logo from '../../img/logo.png'
import Dentist from '../../img/dentist.png'
import './style.css'
import Loader from "../Loader"

const Login = () => {

    const { state, doLogin, handleChangeValues, clickRecoverPassword } = useContext(LoginContext)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>)  => {
        e.preventDefault()
        doLogin(state.email, state.contraseña)
    }

    if(state.cargando){
        return <Loader />
    }

    return (
        <Grid container justifyContent='space-between'>
            <Grid sx={{ display: { xs: 'none', sm: 'flex' } }} xs={0} sm={6} md={6} item>
                <img src={Dentist} alt='Logo' className="imgInLogin" />
            </Grid>
            <Grid xs={12} sm={6} md={6} item className="loginContainer">
                <Box className="textAlign">
                    <img src={Logo} width='130px' alt='Logo' />
                </Box>
                <Typography variant="h4" className="title textAlign">Te damos la bienvenida</Typography>
                <Box 
                    component='form' 
                    onSubmit={handleSubmit}
                    className='formContainer'
                >
                    <TextField 
                        label="Email"
                        variant="standard" 
                        name="email"
                        error={!state.email && state.error}
                        value={state.email}
                        onChange={(e) => handleChangeValues(e)}
                        margin="dense"
                        className="inputWidth"
                    />
                    <TextField 
                        label="Contraseña" 
                        variant="standard"
                        name="contraseña"
                        type='password'
                        error={!state.contraseña && state.error}
                        value={state.contraseña}
                        onChange={(e) => handleChangeValues(e)}
                        margin="dense"
                        className="inputWidth"
                    />
                    {state.wrongUser && <Typography className="errorMessage" variant="body1">Usuario o contraseña incorrectos</Typography>}
                    {state.userBlocked && <Typography className="errorMessage" variant="body1">Usuario bloqueado - Contactase con FOPBA para desbloqueo</Typography>}
                    {state.userNotEnabled && <Typography className="errorMessage" variant="body1">Usuario no habilitado para este sitio</Typography>}
                    <Box onClick={clickRecoverPassword} className='linkMargin'>
                        <Link to={state.email ? `/recuperar-contraseña?email=${state.email}` : '/'}>¿Olvidaste tu contraseña?</Link>
                    </Box>
                    {!state.email && state.clickRecoverPassword && <Typography className="errorMessage" variant="body1">Para recuperar tu contraseña primero tienes que cargar tu email</Typography>}
                    <Button type='submit' variant='contained' sx={{background: '#42C5B7', '&:hover': {background: '#389b90'}, marginTop: 6}}>Ingresar</Button> 
                </Box>
            </Grid>
        </Grid>
    )
}

export default Login