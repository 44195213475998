import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface Props {
    openConfirm: boolean,
    title: string,
    description?: string,
    handleCloseConfirm: () => void
    handleConfirm: () => void
}

export default function Confirm({ openConfirm, title, description, handleCloseConfirm, handleConfirm }:Props) {

    return (
        <div>
            <Dialog
                open={openConfirm}
                onClose={handleCloseConfirm}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {description && description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirm} variant='contained' color='inherit'>Cancelar</Button>
                    <Button onClick={handleConfirm} variant='contained'>Confirmar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}