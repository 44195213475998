import { securityQuestions } from '../../../interfaces/Login';
import { UserState, UserItems, IvaType, ProfessionalItems } from '../../../interfaces/User';
import { CellPhoneConGuiones, CellPhoneFormat } from '../../../utils/CellPhoneFormat';
import { CuitConGuiones, CuitFormat } from '../../../utils/CuitFormat';

type Action = {type: 'getUsers', payload: UserItems[]}
    | {type: 'getUser', payload: UserItems}
    | {type: 'openConfirm', payload: boolean}
    | {type: 'getFilterUsers', payload: UserItems[]}
    | {type: 'clearFilter', payload: {name: string, lastname: string}}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'getProvincias', payload: []}
    | {type: 'getDepartamentos', payload: []}
    | {type: 'getLocalidades', payload: []}
    | {type: 'getCalles', payload: []}
    | {type: 'clearValues', payload: UserItems}
    | {type: 'clearFilter', payload: {name: string, lastname: string}}
    | {type: 'changeUbicacion', payload: {name: string, value: string}}
    | {type: 'handleChangeInput', payload: {name: string, value: string}}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'handleUserChangeSelect', payload: {name: string, value: string | number}}
    | {type: 'changeDatePicker', payload: {name: string, value: string}}
    | {type: 'userAdded', payload: boolean}
    | {type: 'userEdited', payload: boolean}
    | {type: 'passwordsDontMatch', payload: boolean}
    | {type: 'setLoading', payload: boolean}
    | {type: 'openAlert', payload: boolean}
    | {type: 'getSecurityQuestions', payload: securityQuestions[]}
    | {type: 'handleChangeAnswerField', payload: {value: string, name: string}}
    | {type: 'handleChangeSelect', payload: {value: string | number, name: string}}
    | {type: 'getIvaTypes', payload: IvaType[]}
    | {type: 'loadingDatosDemograficos', payload: boolean}
    | {type: 'getProfessionals', payload: ProfessionalItems}
    | {type: 'handleClickCheck', payload: boolean}

export default function UserReducer (state: UserState, action: Action): UserState {
    switch (action.type) {
        case 'getUser':
            state.items = action.payload
            // set answers
            state.answerAndQuestions.firstAnswer = action.payload.answers[0].answer
            state.answerAndQuestions.secondAnswer = action.payload.answers[1].answer
            state.answerAndQuestions.thirdAnswer = action.payload.answers[2].answer
            // set questions
            state.answerAndQuestions.firstQuestion = action.payload.answers[0].securityQuestionsId
            state.answerAndQuestions.secondQuestion = action.payload.answers[1].securityQuestionsId
            state.answerAndQuestions.thirdQuestion = action.payload.answers[2].securityQuestionsId

            state.items.cuil = CuitConGuiones(action.payload.cuil)
            return { 
                ...state, 
                items: state.items,
            }
        case 'openConfirm':
            return { 
                ...state,
                openConfirm: action.payload
            }
        case 'handleChangeFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'getProvincias':
            return { 
                ...state, 
                provincias: action.payload
            }
        case 'getDepartamentos':
            return { 
                ...state, 
                departamentos: action.payload
            }
        case 'getLocalidades':
            return { 
                ...state, 
                localidades: action.payload,
                loadingDatosDemograficos: false
            }
        case 'getCalles':
            return { 
                ...state, 
                calles: action.payload
            }
        case 'clearValues':
            return { 
                ...state,
                items: action.payload
            }
        case 'changeUbicacion':
            state.items[action.payload.name] = action.payload.value
            return { 
                ...state, 
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeInput':
            state.items[action.payload.name] = action.payload.value

            if(action.payload.name === 'cuil'){
                state.items.cuil = CuitFormat(action.payload.value)
                const cuitLength = state.items.cuil.length
                if(cuitLength === 13){
                    const documentNumber = state.items.cuil.slice(3, cuitLength - 2)
                    state.items.documentValue = documentNumber
                }
            }
            if(action.payload.name === 'cellPhone'){
                state.items.cellPhone = CellPhoneFormat(action.payload.value)
            }
            if(action.payload.name === 'phone'){
                state.items.phone = CellPhoneFormat(action.payload.value)
            }
            if(action.payload.name === 'postalCode'){
                state.items.postalCode = action.payload.value.replaceAll('-', '')
            }
            if(action.payload.name === 'streetNumber'){
                state.items.streetNumber = action.payload.value.replaceAll('-', '')
            }

            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleUserChangeSelect':
            state.items[action.payload.name] = action.payload.value
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'changeDatePicker':
            state.items[action.payload.name] = action.payload.value
            return {
                ...state,
                items: state.items,
                buttonDisabled: false
            }
        case 'userEdited':
            return {
                ...state,
                userEdited: action.payload
            }
        case 'passwordsDontMatch':
            return {
                ...state,
                passwordsDontMatch: action.payload
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'openAlert':
            return { 
                ...state,
                openAlert: action.payload
            }
        case 'handleChangeAnswerField':
            state.answerAndQuestions[action.payload.name] = action.payload.value
            let indexAnswer = 0
            if(action.payload.name === 'firstAnswer'){
                indexAnswer = 0
            } else if(action.payload.name === 'secondAnswer'){
                indexAnswer = 1
            } else {
                indexAnswer = 2
            }
            // seteo dentro del array de answer el nuevo valor que se cambio en el input de respuestas 
            state.items.answers[indexAnswer].answer = action.payload.value 
            return { 
                ...state,
                answerAndQuestions: state.answerAndQuestions,
                items: state.items,
                buttonDisabled: false
            }
        case 'handleChangeSelect':
            state.answerAndQuestions[action.payload.name] = action.payload.value
            let indexQuestion = 0
            if(action.payload.name === 'firstQuestion'){
                indexQuestion = 0
            } else if(action.payload.name === 'secondQuestion'){
                indexQuestion = 1
            } else {
                indexQuestion = 2
            }
            // seteo dentro del array de answer el nuevo valor que se cambio en el select de preguntas
            state.items.answers[indexQuestion].securityQuestionsId = typeof action.payload.value === 'number' ? action.payload.value : 0
            return { 
                ...state,
                answerAndQuestions: state.answerAndQuestions,
                items: state.items,
                buttonDisabled: false
            }
        case 'getSecurityQuestions':
            return { 
                ...state,
                listQuestions: action.payload
            }
        case 'getIvaTypes':
            return { 
                ...state,
                ivaTypeList: action.payload
            }
        case 'loadingDatosDemograficos':
            return { 
                ...state,
                loadingDatosDemograficos: action.payload
            }
        case 'getProfessionals':
            return { 
                ...state, 
                dataProfessional: action.payload
            }
        case 'handleClickCheck':
            state.items.streetNumber = action.payload ? 'S/N' : ''
            return {
                ...state
            }
        default:
            return state;
    }
}