import { useReducer } from 'react'
import axios from 'axios';
import LoginReducer from './LoginReducer';
import LoginContext from './LoginContext';
import { SelectChangeEvent } from '@mui/material';
import { LoginState, tokenDecode } from '../../../interfaces/Login';
import jwt_decode from "jwt-decode";

const INITIAL_STATE: LoginState = {
    email: '',
    contraseña: '',
    password: '',
    newPassword: '',
    passwordsDontMatch: false,
    error: false,
    cargando: false,
    firstAccess: false,
    wrongUser: false,
    idSelected: 0,
    userBlocked: false,
    userNotEnabled: false,
    clickRecoverPassword: false,
    invalidIdentity: false,
    listQuestions: [{securityQuestionsId: 0, question: ''}],
    listQuestionsByUser: [],
    answerAndQuestions: {
        firstQuestion: '',
        firstAnswer: '',
        secondQuestion: '',
        secondAnswer: '',
        thirdQuestion: '',
        thirdAnswer: ''
    },
    answerAndQuestionsId: {
        securityQuestionsId0: 0,
        answer0: '',
        securityQuestionsId1: 0,
        answer1: '',
        securityQuestionsId2: 0,
        answer2: ''
    }
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const LoginProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(LoginReducer, INITIAL_STATE)

    const getPermisos = async (responseLogin: any) => {
        const headers = {
            'Authorization': `Bearer ${responseLogin.access_token}`,
            'offset': 0,
            'limit': 20
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}roles/${responseLogin.rolId}/policies`, {headers})
        .then(response => {
            // seteo array de permisos en localStorage
            localStorage.setItem('permissions', JSON.stringify(response.data));

            // si es la primera vez que el usuario hace login hago redirect a /nueva-contraseña
            if(responseLogin.firstAccess === true){
                window.location.href = '/nueva-contraseña'
                // si el usuario no tiene preguntas de seguridad seteadas hago el redirect a /preguntas-de-seguridad
            } else if(!responseLogin.hasSecurityQuestions){
                window.location.href = '/preguntas-de-seguridad'
            } else if(!responseLogin.updateProfile && responseLogin.isProfessional){
                window.location.href = '/perfil-profesional'
            } else if(responseLogin.requireEnrollment && responseLogin.isProfessional){
                window.location.href = '/empadronamiento'
            } else {
                window.location.href = '/inicio'
            }
        })
        .catch(error => {
            if(error.response?.status === 401){
                window.location.href = '/'
            } else if(error.response?.status === 403){
                window.location.href = '/inicio'
            }
        })
    }

    const doLogin = async (email: string, contraseña: string) => {

        const data = {
            "username" : email,
            "password" : contraseña,
            "access": "sitio_usuarios"
        }

        dispatch({type: 'cargando', payload: true})

        // verifico que tenga el email y la contraseña seteados
        if(email && contraseña){
            await axios.post(`${process.env.REACT_APP_AUTENTICACION_API}login`, data)
            .then(response => {
                if(response.status === 201){
                    getPermisos(response.data)
                    // una vez que se haga login seteo datos necesarios en el localStorage
                    localStorage.setItem('token', response.data.access_token);
                    localStorage.setItem('hasSecurityQuestions', response.data.hasSecurityQuestions);
                    localStorage.setItem('user', response.data.usersId);
                    localStorage.setItem('circleUserId', '');
                    localStorage.setItem('professionalId', response.data.professionalId);

                    dispatch({type: 'loginError', payload: false})
                    dispatch({type: 'wrongUser', payload: false})
                    dispatch({type: 'userBlocked', payload: false})
                    dispatch({type: 'userNotEnabled', payload: false})
                    dispatch({type: 'clickRecoverPassword', payload: false})
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch({type: 'wrongUser', payload: true})
                }
                if(error.response.data?.block){
                    dispatch({type: 'userBlocked', payload: true})
                }
                if(error.response?.message === 'userNotEnabled'){
                    dispatch({type: 'userNotEnabled', payload: true})
                }
                dispatch({type: 'cargando', payload: false})
                dispatch({type: 'clickRecoverPassword', payload: false})
            })
        } else {
            dispatch({type: 'loginError', payload: true})
            dispatch({type: 'cargando', payload: false})
        }
    }

    const changePassword = async (password: string, newPassword: string, email?: string | number) => {

        const hasSecurityQuestions = localStorage.getItem('hasSecurityQuestions');

        const token = localStorage.getItem('token')
        let emailToSend = email
        if(token){
            const decoded: tokenDecode = jwt_decode(token ? token : '');
            emailToSend = decoded.email
        }

        const data = {
            "password" : newPassword,
            "username": emailToSend
        }

        dispatch({type: 'cargando', payload: true})

        // verifico que las dos contraseñas coincidan
        if(password === newPassword){
            await axios.patch(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}users/newPassword`, data)
            .then(response => {
                if(response.status === 200){
                    if(hasSecurityQuestions === 'false'){
                        window.location.href = '/preguntas-de-seguridad'
                    } else {
                        window.location.href = '/inicio'
                    }
                    dispatch({type: 'passwordsDontMatch', payload: false})
                    dispatch({type: 'cargando', payload: false})
                    dispatch({type: 'clickRecoverPassword', payload: false})
                }
            })
            .catch(error => {
                if(error.response.status === 401){
                    dispatch({type: 'cargando', payload: false})
                }
            })
        } else {
            dispatch({type: 'passwordsDontMatch', payload: true})
            dispatch({type: 'cargando', payload: false})
        }
    }

    const handleChangeValues = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChange', payload: {name: e.target.name, value: e.target.value}})
        dispatch({type: 'wrongUser', payload: false})
    }

    const getSecurityQuestions =  async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'cargando', payload: true})

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}securityQuestions`, {headers})
        .then(response => {
            dispatch({type: 'getSecurityQuestions', payload: response.data})
            dispatch({type: 'cargando', payload: false})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    };

    const addSecurityQuestions = async () => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const userId = localStorage.getItem('user');

        const data = [
            {
                usersId: userId,
                securityQuestionsId: state.answerAndQuestions.firstQuestion,
                answer: state.answerAndQuestions.firstAnswer 
            },
            {
                usersId: userId,
                securityQuestionsId: state.answerAndQuestions.secondQuestion,
                answer: state.answerAndQuestions.secondAnswer 
            },
            {
                usersId: userId,
                securityQuestionsId: state.answerAndQuestions.thirdQuestion,
                answer: state.answerAndQuestions.thirdAnswer 
            },
        ]

        dispatch({type: 'cargando', payload: true})

        const token = localStorage.getItem('token')
        const decoded: tokenDecode = jwt_decode(token ? token : '');

        await axios.post(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}answers`, data, { headers })
        .then(response => {
            localStorage.setItem('hasSecurityQuestions', 'true');
            if(response.status === 201){
                if(!decoded.updateProfile && decoded.isProfessional){
                    window.location.href = '/perfil-profesional'
                } else if(decoded.requireEnrollment && decoded.isProfessional){
                    window.location.href = '/empadronamiento'
                } else {
                    window.location.href = '/inicio'
                }
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                dispatch({type: 'cargando', payload: false})
            }
        })
    }

    const getSecurityQuestionsByUser =  async (email: string) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'cargando', payload: true})

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}securityQuestions/${email}`, {headers})
        .then(response => {
            dispatch({type: 'getSecurityQuestionsByUser', payload: response.data})
            dispatch({type: 'cargando', payload: false})
            dispatch({type: 'clickRecoverPassword', payload: false})

        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    };

    const validateSecurityQuestion = async (email: string) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = [
            {
                securityQuestionsId: state.answerAndQuestionsId.securityQuestionsId0,
                answer: state.answerAndQuestionsId.answer0,
            },
            {
                securityQuestionsId: state.answerAndQuestionsId.securityQuestionsId1,
                answer: state.answerAndQuestionsId.answer1,
            },
            {
                securityQuestionsId: state.answerAndQuestionsId.securityQuestionsId2,
                answer: state.answerAndQuestionsId.answer2,
            },
        ]

        dispatch({type: 'cargando', payload: true})

        await axios.put(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}securityQuestions/${email}`, data, { headers })
        .then(response => {
            if(response.status === 200){
                window.location.href = `/nueva-contraseña?email=${email}`
            }
        })
        .catch(() => {
            dispatch({type: 'cargando', payload: false})
            dispatch({type: 'openInvalidIdentity'})
        })
    }

    const handleChangeAnswerField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeAnswerField', payload: {name: e.target.name, value: e.target.value}})
    }

    const handleChangeSelect = (event: SelectChangeEvent<string>) => {
        dispatch({type: 'handleChangeSelect', payload: {value: event.target.value, name: event.target.name}})
        dispatch({type: 'setIdSelected', payload: {id: Number(event.target.value)}})
    };

    const questionSelectedId = (id: number) => {
        dispatch({type: 'questionSelectedId', payload: {id: id}})
    };
    
    const handleChangeAnswers = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeAnswers', payload: {name: e.target.name, value: e.target.value}})
    }

    const clickRecoverPassword = () => {
        dispatch({type: 'clickRecoverPassword', payload: true})
    };

    const handleCloseInvalidIdentity = () => {
        dispatch({type: 'handleCloseInvalidIdentity'})
    };

    return(
        <LoginContext.Provider value={{
            state,
            doLogin,
            handleChangeValues,
            changePassword,
            getSecurityQuestions,
            getSecurityQuestionsByUser,
            validateSecurityQuestion,
            handleChangeAnswerField,
            handleChangeSelect,
            questionSelectedId,
            addSecurityQuestions,
            handleChangeAnswers,
            clickRecoverPassword,
            handleCloseInvalidIdentity
        }}>
            {children}
        </LoginContext.Provider>
    )
}

export default LoginProvider