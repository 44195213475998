import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, MenuItem, Typography, FormControl, Select, InputLabel, SelectChangeEvent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';


const InputLabelStyled = styled(InputLabel)(() => ({
    color: '#3B72FF',
    'span': {
        color: 'red'
    }
}));

interface Props {
    openModal: boolean
    handleCloseModal: () => void
    customGetDentalRecordsById: (id: number) => void
    providerByAffiliateCode: any
    setDentalRecordsId: (id: number) => void 
    dentalRecordsId: number
    loadingAffiliateCode:boolean
    
}


export default function ModalImportOdontogram({ openModal, providerByAffiliateCode, handleCloseModal,
     customGetDentalRecordsById , dentalRecordsId ,setDentalRecordsId, loadingAffiliateCode }:Props) {
    
    const handleSelectChange = (event: SelectChangeEvent<number>) => {
        setDentalRecordsId(Number(event.target.value))
    }

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        customGetDentalRecordsById(dentalRecordsId);
        handleCloseModal();
    }
    useEffect(() => {
        const existProviderByAffiliateCode =  providerByAffiliateCode.find(({dentalRecordsId}:any) => dentalRecordsId == -1);
        if(!existProviderByAffiliateCode) providerByAffiliateCode.unshift({dentalRecordsId: -1 , date: "Ninguno"})
        else return;
        if(!loadingAffiliateCode){
        setDentalRecordsId(providerByAffiliateCode[1]?.dentalRecordsId ?? -1);
        if( dentalRecordsId == 0 && !!providerByAffiliateCode[1]?.dentalRecordsId) {customGetDentalRecordsById(providerByAffiliateCode[1]?.dentalRecordsId)}
        }
    },[providerByAffiliateCode])
    return (
        <Dialog
            open={openModal}
            onClose={handleCloseModal}
        >
            <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>  

                <>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} mt={6}>
                        <DialogContent>
                            <Typography>
                                Importacion de <b>Odontograma</b>
                            </Typography>
                        </DialogContent>
                    </Box>
                    <DialogActions sx={{flexDirection: 'column'}}>
                        <Box component='form' onSubmit={handleSubmit} sx={{width: '90%'}}>
                            <Grid container spacing={1}>
                                <FormControl fullWidth variant='outlined' required>
                                    <InputLabelStyled>Fichas</InputLabelStyled>
                                    <Select
                                        label="Fichas"
                                        value={dentalRecordsId ?? ''} 
                                        name='affiliateId' 
                                        onChange={(e) => handleSelectChange(e)}
                                        sx={{mb: 3}}
                                    >
                                        {providerByAffiliateCode.map(({dentalRecordsId , date}:any) => {
                                            return <MenuItem key={dentalRecordsId} value={dentalRecordsId}>
                                                {`${date}`}
                                            </MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid container spacing={1} justifyContent='center' mt={1}>
                                <Button 
                                    type='submit'
                                    variant='contained' 
                                    sx={{margin: '25px', justifyContent: 'center', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                                >
                                    Aplicar
                                </Button>
                            </Grid>
                        </Box>
                    </DialogActions>
                </>
        
        </Dialog>
    );
}