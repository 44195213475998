import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext"
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';
import moment from 'moment';
import { useContext } from "react"
import PaperContainer from '../PaperContainer';
import { styled } from '@mui/material/styles';

const TextFieldStyled = styled(TextField)(() => ({
    '.MuiInputLabel-root': {
        'span': {
            color: 'red'
        }
    }
}));

const ProfessionalData = () => {

    const { state, handleChangeInput, handleChangeDatePicker, saveProfessionals } = useContext(EmpadronamientoContext)

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        saveProfessionals()
    }

    return (
        <PaperContainer>
            <Box textAlign='center' mb={6} mt={2} pt={2} pr={1} pl={1}>
                <Box sx={{width: '60%', marginLeft: '20%', marginTop: 6}}>
                    <Typography variant='h5' sx={{fontWeight: 700}}>Matrícula</Typography>
                    <Typography>Ingresa los datos de tu Matrícula Nacional y Provincial</Typography>
                </Box>

                <Box 
                    component='form' 
                    onSubmit={handleSubmit}
                    className='formContainer'
                    sx={{width: '90%', marginLeft: '5%'}}
                >
                    <Grid container justifyContent='space-between' alignItems='center' spacing={3} mt={3}>
                        <Grid xs={12} sm={6} md={6} item>
                            <TextFieldStyled 
                                fullWidth 
                                label='Nº Matrícula Provincial' 
                                onChange={handleChangeInput}
                                name="provincialEnrollment"
                                required
                            />
                        </Grid>
                        <Grid xs={12} sm={6} md={6} item>
                            <TextFieldStyled 
                                fullWidth 
                                label='Nº Matrícula Nacional' 
                                onChange={handleChangeInput}
                                name="nationalEnrollment"
                            />
                        </Grid>
                        <Grid xs={12} sm={12} md={12} item>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Fecha de Ingreso en la Actividad"
                                    value={state.professionalItems.startActivity ? state.professionalItems.startActivity : null}
                                    onChange={(newValue) => handleChangeDatePicker(moment(newValue).format('YYYY-MM-DD'), 'startActivity')}
                                    renderInput={(params) => <TextFieldStyled {...params} required variant='outlined' fullWidth margin="dense" />}
                                />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>

                    <Grid mt={4}>
                        {state.messageError && state.messageError.length ?
                            state.messageError.map((message, index) => (
                                <Typography color='error' variant="body1" key={index}>{message}</Typography>
                            ))
                        : null}
                    </Grid>

                    <Button 
                        type='submit' 
                        variant='contained'
                        sx={{
                            background: '#42C5B7',
                            '&:hover': {background: '#389b90'},
                            borderRadius: 30,
                            padding: '5px 40px 5px 40px',
                            margin: '65px 0px 30px 0px',
                        }}
                    >
                        Siguiente
                    </Button> 
                </Box>
            </Box>
        </PaperContainer>
    )
}

export default ProfessionalData