import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
interface Props {
    openModal: boolean
    handleCloseModal: () => void
    handleClickCheckHealthyMouth: (event: React.ChangeEvent<HTMLInputElement>) => void
}


export default function ModalCheckHealthyMounth({ openModal, handleCloseModal , handleClickCheckHealthyMouth}:Props) {

    const handleButtonCancel = () => {
       handleCloseModal()
    }

    const handleButtonAccept = () => {
        handleClickCheckHealthyMouth({target: {checked: true} } as any)
        handleCloseModal()  
    }
    return (
        <Dialog
            open={openModal}
            onClose={handleCloseModal}
        >
            <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>  

                <>
            <DialogActions sx={{flexDirection: 'column'}}>

                   <Box sx={{display: 'flex', alignItems: 'center'}} mt={2}>
                        <DialogContent>
                            <Typography variant='subtitle1'>
                              Esta acción <b>borrará</b> todos los cambios realizados en el <b>Odontograma</b>, ¿Quieres continuar?
                            </Typography>
                        </DialogContent>
                    </Box>
                        <Box sx={{display: 'flex', alignItems: 'center', p:1, mb:1}}>
                            <Grid container spacing={1} justifyContent='center' >
                                <Button 
                                 onClick={handleButtonCancel}
                                    variant='contained' 
                                    sx={{margin: '25px', justifyContent: 'center', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                                >
                                    Cancelar
                                </Button>
                            </Grid>
                            <Grid container spacing={1} justifyContent='center' >
                                <Button 
                                 onClick={handleButtonAccept}
                                    variant='contained' 
                                    sx={{margin: '25px', justifyContent: 'center', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                                >
                                    Aceptar
                                </Button>
                            </Grid>
                        </Box>
                    </DialogActions>
                </>
        
        </Dialog>
    );
}