import Box from '@mui/material/Box';
import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext"
import { useContext, useState, useEffect } from "react"
import PaperContainer from '../PaperContainer';
import { styled } from '@mui/material/styles';
import Pagination from '../Pagination';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Loader from '../Loader';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SummaryModal from './SummaryModal';
import Container from '../Container';
import { EnrollmentDetailItems } from '../../interfaces/Empadronamiento';
import { useParams } from 'react-router-dom';
import GeneralRuleModal from '../GeneralRuleModal';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3B72FF',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const TypographyStyled = styled(Typography)(() => ({
    color: '#3B72FF',
    fontWeight: 'bolder',
    marginRight: 8
}));

const DividerStyled = styled(Divider)(() => ({
    borderColor: '#64AAB2',
    marginTop: 5,
    marginBottom: 25,
    width: '100%'
}));

interface Props {
    section: string
    propData?: EnrollmentDetailItems
}

const Summary = (props: Props) => {

    const { state, handleChangeView, handleNextStep, saveEnrollment} = useContext(EmpadronamientoContext)

    const {id} = useParams();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openGeneralRuleModal, setOpenGeneralRuleModal] = useState(false)
    const [generalRules, setGeneralRules] = useState({particularRegulations: '', arancelNomenclature: '', generalInformation: '' })    

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const secureSelected = state.insuranceTypeData.find(insurance => insurance.insuranceTypeId === state.secureId)
    // traigo solo las obras sociales que tienen al menos una cobertura seleccionada
    const insurancesChecked = state.insuranceData.filter(item => item.generalCoverages.some(gc => gc.status === true));
    const findCircleName = state.dataCircles.find(circle => circle.circlesId === props.propData?.circleId)?.name

    const handleOpenGeneralRuleModal = (insuranceId: number) => {
        setOpenGeneralRuleModal(true)
        const insuranceSelected = insurancesChecked.filter(d => d.insurancesId === insuranceId)[0]
        setGeneralRules({particularRegulations: insuranceSelected.particularRegulations, arancelNomenclature: insuranceSelected.arancelNomenclature, generalInformation: insuranceSelected.generalInformation})
    }

    const handleCloseGeneralRuleModal = () => {
        setOpenGeneralRuleModal(false)
    }

    const consultorios = (street: string, height: number | string, index: number) => {
        const coma = index + 1 !== state.consultorioCargado.length
        return `${street ? street : ''} ${height ? height : ''} ${!street && !height ? 'Sin dirección' : ''}${coma ? ', ' : ''}`
    }
    
    const idFopbaInsider = state.userCircles.find(circle => circle.name === state.circleName)?.idFopbaInsider

    if(state.loading) {
        return <Loader />
    }

    const content = <>
        <Box textAlign='center' mb={6} mt={2} pt={2} pr={1} pl={1}>
            <Box sx={{width: '60%', marginLeft: '20%', marginTop: 1}}>
                <Typography variant='h6' sx={{fontWeight: 700}}>Resumen</Typography>
                <Typography>Revisa tus datos para empezar a utilizar la plataforma</Typography>
            </Box>

            <Box 
                className='formContainer'
                sx={{width: '90%', marginLeft: '5%', marginTop: 5}}
            >
                <Box sx={{width: '100%', display: 'flex'}}>
                    <Typography variant='h6'>Detalle</Typography>
                </Box>
                <DividerStyled />

                <Grid container spacing={3} flexDirection='column' mb={3}>
                    <Grid item container xs={12} sm={12} md={12}>
                        <TypographyStyled>Círculo:</TypographyStyled>{state.circleName ? idFopbaInsider + ' - ' + state.circleName : findCircleName}
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12}>
                        {state.consultorioCargado.length > 1 ? <TypographyStyled>Consultorios:</TypographyStyled> : null}
                        {state.consultorioCargado.length > 1 ?
                            state.consultorioCargado.map((consultorio, index) => (
                                consultorios(consultorio.street, consultorio.height, index)
                            ))
                        :
                            <>
                                <TypographyStyled>Consultorio:</TypographyStyled>
                                {!state.consultorioCargado[0].street && !state.consultorioCargado[0].height ? 'Sin dirección' : state.consultorioCargado[0].street + ' ' + state.consultorioCargado[0].height}
                            </>
                        }
                    </Grid>
                    <Grid item container xs={12} sm={12} md={12}>
                        <TypographyStyled>Seguro:</TypographyStyled>{secureSelected?.description}
                    </Grid>
                </Grid>

                {state.loadingInsurance ?
                    <Loader />
                :
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Código</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Obra Social</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Datos Prestacionales</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Odontología General</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Prótesis</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Ortodoncia</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 
                                    ? insurancesChecked.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : insurancesChecked
                                ).map((row) => {
                                    const particularRulesDisabled = !row.particularRegulations && !row.arancelNomenclature && !row.generalInformation
                                    return <StyledTableRow
                                        key={row.insurancesId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.idFopbaInsider}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.name}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '5px'}} align="left">
                                            <LibraryBooksIcon 
                                                onClick={particularRulesDisabled ? () => {} : () => handleOpenGeneralRuleModal(row.insurancesId)} 
                                                style={particularRulesDisabled ? {color: 'gray', textDecoration: 'none', width: '100%'} : {color: '#3C78F8', textDecoration: 'none', width: '100%', cursor: 'pointer'}} 
                                            />
                                        </StyledTableCell>
                                        {row.generalCoverages.map((coverage, index) => {
                                            return <StyledTableCell key={index} sx={{padding: '12px'}} align="center"><Checkbox checked={coverage.status ? coverage.status : false} disabled={!coverage.status} onChange={() => {}} /></StyledTableCell>
                                        })}
                                    </StyledTableRow>
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                        colSpan={3}
                                        count={insurancesChecked.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Filas por pagina',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage='Filas por Página'
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => <Pagination count={insurancesChecked.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                }

                <Grid mt={4}>
                    {state.messageError && state.messageError.length ? 
                        state.messageError.map((message, index) => (
                            <Typography color='error' variant="body1" key={index}>{message}</Typography>
                        ))
                    : null}
                </Grid>
            
                <Grid container justifyContent='center' spacing={2}>
                    <Grid item>
                        <Typography 
                            onClick={() => {handleChangeView('step1'); handleNextStep()}}
                            sx={{color: '#2639ED', textDecoration: 'underline', cursor: 'pointer', marginTop: '35px'}}
                        >
                            Editar información
                        </Typography>
                        <Button 
                            type='button' 
                            variant='contained' 
                            sx={{
                                background: '#42C5B7',
                                '&:hover': {background: '#389b90'},
                                borderRadius: 30,
                                padding: '5px 40px 5px 40px',
                                margin: '15px 0px 30px 0px',
                            }}
                            onClick={() => saveEnrollment(Number(id))}
                        >
                            Finalizar
                        </Button> 
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <SummaryModal openModal={state.openSummaryModal} circlesLength={state.userCircles.length}/>
        <GeneralRuleModal 
            handleCloseGeneralRuleModal={handleCloseGeneralRuleModal}
            openGeneralRuleModal={openGeneralRuleModal}
            particularRegulations={generalRules.particularRegulations}
            arancelNomenclature={generalRules.arancelNomenclature}
            generalInformation={generalRules.generalInformation}
        />
    </>

    return (
        props.section === 'empadronamiento' ? <PaperContainer>{content}</PaperContainer> : <Container child={content}/>
    )
}

export default Summary