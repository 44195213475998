import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Logo from '../img/logo.png'

interface Props {
    children: React.ReactNode,
}

const PaperContainer = (props: Props) => {

    return (
        <Box>
            <Grid container justifyContent='space-evenly'>
                <Grid md={2} item textAlign='end' mt={2}>
                    <img src={Logo} alt='Logo' />
                </Grid>
                <Grid md={10} item>
                    <Paper elevation={3} sx={{ width: {sm: '90%', md: '70%'}, marginLeft: {sm: '0%', md: '6%'}, borderRadius: 10}}>
                        {props.children}
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PaperContainer