import {useContext, useEffect, useState} from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { styled } from '@mui/material/styles';
import ContactContext from "../context/Contact/ContactContext"
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WebIcon from '@mui/icons-material/Web';
import './style.css';
import { Tooltip } from '@mui/material';
import ModalLegalNotice from './ModalLegalNotice';

const TypographyCustom = styled(Typography)(() => ({
    fontFamily: 'League Spartan, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 25,
    color: '#42C5B7'
}));

const Index = () => {

    const [openModal, setOpenModal] = useState(false)

    const { 
        getContact,
        state 
    } = useContext(ContactContext)

    useEffect(() => {
        getContact()
    }, [])
    
    return (
        <Box className='footerContainer'>
            <Grid container justifyContent='space-between' alignItems='center' sx={{padding: {xs: '0px 5px 5px 5px', md: 2} }}>
                <Grid xs={12} sm={4} item>
                    <TypographyCustom>FOPBA</TypographyCustom>
                    <Typography className='colorWhite' variant='body2'>Federación Odontológica de la Pcia. Buenos Aires</Typography>
                </Grid>
                <Grid xs={12} sm={4} item sx={{textAlign: {xs: '', md: 'end'}}}>
                    <Typography className='colorWhite' variant='caption' sx={{cursor: 'pointer'}} onClick={() => setOpenModal(true)} >Aviso legal - Todos los derechos reservados.</Typography>
                </Grid>
                <Grid xs={12} sm={4} item sx={{textAlign: {xs: '', md: 'end'}}}>
                    <Box>
                        <a href={'https://fopba.org.ar/'} rel="noreferrer" target='_blank' className='colorWhite'>
                            <Tooltip title="Sitio web FOPBA">
                                <WebIcon sx={{fontSize: 20, marginRight: 2}} />
                            </Tooltip>
                        </a>
                        <a href={state.contact.linkedin} rel="noreferrer" target='_blank' className='colorWhite'>
                            <Tooltip title="LinkedIn">
                                <LinkedInIcon sx={{fontSize: 20, marginRight: 2}} />
                            </Tooltip>
                        </a>
                        <a href={state.contact.twitter} rel="noreferrer" target='_blank' className='colorWhite'>
                            <Tooltip title="Twitter">
                                <TwitterIcon sx={{fontSize: 20, marginRight: 2}} />
                            </Tooltip>
                        </a>
                        <a href={state.contact.instagram} rel="noreferrer" target='_blank' className='colorWhite'>
                            <Tooltip title="Instagram">
                                <InstagramIcon sx={{fontSize: 20, marginRight: 2}} />
                            </Tooltip>
                        </a>
                        <a href={state.contact.facebook} rel="noreferrer" target='_blank' className='colorWhite'>
                            <Tooltip title="Facebook">
                                <FacebookIcon sx={{fontSize: 20, marginRight: 2}} />
                            </Tooltip>
                        </a>
                        <a href={state.contact.youtube} rel="noreferrer" target='_blank' className='colorWhite'>
                            <Tooltip title="YouTube">
                                <YouTubeIcon sx={{fontSize: 20}} />
                            </Tooltip>
                        </a>
                    </Box>
                </Grid>
            </Grid>
            <ModalLegalNotice openModal={openModal} handleCloseModal={() => setOpenModal(false)} />
        </Box>
    )
}

export default Index