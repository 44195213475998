import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import LoginContext from "../../components/context/Login/LoginContext"
import Logo from '../../img/logo.png'
import { useContext, useEffect, useState } from "react"
import Loader from '../Loader';

const Index = () => {

    const { state, getSecurityQuestions, handleChangeAnswerField, handleChangeSelect, questionSelectedId, addSecurityQuestions } = useContext(LoginContext)
    const [activeStep, setActiveStep] = useState(0);

    useEffect(() => {
        getSecurityQuestions()
    }, [])
    

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>)  => {
        e.preventDefault()
        addSecurityQuestions()
    }

    const steps = ['', '', ''];
    const currentStep = activeStep + 1

    const isLastStep = () => {
        return activeStep === 2;
    };
    
    const handleNext = () => {
        const newActiveStep = isLastStep() ? steps.findIndex((step, i) => !(i in {})) : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    let nameAnswers = '';
    let nameQuestion = '';
    switch (currentStep) {
        case 1:
            nameAnswers = 'firstAnswer'
            nameQuestion = 'firstQuestion'
            break;
        case 2:
            nameAnswers = 'secondAnswer'
            nameQuestion = 'secondQuestion'
            break;
        case 3:
            nameAnswers = 'thirdAnswer'
            nameQuestion = 'thirdQuestion'
            break;
        default:
            nameAnswers = 'firstAnswer'
            nameQuestion = 'firstQuestion'
            break;
    }

    const disabledButton = () => {
        // si esta en la primera pregunta y le falta completar la pregunta o la respuesta retorno "true" para que se deshabilite el boton continuar
        if(currentStep === 1 && (!state.answerAndQuestions.firstAnswer || !state.answerAndQuestions.firstQuestion)){
            return true
        } else if (currentStep === 2 && (!state.answerAndQuestions.secondAnswer || !state.answerAndQuestions.secondQuestion)){
            return true
        } else if (currentStep === 3 && (!state.answerAndQuestions.thirdAnswer || !state.answerAndQuestions.thirdQuestion)){
            return true
        } else {
            return false
        }
    }

    if(state.cargando){
        return <Loader />
    }

    return (
        <Box>
            <Grid container justifyContent='space-evenly'>
                <Grid md={2} item textAlign='end' mt={2}>
                    <img src={Logo} alt='Logo' />
                </Grid>
                <Grid md={10} item>
                    <Paper elevation={3} sx={{ width: {xs: '90%', sm: '90%', md: '70%'}, marginLeft: {xs: '5%', sm: '5%', md: '6%'}, borderRadius: 10}}>
                        <Box textAlign='center' mb={6} mt={2} pt={2} pr={1} pl={1}>
                            <Typography variant="h5" mb={4} sx={{fontWeight: 700}}>Preguntas de seguridad</Typography>
                            <Typography>Creá 3 preguntas de seguridad para recuperar tu cuenta en caso que lo necesites.</Typography>
                        </Box>
                            
                        <Box 
                            component='form' 
                            onSubmit={handleSubmit}
                            className='formContainer'
                        >
                            <FormControl sx={{width: '70%', mb: 3}}>
                                <InputLabel>Pregunta {currentStep}</InputLabel>
                                <Select
                                    value={state.answerAndQuestions[nameQuestion]}
                                    label={`Pregunta ${currentStep}`}
                                    name={nameQuestion}
                                    onChange={handleChangeSelect}
                                >
                                    {state.listQuestions.map((question) => (
                                        <MenuItem key={question.securityQuestionsId} value={question.securityQuestionsId}>{question.question}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField 
                                label={`Respuesta ${currentStep}`}
                                variant="standard"
                                name={nameAnswers}
                                sx={{width: '70%', mb: 12}}
                                value={state.answerAndQuestions[nameAnswers]}
                                onChange={(e) => handleChangeAnswerField(e)}
                                margin="dense"
                                className="inputWidth"
                            />
                            <Stepper nonLinear activeStep={activeStep}>
                                {steps.map((label, index) => (
                                    <Step key={index}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                            <Button 
                                type={currentStep === 3 ? 'submit' : 'button'} 
                                variant='contained' 
                                sx={{
                                    background: '#42C5B7',
                                    '&:hover': {background: '#389b90'},
                                    borderRadius: 30,
                                    padding: '5px 40px 5px 40px',
                                    margin: '65px 0px 30px 0px',
                                }}
                                disabled={disabledButton()}
                                onClick={currentStep === 3 ? () => {} : () => {handleNext(); questionSelectedId(state.idSelected)}}
                            >
                                {currentStep === 3 ? 'Listo!' : 'Siguiente'}
                            </Button> 
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Index