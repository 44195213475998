import { useContext, useEffect, useState } from "react"
import Container from '../Container'
import { Box, Divider, Grid, Typography, Paper } from '@mui/material'
import MuiButton from '@mui/material/Button'
import { TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext"
import EditIcon from '@mui/icons-material/Edit';
import Loader from "../Loader";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
import Button from '../Button';
import { tokenDecode } from '../../interfaces/Login';
import jwt_decode from "jwt-decode";

const TypographyStyled = styled(Typography)(() => ({
    color: '#3B72FF',
    fontWeight: 'bolder',
    marginRight: 8
}));

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3B72FF',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Index = () => {

    const { state, getProfessionals, getEnrollment, rulesController, getEnrollmentStatus, getUserCircles } = useContext(EmpadronamientoContext)

    useEffect(() => {
        getProfessionals()
        getEnrollment()
        getEnrollmentStatus()
        getUserCircles()
        rulesController()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(state.loading || state.loadingEnrollment){
        return <Loader />
    }

    const enrollmentLength = state.enrollmentData.length
    const lastEnrollment = state.enrollmentData[enrollmentLength - 1]
    const lastEnrollmentId = lastEnrollment?.registrationsId

    return (
        <Container
            child={
                <Box>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                            <h4>Datos Profesionales</h4>
                        </Grid>
                        {/* muestro el boton de reempadronamiento solo si es perfil profesional y si esta habilitado en las reglas */}
                        {decoded.profileId === 2 && state.showReempadronamientoButton?
                            <Grid item>
                                <MuiButton variant="contained">
                                    <Link to={`/reempadronamiento/${lastEnrollmentId}`} style={{color: '#fff', textDecoration: 'none'}}>Reempadronarme</Link>
                                </MuiButton>
                            </Grid>
                        : null}
                    </Grid>
                    
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                    <Grid container spacing={3} justifyContent='space-between'>
                        {state.enrollmentData.length ?
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Nombre:</TypographyStyled>{state.enrollmentData[0].name ? state.enrollmentData[0].name : ''}
                        </Grid>
                        : null}
                        {state.enrollmentData.length ?
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Apellido:</TypographyStyled>{state.enrollmentData[0].lastName ? state.enrollmentData[0].lastName : ''}
                        </Grid>
                        : null}
                        {state.dataProfessional.nationalEnrollment ? 
                            <Grid item container xs={12} sm={6} md={6}>
                                <TypographyStyled>Nº Matrícula Nacional:</TypographyStyled>{state.dataProfessional.nationalEnrollment}
                            </Grid>
                        : null}
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Nº Matrícula Provincial:</TypographyStyled>{state.dataProfessional.provincialEnrollment}
                        </Grid>

                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Fecha de Ingreso en la Actividad:</TypographyStyled>{state.dataProfessional.startActivity}
                        </Grid>

                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Fecha de Ingreso en FOPBA:</TypographyStyled>{state.dataProfessional.fopbaRegistration ? state.dataProfessional.fopbaRegistration : ' - '}
                        </Grid>
                    </Grid>
                    <h4>Empadronamientos</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Circulo</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Fecha de Empadronamiento</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Estado</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Detalle</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 
                                    ? state.enrollmentData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : state.enrollmentData
                                ).map((row) => (
                                    <StyledTableRow
                                        key={row.registrationsId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{state.userCircles.filter(item => item.circleId === row.circleId).map(circle => circle.idFopbaInsider + ' - ' + circle.name)}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.registrationFinishDate}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{state.enrollmentStatus.find(item => item.registrationStatesId === row.status)?.description}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="center">
                                            <Link to={`/usuario/detalle-de-empadronamiento/${row.registrationsId}`} style={{color: '#3C78F8', textDecoration: 'none', width: '100%'}}>
                                                <SearchIcon color="primary" sx={{cursor: 'pointer'}} />
                                            </Link>
                                            {row.status === 6 && decoded.profileId === 2 ?
                                                <Link to={`/modificar-empadronamiento/${row.registrationsId}`} style={{color: '#3C78F8', textDecoration: 'none', width: '100%', marginLeft: 10}}>
                                                    <EditIcon color="primary" sx={{cursor: 'pointer'}} />
                                                </Link>
                                            : null}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                        colSpan={3}
                                        count={state.enrollmentData.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Filas por pagina',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage='Filas por Página'
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => <Pagination count={state.enrollmentData.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>

                    <Grid container justifyContent='end' mt={4}>
                        <Grid item xs={4} sm={4} md={3}>
                            <Button 
                                child={
                                    <Link style={{textDecoration: 'none', color: '#4e4e4e', width: '100%'}} to={'/usuario'}>
                                        Volver
                                    </Link>
                                } 
                                variant='outlined' 
                                color='secondary'
                            />
                        </Grid>
                    </Grid>
                </Box>
            }
        />
    )
}

export default Index