import { useContext, useEffect, useState } from "react"
import Container from '../Container'
import { Box, Divider, Paper } from '@mui/material'
import MuiButton from '@mui/material/Button'
import { TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import OfficesContext from "../context/Offices/OfficesContext"
import Loader from "../Loader";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3B72FF',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Index = () => {

    const { state, getOffices, getAllLocalidades } = useContext(OfficesContext)

    useEffect(() => {
        getOffices()
        getAllLocalidades()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(state.loading || state.loadingAllDatosDemograficos){
        return <Loader />
    }
    
    return (
        <Container
            child={
                <Box>
                    <h4>Consultorios</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />

                    <MuiButton variant="contained" sx={{marginBottom: 2}}>
                        <Link style={{textDecoration: 'none', color: '#fff', width: '100%'}} to={'/agregar-consultorio'}>
                            Agregar Consultorio
                        </Link>
                    </MuiButton>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Calle</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Número</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Teléfono / Celular</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Ciudad</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Estado</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Detalle</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 
                                    ? state.offices.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : state.offices
                                ).map((row) => (
                                    <StyledTableRow
                                        key={row.officesId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.street}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.height}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.phone}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">
                                            {state.allLocalidades.find(d => d.townsId === Number(row.town))?.name}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.officeStateId === 3 ? "Inactivo" : "Activo"}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '5px'}} align="center">
                                            <Link to={`/consultorio/${row.officesId}`} style={{color: '#3C78F8', textDecoration: 'none', width: '100%'}}>
                                                <SearchIcon color="primary" sx={{cursor: 'pointer'}} />
                                            </Link>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                        colSpan={3}
                                        count={state.offices.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Filas por pagina',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage='Filas por Página'
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => <Pagination count={state.offices.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            }
        />
    )
}

export default Index