import { Button, Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination, Box, Grid, TextField } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Pagination from '../Pagination';
import CirculoContext from '../context/Circles/CircleContext';
import Divider from '@mui/material/Divider';
import Loader from '../Loader';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Container from '../Container';
import ListPermissions from '../../utils/ListPermissions';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3B72FF',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Index = () => {

    const {
        state, 
        getCirculos,
        handleChangeFilter,
        getCirclesFilter,
        clearFilter
    } = useContext(CirculoContext)

    useEffect(() => {
        getCirculos()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(!ListPermissions().listarCirculos){
        window.location.href = '/inicio'
    }

    if((!state.data.length && !state.filtered) || state.loading){
        return <Loader />
    }

    const FilterDisabled = state.filterCircleName.length >= 3 || state.filterCircleId

    return (
        <Container 
            child={
                <Box>
                    <h4>Círculos</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                    <Grid container justifyContent='space-between' alignItems='center' spacing={3} mb={3}>
                        <Grid xs={6} sm={4} md={5} item>
                            <TextField fullWidth value={state.filterCircleName} name='filterCircleName' label='Nombre' onChange={(e) => handleChangeFilter(e)} />
                        </Grid>
                        <Grid xs={6} sm={4} md={5} item>
                            <TextField fullWidth value={state.filterCircleId} name='filterCircleId' label='Código' onChange={(e) => handleChangeFilter(e)} />
                        </Grid>
                        <Grid xs={4} sm={3} md={2} item>
                            <Button variant="outlined" size={state.filterCircleName || state.filterCircleId ? 'small' : 'large'} fullWidth startIcon={<FilterAltIcon />} onClick={() => {getCirclesFilter(); setPage(0)}} disabled={!FilterDisabled}>Filtrar</Button>
                            {state.filterCircleName || state.filterCircleId ? 
                                <Button variant="outlined" size='small' fullWidth color='error' startIcon={<FilterAltOffIcon />} onClick={() => {clearFilter(); setPage(0)}}>Limpiar</Button>
                            : null}
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Código</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Nombre</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Autoridad</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Estado</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Detalle</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 
                                    ? state.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : state.data
                                ).map((row) => (
                                    <StyledTableRow
                                        key={row.circlesId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.idFopbaInsider}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.name}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.authorityName}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.circleState.description}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '5px'}} align="center">
                                            <Link to={`/detalle-de-circulo/${row.circlesId}`} style={{color: '#3C78F8', textDecoration: 'none', width: '100%'}}>
                                                <RemoveRedEyeOutlinedIcon />
                                            </Link>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                        colSpan={3}
                                        count={state.data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Filas por pagina',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage='Filas por Página'
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => <Pagination count={state.data.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </Box>
            } 
        />
    )
}

export default Index