import { ContactState, Contact } from '../../../interfaces/Contact';

type Action = {type: 'getContact', payload: Contact} | {type: 'loading', payload: boolean}

export default function ContactReducer (state: ContactState, action: Action): ContactState {
    switch (action.type) {
        case 'getContact':
            return { 
                ...state,
                contact: action.payload,
                loading: false
            }
        case 'loading':
            return { 
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}