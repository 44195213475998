import { useReducer } from 'react'
import { EmpadronamientoState, EnrollmentDetailItems } from '../../../interfaces/Empadronamiento';
import EmpadronamientoContext from './EmpadronamientoContext';
import EmpadronamientoReducer from './EmpadronamientoReducer';
import { SelectChangeEvent } from "@mui/material";
import axios from 'axios';
import moment from 'moment'

const INITIAL_STATE: EmpadronamientoState = {
    enrollmentData: [],
    enrollmentStatus: [],
    allEnrollmentsData: [],
    allEnrollmentsDetail: {
        circleId: 0,
        collectiveFile: '',
        hasXRay: false, 
        officesResponse: [{
            officesId: 0,
            street: '',
            height: 0,
            floor: '',
            apartment: '',
            postalCode: 0,
            town: '',
            department: '',
            state: '',
            country: '',
            googlePlaceId: '',
            phone: '',
            cellPhone: '',
            hasXRay: ''
        }],
        registrationHistoryResponses: [{status: 0, name: '', lastname: '', comment: '', date: '', registrationStateId: 0}], 
        secureId: 0,
        specialitiesResponse: [{
            specialityId: 0,
            isExclusive: '',
            certifyFile: null,
            certifyFileBase64: '',
            antiquity: 0,
            awardedBy: '',
            awardedYear: 0
        }],
        insurancesResponse: [{insurancesId: 0, name: '', generalCoverages: [], idFopbaInsider: 0}],
        name: '',
        lastName: '',
        registrationStateId: 0,
        provincialEnrollment: '',
        acceptTerms: false,
        secureFinishDate: '',
        secureStartDate:  '',
        userFile: ''
    },
    enrollmentDetail: {
        circleId: 0,
        collectiveFile: '',
        hasXRay: false, 
        officesResponse: [{
            officesId: 0,
            street: '',
            height: 0,
            floor: '',
            apartment: '',
            postalCode: 0,
            town: '',
            department: '',
            state: '',
            country: '',
            googlePlaceId: '',
            phone: '',
            cellPhone: '',
            hasXRay: ''
        }],
        registrationHistoryResponses: [{status: 0, name: '', lastname: '', comment: '', date: '', registrationStateId: 0}], 
        secureId: 0,
        specialitiesResponse: [{
            specialityId: 0,
            isExclusive: '',
            certifyFile: null,
            certifyFileBase64: '',
            antiquity: 0,
            awardedBy: '',
            awardedYear: 0
        }],
        insurancesResponse: [{insurancesId: 0, name: '', generalCoverages: [], idFopbaInsider: 0}],
        name: '',
        lastName: '',
        registrationStateId: 0,
        provincialEnrollment: '',
        acceptTerms: false,
        secureFinishDate: '',
        secureStartDate:  '',
        userFile: ''
    },
    viewToShow: 'start',
    dataProfessional: {
        nationalEnrollment: '',
        provincialEnrollment: '',
        startActivity: '',
        fopbaRegistration: ''
    },
    professionalItems: {
        nationalEnrollment: '',
        provincialEnrollment: '',
        startActivity: '',
        fopbaRegistration: ''
    },
    dataCircles: [],
    circleName: '',
    circleId: 0,
    activeStep: 0,
    steps: ['', '', '', '', ''],
    consultorioItems: {
        officesId: 0,
        street: '',
        height: 0,
        floor: '',
        apartment: '',
        postalCode: '',
        town: '',
        department: '',
        state: '',
        country: '',
        googlePlaceId: '',
        phone: '',
        cellPhone: '',
        hasXRay: '',
        betweenStreet: '',
        observations: '',
        attentionHours: '',
        email: '',
        secondEmail: '',
        availabilityDate: '',
        availability: '',
        officeStateId: 0
    },
    consultorioCargado: [],
    insuranceData: [],
    insuranceFiltered: [],
    insurancesSelected: [],
    InsurancesSelectedItems: {
        insuranceId: 0,
        generalCoverageIds: []
    },
    loadingInsurance: false,
    insuranceTypeData: [],
    insuranceTypeItems: {
        insuranceTypeId: 0,
        description: '',
        requireDocumentation: false,
        collectiveFile: null,
        collectiveFileBase64: ''
    },
    nameFilter: '',
    filtered: false,
    mainSpecialityData: [],
    mainSpecialityItems: {
        specialityId: 0, 
        generalCoverageId: 0,
        description: '',
        idFopbaInsider: 0
    },
    mainSpecialityName: '',
    specialityItems: {
        specialityId: 0,
        isExclusive: '',
        certifyFile: null,
        certifyFileBase64: '',
        antiquity: 0,
        awardedBy: '',
        awardedYear: 0,
        idFopbaInsider: 0
    },
    specialityLoad: [{
        specialityId: 16,
        isExclusive: "false",
        certifyFile: null,
        certifyFileBase64: '',
        antiquity: 0,
        awardedBy: '',
        awardedYear: 0,
    }],
    secureId: 0,
    openSummaryModal: false,
    openConfirm: false,
    provincias: [],
    departamentos: [],
    localidades: [],
    allLocalidades: [],
    loading: true,
    loadingEnrollmentDetail: false,
    loadingEnrollment: false,
    loadingAllEnrollments: false,
    enrollmentNameFilter: '',
    enrollmentLastNameFilter: '',
    enrollmentlicenseFilter: '',
    enrollmentStatusFilter: '',
    enrollmentFiltered: false,
    autocompleteEnrollmentFields: false,
    setInsuranceResponse: false,
    generalRules: [],
    particularRules: [],
    loadingDatosDemograficos: false,
    showReempadronamientoButton: false,
    messageError: [],
    userCircles: []
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const EmpadronamientoProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(EmpadronamientoReducer, INITIAL_STATE)

    const getEnrollment = async () => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoadingEnrollment', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}enrollment/users/${userId}?offset=0&limit=300`, {headers})
        .then(response => {
            dispatch({type: 'getEnrollment', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingEnrollment', payload: false});
        })
    }

    const getEnrollmentDetail = async (enrollmentId: number) => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoadingEnrollmentDetail', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}enrollment/user/${userId}/enrollment/${enrollmentId}`, {headers})
        .then(response => {
            dispatch({type: 'getEnrollmentDetail', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingEnrollmentDetail', payload: false});
        })
    }

    const getEnrollmentStatus = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}enrollments/status`, {headers})
        .then(response => {
            dispatch({type: 'getEnrollmentStatus', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getAllEnrollments = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoadingAllEnrollments', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}enrollment`, {headers})
        .then(response => {
            dispatch({type: 'getAllEnrollments', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingAllEnrollments', payload: false});
        })
    }

    const getAllEnrollmentDetail = async (enrollmentId: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoadingAllEnrollments', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}enrollment/detail/${enrollmentId}`, {headers})
        .then(response => {
            dispatch({type: 'getAllEnrollmentDetail', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingAllEnrollments', payload: false});
        })
    }

    const getAllEnrollmentsFilter = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const url = `${process.env.REACT_APP_SITIO_DE_USUARIOS_API}enrollment?name=${state.enrollmentNameFilter}&lastname=${state.enrollmentLastNameFilter}&license=${state.enrollmentlicenseFilter}&status=${state.enrollmentStatusFilter}`

        dispatch({type: 'setLoadingAllEnrollments', payload: true});

        await axios.get(url, {headers})
        .then(response => {
            dispatch({type: 'getAllEnrollmentsFilter', payload: response.data})
            dispatch({type: 'enrollmentFiltered', payload: true})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingAllEnrollments', payload: false});
        })
    }

    const clearEnrollmentFilter = () => {
        dispatch({type: 'clearEnrollmentFilter'})
        // si se filtro llamo al get de empadronamientos para traer todos los resultado nuevamente
        if(state.enrollmentFiltered){
            dispatch({type: 'enrollmentFiltered', payload: false})
            getAllEnrollments()
        }
    }

    const modifyEnrollmentStatus = async (registrationsId: number, status: number, comment?: string) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            "status" : status,
            "comment" : comment,
        }

        dispatch({type: 'setLoading', payload: true});

        await axios.put(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}enrollment/${registrationsId}/status`, data, {headers})
        .then(() => {
            window.location.href = `/empadronamientos/${registrationsId}/${status}`
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
        
    }

    const getProfessionals = async () => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}users/${userId}/professionals/`, {headers})
        .then(response => {
            dispatch({type: 'getProfessionals', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const saveProfessionals = async () => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            "nationalEnrollment" : state.professionalItems.nationalEnrollment,
            "provincialEnrollment" : state.professionalItems.provincialEnrollment,
            "startActivity" : state.professionalItems.startActivity,
            "stateId": 1
        }

        await axios.post(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}users/${userId}/professionals`, data, { headers })
        .then(response => {
            if(response.status === 201){
                dispatch({type: 'viewToShow', payload: 'step1'})
                dispatch({type: "messageError", payload: []});
                dispatch({type: 'setLoading', payload: false})
            }
        })
        .catch(error => {
            if(error.response?.status === 401){
                window.location.href = '/'
            }
            if (error.response.status === 500) {
                dispatch({type: "messageError", payload: ["Error interno del servidor al agregar regla general"]});
                dispatch({ type: "setLoading", payload: false });
            }
            dispatch({type: "messageError", payload: error.response.data?.errors});
            dispatch({type: 'setLoading', payload: false})
        })
    }

    const getCircles = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}circles`, {headers})
        .then(response => {
            dispatch({type: 'getCircles', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const handleChangeSelectCircle = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeSelectCircle', payload: {value: event.target.value}})
    };

    const isLastStep = () => {
        return state.activeStep === 4;
    };
    
    const handlePrevStep = () => {
        const newActiveStep = state.activeStep - 1;
        dispatch({type: 'handleStep', payload: newActiveStep});
    };

    const handleNextStep = () => {
        const newActiveStep = isLastStep() ? state.steps.findIndex((step, i) => !(i in {})) : state.activeStep + 1;
        dispatch({type: 'handleStep', payload: newActiveStep});
    };

    const clearConsultorioValues = () => {
        dispatch({type: 'clearConsultorioValues', payload: {
            officesId: 0,
            street: '',
            height: 0,
            floor: '',
            apartment: '',
            postalCode: '',
            town: '',
            department: '',
            state: '',
            country: '',
            googlePlaceId: '',
            phone: '',
            cellPhone: '',
            hasXRay: '',
            betweenStreet: '',
            observations: '',
            attentionHours: '',
            email: '',
            secondEmail: '',
            availabilityDate: '',
            availability: '',
            officeStateId: 0

        }})
    }

    const removeConsultorio = (idToRemove: number) => {
        dispatch({type: 'removeConsultorio', payload: idToRemove})
    }

    const getInsuranceType = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurances/type`, {headers})
        .then(response => {
            dispatch({type: 'getInsuranceType', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const convertFileToBase64 = (file: FileList | null, name: string, typeDispatch: 'handleChangeFile' | 'handleChangeFileSpeciality') => {
        const fileValue = file ? file : []
        if(fileValue.length){
            Array.from(fileValue).forEach((archivo: any) => {
                const reader = new FileReader();
                reader.readAsDataURL(archivo)
                reader.onload = () => {
                    const base64 = reader.result
                    const base64String = typeof base64 === 'string' ? base64 : ''
                    // hago split para quitar todo lo que esta antes de la coma ya que eso no se necesita
                    let base64Split = []
                    base64Split = base64String.split(',')
                    dispatch({type: typeDispatch, payload: {valueBase64: base64Split[1], value: file, name: name}})
                }
            })
        }
    }

    const handleChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        convertFileToBase64(event.target.files, event.target.name, 'handleChangeFile')
    }

    const handleChangeFileSpeciality = (event: React.ChangeEvent<HTMLInputElement>) => {
        convertFileToBase64(event.target.files, event.target.name, 'handleChangeFileSpeciality')
    }

    const getInsurances = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoadingInsurance', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurance/list`, {headers})
        .then(response => {
            dispatch({type: 'getInsurances', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingInsurance', payload: false});
        })
    }

    const getInsuranceFilter = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const url = `${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurance/list?name=${state.nameFilter}`

        dispatch({type: 'setLoadingInsurance', payload: true});

        await axios.get(url, {headers})
        .then(response => {
            dispatch({type: 'getInsuranceFiltered', payload: response.data})
            dispatch({type: 'filtered', payload: true})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingInsurance', payload: false});
        })
    }

    const clearFilter = () => {
        dispatch({type: 'clearFilter'})
        // si se filtro llamo al get de obras sociales para traer todos los resultado nuevamente
        if(state.filtered){
            dispatch({type: 'filtered', payload: false})
        }
    }

    const handleClickCoverage = (event: React.ChangeEvent<HTMLInputElement>, insuranceId: number, coverageSelected: number) => {
        dispatch({type: 'handleClickCoverage', payload: {status: event.target.checked, insuranceId, coverageSelected}})
    }

    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeFilter', payload: {name: event.target.name, value: event.target.value}})
    };

    const handleChangeSelectFilter = (event: SelectChangeEvent<string>) => {
        dispatch({type: 'handleChangeFilter', payload: {name: event.target.name, value: event.target.value}})
    };

    const getMainSpeciality = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}speciality?limit=20`, {headers})
        .then(response => {
            dispatch({type: 'getMainSpeciality', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const handleChangeSpeciality = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeSpeciality', payload: {value: event.target.value, name: event.target.name}})
    };

    const clearSpecialityValues = () => {
        dispatch({type: 'clearSpecialityValues', payload: {
            specialityId: 0,
            isExclusive: '',
            certifyFile: null,
            certifyFileBase64: '',
            antiquity: 0,
            awardedBy: '',
            awardedYear: 0,
            idFopbaInsider: 0
        }})
    }

    const removeSpeciality = (idToRemove: number) => {
        dispatch({type: 'removeSpeciality', payload: idToRemove})
    }

    const handleChangeSecureCheck = (secureId: number) => {
        dispatch({type: 'handleChangeSecureCheck', payload: secureId})
    };

    const saveEnrollment = async (enrollmentId?: number) => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        // traigo solo las obras sociales que tienen al menos una cobertura seleccionada
        const insurancesChecked = state.insuranceData.filter(item => item.generalCoverages.some(gc => gc.status === true));
        // muestro solo las coberturas que estan seleccionadas
        const insuranceWithCoverageChecked = insurancesChecked.map(item => ({
            ...item,
            generalCoverages: item.generalCoverages.filter(coverage => coverage.status === true)
        }));

        // formateo las obras sociales en el formato esperado para el backend
        const insuranceFormat: any[] = []
        insuranceWithCoverageChecked.forEach(item => {
            insuranceFormat.push({insuranceId: item.insurancesId, generalCoverageIds: item.generalCoverages.map(gc => (gc.generalCoveragesId))})
        })

        // agrego el atributo antiquity a especialidades
        state.specialityLoad.forEach(item => {
            item.antiquity = moment().diff(item.awardedYear, 'year')
            item.certifyFile = item.certifyFileBase64 ? item.certifyFileBase64 : ''
            delete item.certifyFileBase64
        })

        // Formateo datos de consultorios
        state.consultorioCargado.forEach(item => {
            item.requiredFullAddress = false
            item.country = 'Argentina'
            item.hasXRay = item.hasXRay === 'true' ? true : false
            delete item.officesId
        })

        const collectiveDescription = state.insuranceTypeData.find(insurance => insurance.insuranceTypeId === state.secureId)
        const circleSelected = state.userCircles.find(c => c.name === state.circleName)

        const data = {
            "circleId": circleSelected?.circleId ? circleSelected?.circleId : state.circleId,
            "secureId": state.secureId,
            "collective": collectiveDescription?.description,
            "comment": "",
            "registrationFinishDate": new Date(),
            "insurances": insuranceFormat,
            "specialities": state.specialityLoad,
            "offices": state.consultorioCargado,
            "acceptTerm": state.enrollmentDetail.acceptTerms,
            ...(state.enrollmentDetail.secureStartDate && {"secureStartDate": state.enrollmentDetail.secureStartDate}),
            ...(state.enrollmentDetail.secureFinishDate && {"secureFinishDate": state.enrollmentDetail.secureFinishDate}),
            ...(state.insuranceTypeItems.collectiveFileBase64 && {"collectiveFile": state.insuranceTypeItems.collectiveFileBase64}),
        }

        const enrollmentModify = {...data, registrationModifyId: enrollmentId}

        dispatch({type: 'setLoading', payload: true})

        await axios.post(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}enrollment/${userId}`, enrollmentId ? enrollmentModify : data, { headers })
        .then(response => {
            if(response.status === 201){
                dispatch({type: 'setLoading', payload: false})
                dispatch({type: "messageError", payload: []});
                dispatch({type: 'openSummaryModal', payload: true})
            }
        })
        .catch(error => {
            if(error.response?.status === 401){
                window.location.href = '/'
            }
            if (error.response.status === 500) {
                dispatch({type: "messageError", payload: ["Error interno del servidor al agregar regla general"]});
                dispatch({ type: "setLoading", payload: false });
            }
            dispatch({type: "messageError", payload: error.response.data?.errors});
            dispatch({type: 'setLoading', payload: false})
        })
    }

    const getProvincias = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}states`, {headers})
        .then(response => {
            dispatch({type: 'getProvincias', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    
    const getDepartamentos = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}departments?stateId=${state.consultorioItems.state}&offset=0&limit=200`, {headers})
        .then(response => {
            dispatch({type: 'getDepartamentos', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    
    const getLocalidades = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        dispatch({ type: "loadingDatosDemograficos", payload: true });
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}towns?stateId=${state.consultorioItems.state}&departmentId=${state.consultorioItems.department}&offset=0&limit=200`, {headers})
        .then(response => {
            dispatch({type: 'getLocalidades', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
          dispatch({ type: "loadingDatosDemograficos", payload: false });
        })
    }

    const getAllLocalidades = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}towns?statesId=1&offset=0&limit=1000`, {headers})
        .then(response => {
            dispatch({type: 'getAllLocalidades', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const autocompleteEnrollmentFields = (enrollmentsDetail: EnrollmentDetailItems) => {
        dispatch({type: 'autocompleteEnrollmentFields', payload: {
            consultorioCargado: enrollmentsDetail.officesResponse,
            specialityLoad: enrollmentsDetail.specialitiesResponse,
            secureId: enrollmentsDetail.secureId,
            circleId: enrollmentsDetail.circleId
        }})
    }

    const getGeneralRules = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}generalRules`, {headers})
        .then(response => {
            dispatch({type: 'getGeneralRules', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getParticularRules = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}particularRules`, {headers})
        .then(response => {
            dispatch({type: 'getParticularRules', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }
    const rulesController = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const professionalId = localStorage.getItem('professionalId')

        dispatch({type: 'setLoading', payload: true});

        await axios.post(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}rules/validate/${professionalId}`, professionalId, { headers })
        .then(response => {
            if(response.status === 201){
                dispatch({type: 'showReempadronamientoButton', payload: response.data.status})
                dispatch({type: 'setLoading', payload: false})
            }
        })
        .catch(error => {
            if(error.response?.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false})
        })
    }

    const handleChangeUbicacion = (value: any, name: string) => {
        dispatch({type: 'changeUbicacion', payload: {value: value, name: name}})
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInput', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeInputConsultorio = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInputConsultorio', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeDatePicker = (value: string, name: string) => {
        dispatch({type: 'changeDatePicker', payload: {value: value, name: name}})
    }

    const handleChangeYearPicker = (value: string, name: string) => {
        dispatch({type: 'changeYearPicker', payload: {value: value, name: name}})
    }

    const handleOpenConfirm = () => {
        dispatch({type: 'openConfirm', payload: true})
    }

    const handleCloseConfirm = () => {
        dispatch({type: 'openConfirm', payload: false})
    }

    const handleChangeView = (view: string) => {
        dispatch({type: 'viewToShow', payload: view})
    }

    const handleChangeHasXRay = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeHasXRay', payload: event.target.value})
    };

    const closeSummaryModal = () => {
        dispatch({type: 'openSummaryModal', payload: false})
    };

    const handleClickCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: 'handleClickCheck', payload: event.target.checked})
    }

    const setLatAndLng = (latAndLng: string) => {
        dispatch({type: 'setLatAndLng', payload: latAndLng})
    }

    const setInsuranceResponse = (isSet: boolean) => {
        dispatch({type: 'setInsuranceResponse', payload: isSet})
    }

    const getUserCircles = async () => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}usersCircles/${userId}`, {headers})
        .then(response => {
            dispatch({type: 'getUserCircles', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    return(
        <EmpadronamientoContext.Provider value={{
            state,
            getEnrollment,
            getEnrollmentDetail,
            getEnrollmentStatus,
            getAllEnrollments,
            getAllEnrollmentDetail,
            getAllEnrollmentsFilter,
            modifyEnrollmentStatus,
            getProfessionals,
            saveProfessionals,
            getCircles,
            handleChangeSelectCircle,
            handlePrevStep,
            handleNextStep,
            clearConsultorioValues,
            removeConsultorio,
            getInsurances,
            getInsuranceType,
            handleChangeFile,
            handleChangeFileSpeciality,
            getInsuranceFilter,
            clearFilter,
            clearEnrollmentFilter,
            handleClickCoverage,
            handleChangeFilter,
            handleChangeSelectFilter,
            getMainSpeciality,
            handleChangeSpeciality,
            clearSpecialityValues,
            removeSpeciality,
            handleChangeSecureCheck,
            saveEnrollment,
            getProvincias,
            getDepartamentos,
            getLocalidades,
            getAllLocalidades,
            autocompleteEnrollmentFields,
            getGeneralRules,
            getParticularRules,
            rulesController,
            handleChangeUbicacion,
            handleChangeInput,
            handleChangeInputConsultorio,
            handleChangeDatePicker,
            handleChangeYearPicker,
            handleOpenConfirm,
            handleCloseConfirm,
            handleChangeView,
            handleChangeHasXRay,
            closeSummaryModal,
            handleClickCheck,
            setLatAndLng,
            setInsuranceResponse,
            getUserCircles
        }}>
            {children}
        </EmpadronamientoContext.Provider>
    )
}

export default EmpadronamientoProvider