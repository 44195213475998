import { useReducer } from 'react'
import { DentalRecordsState, ProviderState, Providers } from '../../../interfaces/DentalRecords';
import DentalRecordsContext from './DentalRecordsContext';
import DentalRecordsReducer from './DentalRecordsReducer';
import { SelectChangeEvent } from '@mui/material';
import jwt_decode from "jwt-decode";
import axios from 'axios';
import moment from 'moment';
import { tokenDecode } from '../../../interfaces/Login';
import html2canvas from 'html2canvas';

const INITIAL_STATE: DentalRecordsState = {
    data: [],
    loading: false,
    openConfirm: false,
    openAlert: false,
    buttonDisabled: true,
    insuranceId: 0,
    professionalEnrollment: '',
    status: 0,
    loadingInsurance: false,
    filtered: false,
    dataInsurance: [],
    statusList: [],
    dentalDetail: {
        pieces: [],
        faces: []
    },
    circleList: [],
    sexTypesList: [],
    dentalRecordStates: [],
    documentTypesList: [],
    provincias: [],
    departamentos: [],
    localidades: [],
    loadingDatosDemograficos: false,
    items: {
        affiliatedValidated: false,
        createdAt: '',
        patientDetail: {
            affiliateId: 0,
            age: 0,
            insuranceId: 0,
            planId: 0,
            floor: '',
            apartment: '',
            town: '',
            documentTypeId: 0,
            documentValue: 0,
            phone: 0,
            name: '',
            lastname: '',
            postalCode: '',
            sexTypeId: 0,
            street: '',
            streetNumber: '',
            department: '',
            state: '',
            bornDate: '',
            ivaTypeId: 0
        },
        professionalDetail: {
            professionalsId: 0,
            isNationalEnrollment: false,
            enrollmentType: '',
            enrollment: '',
            circleId: 0,
            professionalName: '',
            professionalLastname: ''
        },
        treatments: [],
        treatmentsItems: {
            treatmentsId: 0,
            code: 0,
            faceId: [],
            pieceId: 0,
            description: '',
            faceName:"",
            face:0, 
            pieceNumber:0
        },
        providers: [],
        histories: [],
        observers: '',
        bond: '',
        token: '',
        createDate: moment().format('YYYY-MM-DD'),
        dentalRecordsFiles: [{
            dentalFile: '',
            dentalRecordFileId: 0,
            dentalRecordsId: 0        
        }],
        healthyMouth:false,
        amountPieces:0
    },
    affiliateData: {
        affiliateId: 0,
        state: '',
        department: '',
        plansId: 0,
        name: '',
        lastname: '',
        age: 0,
        bornDate: '',
        apartment: '',
        documentType: 1,
        documentValue: 0,
        sexTypeId: 0,
        floor: '',
        phone: 0,
        postalCode: '',
        street: '',
        streetNumber: '',
        town: '',
        validated: false,
        ivaTypeId: 0
    },
    professionalItems: {
        nationalEnrollment: '',
        provincialEnrollment: '',
        startActivity: '',
        fopbaRegistration: ''
    },
    enrollmentData: [],
    pieceId: 0,
    faceId: 0,
    code: 0,
    providerDetail: [],
    listProviderTypes: [],
    affiliateNotFound: false,
    userCircles: [],
    userCircleSelected: 0,
    loadingModal: false,
    providerState: [],
    enrollmentDetail: {
        circleId: 0,
        collectiveFile: '',
        hasXRay: false, 
        officesResponse: [{
            officesId: 0,
            street: '',
            height: 0,
            floor: '',
            apartment: '',
            postalCode: 0,
            town: '',
            department: '',
            state: '',
            country: '',
            googlePlaceId: '',
            phone: '',
            cellPhone: '',
            hasXRay: ''
        }],
        registrationHistoryResponses: [{status: 0, name: '', lastname: '', comment: '', date: '', registrationStateId: 0}], 
        secureId: 0,
        specialitiesResponse: [{
            specialityId: 0,
            isExclusive: '',
            certifyFile: null,
            certifyFileBase64: '',
            antiquity: 0,
            awardedBy: '',
            awardedYear: 0
        }],
        insurancesResponse: [{insurancesId: 0, name: '', generalCoverages: [], idFopbaInsider: 0}],
        name: '',
        lastName: '',
        registrationStateId: 0,
        provincialEnrollment: '',
        acceptTerms: false,
        secureFinishDate: '',
        secureStartDate:  '',
        userFile: ''
    },
    loadingEnrollmentDetail: false,
    plans: [],
    postalCodeSelected: '',
    affiliateFilter: '',
    ivaTypesData: [{
        ivaTypeId: 0,
        description: ''
    }],
    fileSaved: [],            
    providerByAffiliateCode: [],
    loadingAffiliateCode:true
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const DentalRecordsProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(DentalRecordsReducer, INITIAL_STATE)

    const getDentalRecords = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}dentalRecords?offset=0&limit=500`, {headers})
        .then(response => {
            dispatch({type: 'getDentalRecords', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getDentalRecordsFilter = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        let params = []
        if(state.insuranceId){
            params.push(`&insuranceId=${state.insuranceId}`)
        }
        if(state.professionalEnrollment){
            params.push(`&professionalEnrollment=${state.professionalEnrollment}`)
        }
        if(state.status){
            params.push(`&status=${state.status}`)
        }
        if(state.affiliateFilter){
            params.push(`&affiliateCode=${state.affiliateFilter}`)
        }

        let paramsFormat = params[0]
        if(params.length === 2){
            paramsFormat = params[0] + params[1]
        } else if(params.length === 3){
            paramsFormat = params[0] + params[1] + params[2]
        } else if(params.length === 4){
            paramsFormat = params[0] + params[1] + params[2] + params[3]
        }

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}dentalRecords?${paramsFormat}`, {headers})
        .then(response => {
            dispatch({type: 'getDentalRecords', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
            dispatch({type: 'filtered', payload: true})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getDentalRecordsById = async (id: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}dentalRecords/${id}`, {headers})
        .then(response => {
            dispatch({type: 'getDentalRecordsById', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getDentalDetail = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}dental/detail`, {headers})
        .then(response => {
            const data=  response?.data?.pieces?.map((piece:any) =>{ 
            return piece;
            })
            dispatch({type: 'getDentalDetail', payload: {faces:response?.data?.faces,pieces: data  }});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getDentalRecordStates = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}dentalRecordStates`, {headers})
        .then(response => {
            dispatch({type: 'getDentalRecordStates', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const modifyDentalRecordStatus = async (dentalRecordId: number, status: number, comment?: string) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            "status" : status,
            "comment" : comment,
        }

        dispatch({type: 'setLoading', payload: true});

        await axios.put(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}dentalRecords/${dentalRecordId}/state`, data, {headers})
        .then(() => {
            window.location.href = `/ficha/${dentalRecordId}/${status}`
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
        
    }

    const modifyDentalRecord = async (dentalRecordId: number, status: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
        let treatments;

        if(Array.isArray(state?.items?.treatmentsItems?.faceId)){
           treatments = state?.items?.treatmentsItems?.faceId?.map((face) =>({
                "pieceId": state.items.treatmentsItems.pieceId,
                "faceId": face,
                "code": state.items.treatmentsItems.code,
                "pieceNumber":state.items.treatmentsItems.pieceNumber,
                        codeId:state.items.treatmentsItems.code,
            }))
        }else{
            treatments = [{
                "pieceId": state.items.treatmentsItems.pieceId,
                "faceId": state.items.treatmentsItems.faceId,
                "code": state.items.treatmentsItems.code,
                "pieceNumber":state.items.treatmentsItems.pieceNumber,
                codeId: state.items.treatmentsItems.code,
            }]
        }
        if(state.items.treatments.length){
           treatments = state?.items?.treatments?.map((treatment) => ({
                code: treatment.code,
                faceId: treatment.face ? treatment.face : !Array.isArray(treatment.faceId) ? treatment.faceId : undefined,
                pieceId:treatment.pieceId,
                pieceNumber:treatment.pieceNumber,
                codeId: treatment.code,
            }));
        }
        const providerDetail = state?.providerDetail?.map((detail) => {
            if(detail?.faces){
            return {...detail , providerTypeId:detail.providerType, faces: Object.keys(detail?.faces).map((key) => detail?.faces[key]) }
            }
            return {...detail, providerTypeId:detail.providerType};
        })
        const data = {
            "professional" : {
                "enrollmentType": state.items.professionalDetail.enrollmentType,
                "enrollment": state.items.professionalDetail.enrollment,
                "professionalsId": state.items.professionalDetail.professionalsId, 
                "isNationalEnrollment":  state.items.professionalDetail.isNationalEnrollment,
                "circleId":  state.items.professionalDetail.circleId,
                "professionalName":  state.items.professionalDetail.professionalName,
                "professionalLastname": state.items.professionalDetail.professionalName
            },
            "patient": {
                "name": state.affiliateData.name,
                "lastname": state.affiliateData.lastname,
                "sexTypeId": state.affiliateData.sexTypeId,
                "documentTypeId": state.affiliateData.documentType,
                "documentValue": state.affiliateData.documentValue,
                "phone": state.affiliateData.phone,
                "street": state.affiliateData.street,
                "streetNumber": state.affiliateData.streetNumber,
                "floor": state.affiliateData.floor ? state.affiliateData.floor : '',
                "apartment": state.affiliateData.apartment ? state.affiliateData.apartment : '',
                "postalCode": state.affiliateData.postalCode,
                "town": state.affiliateData.town,
                "state": state.affiliateData.state,
                "department": state.affiliateData.department,
                "bornDate": state.affiliateData.bornDate,
                "ivaTypeId": state.affiliateData.ivaTypeId
            },
            "treatments": treatments,
            "providers": providerDetail,
            "observer": state.items.observers,
            "createDate": state.items.createDate,
            "circleId": state.userCircleSelected,
            "bond": state.items.bond,
            "token": state.items.token,
            "healthyMouth": providerDetail.length == 0 ? true : false,
            "amountPieces": state.items.amountPieces,
            "comment": ' '
        }
        dispatch({type: 'setLoading', payload: true});
        await axios.put(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}dentalRecords/${dentalRecordId}`, data, {headers})
        .then(() => {
            window.location.href = '/fichas'
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
        
    }

    const getInsurances = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'loadingInsurance', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurance/list`, {headers})
        .then(response => {
            dispatch({type: 'getInsurances', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'loadingInsurance', payload: false});
        })
    }

    const getInsuranceStatus = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurance/status`, {headers})
        .then(response => {
            dispatch({type: 'getInsuranceStatus', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getCirles = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}circles`, {headers})
        .then(response => {
            dispatch({type: 'getCirles', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getSexTypes = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}sexType/`, {headers})
        .then(response => {
            dispatch({type: 'getSexTypes', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getDocumentTypes = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}documentTypes`, {headers})
        .then(response => {
            dispatch({type: 'getDocumentTypes', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getProvincias = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}states`, {headers})
        .then(response => {
            dispatch({type: 'getProvincias', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    
    const getDepartamentos = async (state: string) => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}departments?stateId=${state}&offset=0&limit=200`, {headers})
        .then(response => {
            dispatch({type: 'getDepartamentos', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    
    const getLocalidades = async (state: string, department: string) => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        dispatch({ type: "loadingDatosDemograficos", payload: true });
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}towns?stateId=${state}&departmentId=${department}&offset=0&limit=200`, {headers})
        .then(response => {
            dispatch({type: 'getLocalidades', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
          dispatch({ type: "loadingDatosDemograficos", payload: false });
        })
    }

    const getAffiliates = async (code: string, insurancesIdFopbaInsider: number, insuranceId: number) => {

        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        dispatch({ type: "setLoading", payload: true });
        
        const bond = state.items.bond
        const token = state.items.token
        const createDate = state.items.createDate

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}affiliate/${code}/insurances/${insurancesIdFopbaInsider}`, {headers})
        .then(response => {
            dispatch({type: 'getAffiliates', payload: response.data})
            if(response.status === 200 && window.location.pathname === '/fichas'){
                window.location.href = `/agregar-ficha/${code}/${insurancesIdFopbaInsider}/${insuranceId}?bond=${bond}&token=${token}&createDate=${createDate}`
            } else if(response.status === 204){
                dispatch({type: 'affiliateNotFound', payload: true})
                dispatch({ type: "setLoading", payload: false });
            }
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            } else if (error.response.status === 400){
                dispatch({type: 'affiliateNotFound', payload: true})
                dispatch({ type: "setLoading", payload: false });
            } else {
                dispatch({type: 'affiliateNotFound', payload: false})
                dispatch({ type: "setLoading", payload: false });
            }
        })
    }

    const getProfessionals = async () => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}users/${userId}/professionals/`, {headers})
        .then(response => {
            dispatch({type: 'getProfessionals', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getEnrollment = async () => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoadingModal', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}enrollment/users/${userId}?offset=0&limit=300`, {headers})
        .then(response => {
            dispatch({type: 'getEnrollment', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingModal', payload: false});
        })
    }

    const getProviderTypes = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}providerTypes`, {headers})
        .then(response => {
            dispatch({type: 'getProviderTypes', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const saveDentalRecords = async (insuranceId: number) => {

        const token = localStorage.getItem('token')
        const decoded: tokenDecode = jwt_decode(token ? token : '');

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
        let treatments;
        if(Array.isArray(state?.items?.treatmentsItems?.faceId)){
           treatments = state?.items?.treatmentsItems?.faceId?.map((face) =>({
                "pieceId": state.items.treatmentsItems.pieceId,
                "faceId": face,
                "code": state.items.treatmentsItems.code,
                "pieceNumber":state.items.treatmentsItems.pieceNumber,
                        codeId:state.items.treatmentsItems.code,
            }))
        }else{
            treatments = [{
                "pieceId": state.items.treatmentsItems.pieceId,
                "faceId": state.items.treatmentsItems.faceId,
                "code": state.items.treatmentsItems.code,
                "pieceNumber":state.items.treatmentsItems.pieceNumber,
                codeId: state.items.treatmentsItems.code,
            }]
        }
        if(state.items.treatments.length){
           treatments = state?.items?.treatments?.map((treatment) => ({
                code: treatment.code,
                faceId: treatment.face ? treatment.face : !Array.isArray(treatment.faceId) ? treatment.faceId : undefined,
                pieceId:treatment.pieceId,
                pieceNumber:treatment.pieceNumber,
                codeId: treatment.code,
            }));
        }
        const providerDetail = state?.providerDetail?.map((detail) => {
            if(detail?.faces){
            return {...detail , providerTypeId:detail.providerType, faces: Object.keys(detail?.faces).map((key) => detail?.faces[key]) }
            }
            return {...detail, providerTypeId:detail.providerType};
        })

            // const div:any = document.getElementById("capture");
            // const div:any = document.getElementById("only-odonto");
            // if(div){
            //     html2canvas(div).then((canvas) => {
            //     const base64String = canvas.toDataURL("image/png");
            //     console.log(base64String);
            //     const link = document.createElement("a");
            //     link.href = canvas.toDataURL("image/png");
            //     link.download = "capture.png";
            //     link.click();
            //     });
            // }

        dispatch({type: 'setLoading', payload: true})
        const data = {
            "affiliatedValidated": true,
            "observers": state.items.observers,
            "historyComment": " ",
            "rxDiagnosis": "-",
            "relationship": "",
            "professionalDetail": {
                "professionalsId": decoded.professionalId,
                "isNationalEnrollment": state.items.professionalDetail.isNationalEnrollment,
                "enrollment": state.items.professionalDetail.enrollment,
                "professionalName": state.items.professionalDetail.professionalName,
                "professionalLastname": state.items.professionalDetail.professionalLastname,
                "circleId": state.userCircleSelected
            },
            "patientDetail": {
                "insuranceId": insuranceId,
                "planId": 245,
                "affiliateId": Number(state.affiliateData.affiliateId),
                "name": state.affiliateData.name,
                "lastname": state.affiliateData.lastname,
                "sexTypeId": state.affiliateData.sexTypeId,
                "documentTypeId": state.affiliateData.documentType,
                "documentValue": state.affiliateData.documentValue,
                "phone": state.affiliateData.phone,
                "street": state.affiliateData.street,
                "streetNumber": state.affiliateData.streetNumber,
                "floor": state.affiliateData.floor ? state.affiliateData.floor : "",
                "apartment": state.affiliateData.apartment ? state.affiliateData.apartment : "",
                "postalCode": state.affiliateData.postalCode,
                "town": state.affiliateData.town,
                "department": state.affiliateData.department,
                "state": state.affiliateData.state,
                "age": moment().diff(state.affiliateData.bornDate, 'years'),
                "bornDate": state.affiliateData.bornDate,
                "ivaTypeId": state.affiliateData.ivaTypeId
            },
            "createDate": state.items.createDate,
            "dentalRecordFile": state.items.dentalRecordsFiles,
            "treatments": treatments,
            "providerDetail": providerDetail,
            "circleId": state.userCircleSelected,
            "bond": state.items.bond,
            "token": state.items.token,
            "healthyMouth": providerDetail.length == 0 ? true : false,
            "amountPieces": state.items.amountPieces
        }
        await axios.post(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}dentalRecords`, data, { headers })
        .then(response => {
            if(response.status === 201){
                window.location.href = '/fichas'
            }
        })
        .catch(error => {
            if(error.response?.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false})
        })
    }

    const clearFilter = () => {
        dispatch({type: 'clearFilter'})
        // si se filtro llamo al get de fichas para traer todos los resultados nuevamente
        if(state.filtered){
            getDentalRecords()
            dispatch({type: 'filtered', payload: false})
        }
    }

    const pushTreatmentsTable = () => {
        dispatch({type: 'pushTreatmentsTable', payload: {
            treatmentsId: 0,
            code: 0,
            faceId: [],
            pieceId: 0,
            description: '',
            faceName:"",
            face:0,
            pieceNumber:0
        }})
    }

    const removeTreatments = (idToRemove: number) => {
        dispatch({type: 'removeTreatments', payload: idToRemove})
    }

    const pushProviders = (pieceId: number, providerType: number, description: string, faces?:any, required?: boolean, providersId?:number) => {
        dispatch({type: 'pushProviders',  payload:{
            pieceId,
            providerType,
            description,
            faces,
            required,
            providersId
        }})
    }
    const cleanProviders = () => {
        dispatch({type: 'cleanProviders',  payload:[]})
    }

    const setProviders = (value: ProviderState[]) => {
        dispatch({type: 'setProviders', payload: value})
    }
    
    const getUserCircles = async () => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoadingModal', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}usersCircles/${userId}`, {headers})
        .then(response => {
            dispatch({type: 'getUserCircles', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingModal', payload: false});
        })
    }

    const getEnrollmentDetail = async (enrollmentId: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoadingEnrollmentDetail', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}enrollment/detail/${enrollmentId}`, {headers})
        .then(response => {
            dispatch({type: 'getEnrollmentDetail', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoadingEnrollmentDetail', payload: false});
        })
    }

    const handleChangeUbicacion = (value: any, name: string) => {
        dispatch({type: 'changeUbicacion', payload: {value: value, name: name}})
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInput', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeInputAdd = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInputAdd', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeSelect = (event: SelectChangeEvent<string | number | number[] | string[]> ) => {
        dispatch({type: 'handleChangeSelect', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeDatePicker = (value: string, name: string) => {
        dispatch({type: 'changeDatePicker', payload: {value: value, name: name}})
    }

    const handleOpenConfirm = () => {
        dispatch({type: 'openConfirm', payload: true})
    }

    const handleCloseConfirm = () => {
        dispatch({type: 'openConfirm', payload: false})
    }

    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeFilter', payload: {name: event.target.name, value: event.target.value}})
    };

    const openAlert = () => {
        dispatch({type: 'openAlert', payload: true})
    };

    const closeAlert = () => {
        dispatch({type: 'openAlert', payload: false})
    };

    const closeAlertAffiliateNotFound = () => {
        dispatch({type: 'affiliateNotFound', payload: false})
    };

    const handleClickCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: 'handleClickCheck', payload: event.target.checked})
    }

    const handleClickCheckHealthyMouth = (event: React.ChangeEvent<HTMLInputElement> )  => {
   
        dispatch({type: 'handleClickCheckHealthyMouth', payload: event.target.checked})
        if(event.target.checked){
        dispatch({type: 'customGetDentalRecordsById', payload:[]})
        dispatch({type: 'cleanProviders', payload: []})
        }
    }
    const getPlanCodes = async (insuranceId: number) => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurance/${insuranceId}/plans`, {headers})
        .then(response => {
            dispatch({type: 'getPlanCodes', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getIvaTypes = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}ivaType`, {headers})
        .then(response => {
            dispatch({type: 'getIvaTypes', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

        const getProviderByAffiliateCode = async (code: string) => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
        dispatch({ type: "setLoadingAffiliateCode", payload: true });
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}dentalRecords?affiliateCode=${code}`, {headers})
        .then(response => {
            dispatch({type: 'getProviderByAffiliateCode', payload: response.data})
        })
       .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        }).finally(() =>dispatch({ type: "setLoadingAffiliateCode", payload: false }))
    }
     const customGetDentalRecordsById = async (id: number) => {
 
        if(id < 0 ){
           return dispatch({type: 'customGetDentalRecordsById', payload: []})
        }
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}dentalRecords/${id}`, {headers})
        .then(response => {
            dispatch({type: 'customGetDentalRecordsById', payload: response?.data?.providers})
            dispatch({type: 'handleClickCheckHealthyMouth', payload:response?.data?.providers?.length == 0})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        }).finally(() =>dispatch({ type: "setLoading", payload: false }))
       }
    const changeStateLoadProvider = (value:boolean) => {
        dispatch({type: 'changeStateLoadProvider', payload: value})
    }
    return(
        <DentalRecordsContext.Provider value={{
            state,
            getDentalRecords,
            getDentalRecordsFilter,
            getDentalRecordsById,
            getDentalDetail,
            getDentalRecordStates,
            modifyDentalRecordStatus,
            modifyDentalRecord,
            getInsurances,
            getInsuranceStatus,
            getCirles,
            getSexTypes,
            getDocumentTypes,
            getProvincias,
            getDepartamentos,
            getLocalidades,
            getAffiliates,
            getProfessionals,
            getEnrollment,
            getProviderTypes,
            saveDentalRecords,
            getUserCircles,
            getEnrollmentDetail,
            handleChangeUbicacion,
            handleChangeInput,
            handleChangeInputAdd,
            handleChangeSelect,
            handleChangeDatePicker,
            handleOpenConfirm,
            handleCloseConfirm,
            handleChangeFilter,
            clearFilter,
            pushTreatmentsTable,
            removeTreatments,
            pushProviders,
            closeAlert,
            closeAlertAffiliateNotFound,
            handleClickCheck,
            setProviders,
            getPlanCodes,
            getIvaTypes,
            handleClickCheckHealthyMouth,
            getProviderByAffiliateCode,
            customGetDentalRecordsById,
            cleanProviders,
            changeStateLoadProvider
        }}>
            {children}
        </DentalRecordsContext.Provider>
    )
}

export default DentalRecordsProvider