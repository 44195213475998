import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Group34 from '../../img/Group34.png'

const DialogStyled = styled(Dialog)(() => ({
    '.MuiPaper-root':{
        backgroundColor: '#F6F9FF',
        borderRadius: 30,
        padding: 45
    }
}));

const BoxStyled = styled(Box)(() => ({
    textAlign: 'center',
}));

const DialogTitleStyled = styled(DialogTitle)(() => ({
    textAlign: 'center',
    fontWeight: 'bold'
}));

const DialogContentTextStyled = styled(DialogContentText)(() => ({
    textAlign: 'center',
    color: '#64AAB2'
}));

const IconButtonStyled = styled(IconButton)(({theme}) => ({
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500],
}));

interface Props {
    open: boolean,
    handleCloseInvalidIdentity: () => void
}

export default function InvalidIdentity({ open, handleCloseInvalidIdentity }:Props) {

    return (
        <DialogStyled
            open={open}
        >
            <DialogContent>
                <BoxStyled>
                    <img src={Group34} alt='Group34' width='80' />
                </BoxStyled>
                <DialogTitleStyled>
                    No pudimos validar tu identidad en el sitio
                </DialogTitleStyled>
                <DialogContentTextStyled>
                    Te pedimos que te comuniques con mesa de ayuda para que podamos hacer un blanqueo de tu constraseña
                </DialogContentTextStyled>
            </DialogContent>
            <IconButtonStyled onClick={handleCloseInvalidIdentity}>
                <CloseIcon />
            </IconButtonStyled>
        </DialogStyled>
    );
}