import { useContext, useEffect } from "react"
import Container from '../Container'
import { Box, Divider, Grid } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { getGeocode, getLatLng } from "use-places-autocomplete";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { useLoadScript } from "@react-google-maps/api";
import { styled } from '@mui/material/styles';
import OfficesContext from "../context/Offices/OfficesContext"
import Loader from "../Loader";
import Button from "../Button";
import Checkbox from '@mui/material/Checkbox';
import { Link, useParams } from "react-router-dom";
import Alert from "../Alert";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

const TextFieldStyled = styled(TextField)(() => ({
    '.MuiInputLabel-root': {
        'span': {
            color: 'red'
        }
    }
}));

const LibrarieIsLoaded = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ? process.env.REACT_APP_GOOGLE_API_KEY : '',
        libraries: ["places"],
    });
    return isLoaded
}

const Detail = () => {

    const { state, getOfficesById, closeAlert, updateOffices, handleChangeInputConsultorio, handleChangeHasXRay, handleClickCheck, handleChangeUbicacion, getProvincias, getDepartamentos, getLocalidades, setLatAndLng, handleChangeDatePicker } = useContext(OfficesContext)

    const formatAddress = async () => {
        const address = `${state.officesItems.street} ${state.officesItems.height}, ${state.officesItems.town}, ${state.officesItems.state}, Argentina`
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        // seteo latitud y longitud separado por coma en el campo googlePlaceId
        const latAndLng = lat + ',' + lng
        setLatAndLng(latAndLng)
    };

    const {id} = useParams()

    useEffect(() => {
        getOfficesById(Number(id))
        closeAlert()
    }, [])
    
    useEffect(() => {
        // si se seleccionaron los campos obligatorios para obtener la latitud y longitud formateo la direccion
        if(state.officesItems.street && state.officesItems.town && state.officesItems.height && state.officesItems.town && state.officesItems.state){
            formatAddress()
        }
    }, [state.officesItems.street, state.officesItems.town, state.officesItems.height, state.officesItems.town, state.officesItems.state])

    useEffect(() => {
        if(!state.officesItems.state){
            getProvincias();
        } else if(state.officesItems.state && !state.loadingDatosDemograficos){
            getDepartamentos();
            getLocalidades();
        }
    }, [state.officesItems.state, state.officesItems.department])
    

    // recorro array de localidades y me fijo si hay algun nombre repetido y lo saco del array
    let arrayLocalidad: any[] = [];
    if (state.localidades.length) {
        state.localidades.map((l) => {
            if (!arrayLocalidad.some(item => item.name === l.name.charAt(0) + l.name.slice(1).toLowerCase())) {
                arrayLocalidad.push({name: l.name.charAt(0) + l.name.slice(1).toLowerCase(), townsId: l.townsId});
            }
        });
    }
    const localidadesFormat = arrayLocalidad;

    const onSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        updateOffices(Number(id), false)
    }

    const onDelete = () => {
        updateOffices(Number(id), true)
    }

    if((process.env.NODE_ENV !== 'production' && !LibrarieIsLoaded()) || (process.env.NODE_ENV === 'production' && !LibrarieIsLoaded()) || state.loading){
        return <Loader />
    }

    const provinciaSelected = state.provincias.find(p => p.statesId === Number(state.officesItems.state))
    const departamentSelected = state.departamentos.find(d => d.departmentsId === Number(state.officesItems.department))
    const localidadSelected = state.localidades.find(l => l.townsId  === Number(state.officesItems.town))

    if(Number(localidadSelected?.postalCode) !== 0){
        state.officesItems.postalCode = localidadSelected?.postalCode ? localidadSelected.postalCode : state.officesItems.postalCode
    }

    //deshabilito los campos si el estado del consultorio es Eliminado
    const fieldDisabled = state.officesItems.officeStateId === 3

    return (
        <Container
            child={
                <Box>
                    <h4>Modificar Consultorio</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                    <h4>Datos Demográficos</h4>

                    <Box 
                        component='form' 
                        onSubmit={onSubmit}
                        className='formContainer'
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} md={6}>
                                <TextFieldStyled 
                                    fullWidth 
                                    label='Calle' 
                                    onChange={handleChangeInputConsultorio}
                                    name="street"
                                    value={state.officesItems.street}
                                    required
                                    disabled={fieldDisabled}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3}>
                                <TextFieldStyled
                                    label="Número"
                                    name='height'
                                    variant="outlined"
                                    required
                                    fullWidth
                                    value={state.officesItems.height ? state.officesItems.height : ''}
                                    onChange={handleChangeInputConsultorio}
                                    disabled={fieldDisabled}
                                />
                                <FormControlLabel control={<Checkbox onChange={handleClickCheck} checked={state.officesItems.height === 'S/N'} />} label="Sin Número" disabled={fieldDisabled}/>
                            </Grid>
                            <Grid item xs={6} sm={6} md={3}>
                                <TextFieldStyled 
                                    fullWidth 
                                    label='Entre Calles' 
                                    onChange={handleChangeInputConsultorio}
                                    name="betweenStreet"
                                    value={state.officesItems.betweenStreet}
                                    disabled={fieldDisabled}
                                    inputProps={{maxLength: '50'}}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={2}>
                                <TextFieldStyled
                                    label="Piso"
                                    name='floor'
                                    variant="outlined"
                                    inputProps={{maxLength: "2"}}
                                    fullWidth
                                    value={state.officesItems.floor ? state.officesItems.floor : ''}
                                    onChange={handleChangeInputConsultorio}
                                    disabled={fieldDisabled}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={2}>
                                <TextFieldStyled
                                    label="Depto"
                                    name='apartment'
                                    variant="outlined"
                                    fullWidth
                                    value={state.officesItems.apartment ? state.officesItems.apartment : ''}
                                    onChange={handleChangeInputConsultorio}
                                    disabled={fieldDisabled}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={2}>
                                <TextFieldStyled
                                    label="Código Postal"
                                    name='postalCode'
                                    required
                                    variant="outlined"
                                    fullWidth
                                    value={state.officesItems.postalCode ? state.officesItems.postalCode : ''}
                                    onChange={handleChangeInputConsultorio}
                                    disabled={fieldDisabled}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disabled={fieldDisabled}
                                        value={{
                                            statesId: provinciaSelected?.statesId ? provinciaSelected.statesId : 0,
                                            name: provinciaSelected?.name ? provinciaSelected.name : ''
                                        }}
                                        onChange={(event, newValue) =>
                                            handleChangeUbicacion(newValue?.statesId ? newValue.statesId : 0, "state")
                                        }
                                        getOptionLabel={(option) => {
                                            return option.name;
                                        }}
                                        options={state.provincias}
                                        renderInput={(params) => (
                                            <TextFieldStyled
                                                {...params}
                                                key={params.id}
                                                required
                                                label="Provincia"
                                                fullWidth
                                                disabled={fieldDisabled}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth>
                                    <Autocomplete
                                        disabled={fieldDisabled}
                                        value={{
                                            departmentsId: departamentSelected?.departmentsId ? departamentSelected.departmentsId : 0,
                                            name: departamentSelected?.name ? departamentSelected.name : ''
                                        }}
                                        onChange={(event, newValue) =>
                                            handleChangeUbicacion(newValue?.departmentsId ? newValue.departmentsId : 0, "department")
                                        }
                                        getOptionLabel={(option) => {
                                            return option.name;
                                        }}
                                        options={state.departamentos}
                                        renderInput={(params) => (
                                            <TextFieldStyled
                                                {...params}
                                                key={params.id}
                                                required
                                                label="Partido"
                                                disabled={fieldDisabled}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <FormControl fullWidth >
                                    <Autocomplete
                                        disabled={fieldDisabled}
                                        value={{
                                            townsId: localidadSelected?.townsId ? localidadSelected.townsId : 0,
                                            name: localidadSelected?.name ? localidadSelected.name.charAt(0) + localidadSelected.name.slice(1).toLowerCase() : ''
                                        }}
                                        onOpen={!state.localidades.length ? getLocalidades : () => {}}
                                        onChange={(event, newValue) =>
                                            handleChangeUbicacion(newValue?.townsId ? newValue.townsId : 0, "town")
                                        }
                                        getOptionLabel={(option) => {
                                            return option.name;
                                        }}
                                        options={localidadesFormat}
                                        renderInput={(params) => (
                                            <TextFieldStyled
                                                {...params}
                                                key={params.id}
                                                required
                                                label="Localidad"
                                                disabled={fieldDisabled}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <h4 style={{textAlign: 'left', width: '100%'}}>Datos de Contacto</h4>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} md={3}>
                                <TextFieldStyled
                                    label="Teléfono / Celular"
                                    name='phone'
                                    variant="outlined"
                                    required
                                    fullWidth
                                    value={state.officesItems.phone ? state.officesItems.phone : ''}
                                    onChange={handleChangeInputConsultorio}
                                    disabled={fieldDisabled}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3}>
                                <TextFieldStyled
                                    label="Teléfono / Celular"
                                    name='cellPhone'
                                    variant="outlined"
                                    fullWidth
                                    value={state.officesItems.cellPhone ? state.officesItems.cellPhone : ''}
                                    onChange={handleChangeInputConsultorio}
                                    disabled={fieldDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextFieldStyled
                                    label="Email"
                                    name='email'
                                    type="email"
                                    variant="outlined"
                                    fullWidth
                                    required
                                    value={state.officesItems.email ? state.officesItems.email : ''}
                                    onChange={handleChangeInputConsultorio}
                                    disabled={fieldDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={3}>
                                <TextFieldStyled
                                    label="Email Opcional"
                                    name='secondEmail'
                                    type="email" 
                                    variant="outlined"
                                    fullWidth
                                    value={state.officesItems.secondEmail ? state.officesItems.secondEmail : ''}
                                    onChange={handleChangeInputConsultorio}
                                    disabled={fieldDisabled}
                                />
                            </Grid>
                        </Grid>
                        <h4 style={{textAlign: 'left', width: '100%'}}>Detalles de Consultorio</h4>
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} md={4}>
                                <TextFieldStyled
                                    fullWidth
                                    select
                                    label="¿Equipo de Rayos X?"
                                    value={state.officesItems.hasXRay ? state.officesItems.hasXRay : ''}
                                    onChange={handleChangeHasXRay}
                                    name='hasXRay'
                                    disabled={fieldDisabled}
                                >
                                    <MenuItem value='true'>Si</MenuItem>
                                    <MenuItem value='false'>No</MenuItem>
                                </TextFieldStyled>
                            </Grid>
                            <Grid item xs={6} sm={6} md={4}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Fecha de Habilitación"
                                    disabled={fieldDisabled}
                                    value={state.officesItems.availabilityDate ? state.officesItems.availabilityDate : null}
                                    onChange={(newValue) =>
                                        handleChangeDatePicker(
                                        moment(newValue).format("YYYY-MM-DD"),
                                        "availabilityDate"
                                        )
                                    }
                                    renderInput={(params) => (
                                        <TextFieldStyled
                                            {...params}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            disabled={fieldDisabled}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <TextFieldStyled
                                    fullWidth
                                    label="Horarios de Atención"
                                    value={state.officesItems.attentionHours}
                                    onChange={handleChangeInputConsultorio}
                                    name='attentionHours'
                                    multiline
                                    rows={2}
                                    disabled={fieldDisabled}
                                    inputProps={{maxLength: '150'}}
                                >
                                </TextFieldStyled>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextFieldStyled
                                    fullWidth
                                    label="Observaciones"
                                    value={state.officesItems.observations}
                                    onChange={handleChangeInputConsultorio}
                                    name='observations'
                                    multiline
                                    rows={3}
                                    inputProps={{maxLength: "250"}}
                                    disabled={fieldDisabled}
                                >
                                </TextFieldStyled>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} justifyContent='space-between' mt={4}>
                            <Grid item xs={4} sm={4} md={2}>
                                <Button 
                                    child='Eliminar'
                                    variant='outlined' 
                                    color='error'
                                    type="button"
                                    handleClick={onDelete}
                                    disabled={fieldDisabled}
                                />
                            </Grid>
                            <Grid item container justifyContent='end' xs={8} sm={8} md={10}>
                                <Grid item xs={6} sm={5} md={3}>
                                    <Button 
                                        child={
                                            <Link style={{textDecoration: 'none', color: '#4e4e4e', width: '100%'}} to={'/consultorios'}>
                                                Volver
                                            </Link>
                                        } 
                                        variant='outlined' 
                                        color='secondary'
                                    />
                                </Grid>
                                <Grid item xs={6} sm={5} md={3}>
                                    <Button 
                                        child='Guardar'
                                        variant='outlined' 
                                        color='secondary'
                                        type="submit"
                                        disabled={fieldDisabled}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>

                    <Alert
                        openAlert={state.openAlert}
                        handleClose={closeAlert}
                        severity="success"
                        text={"Consultorio modificado correctamente"}
                    />
                </Box>
            }
        />
    )
}

export default Detail