import Box from '@mui/material/Box';
import { keyframes } from '@emotion/react'

const load = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`

const Loader = () => {
    return (
        <Box sx={{
            margin: '250px auto',
            border: '4px solid rgba(0,0,0,0.1)',
            width: 50,
            height: 50,
            borderRadius: '50%',
            borderLeftColor: '#64AAB2',
            animation: `${load} 1s linear infinite`
        }}></Box>
    )
}

export default Loader