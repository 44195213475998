import { LoginState, securityQuestions } from '../../../interfaces/Login';

type Action = {type: 'handleChange', payload: {name: string, value: string}}
    | {type: 'loginError', payload: boolean}
    | {type: 'wrongUser', payload: boolean}
    | {type: 'cargando', payload: boolean}
    | {type: 'firstAccess', payload: boolean}
    | {type: 'passwordsDontMatch', payload: boolean}
    | {type: 'getSecurityQuestions', payload: securityQuestions[]}
    | {type: 'getSecurityQuestionsByUser', payload: securityQuestions[]}
    | {type: 'handleChangeAnswerField', payload: {value: string, name: string}}
    | {type: 'handleChangeSelect', payload: {value: string, name: string}}
    | {type: 'questionSelectedId', payload: {id: number}}
    | {type: 'setIdSelected', payload: {id: number}}
    | {type: 'userBlocked', payload: boolean}
    | {type: 'userNotEnabled', payload: boolean}
    | {type: 'handleChangeAnswers', payload: {value: string, name: string}}
    | {type: 'clickRecoverPassword', payload: boolean}
    | {type: 'openInvalidIdentity'}
    | {type: 'handleCloseInvalidIdentity'}

export default function LoginReducer (state: LoginState, action: Action): LoginState {
    switch (action.type) {
        case 'handleChange':
            return { 
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'loginError':
            return { 
                ...state,
                error: action.payload
            }
        case 'wrongUser':
            return { 
                ...state,
                wrongUser: action.payload
            }
        case 'cargando':
            return { 
                ...state,
                cargando: action.payload
            }
        case 'firstAccess':
            return { 
                ...state,
                firstAccess: action.payload
            }
        case 'passwordsDontMatch':
            return { 
                ...state,
                passwordsDontMatch: action.payload
            }
        case 'handleChangeAnswerField':
            state.answerAndQuestions[action.payload.name] = action.payload.value
            return { 
                ...state,
                answerAndQuestions: state.answerAndQuestions
            }
        case 'handleChangeSelect':
            state.answerAndQuestions[action.payload.name] = action.payload.value
            return { 
                ...state,
                answerAndQuestions: state.answerAndQuestions
            }
        case 'getSecurityQuestions':
            return { 
                ...state,
                listQuestions: action.payload
            }
        case 'getSecurityQuestionsByUser':
            return { 
                ...state,
                listQuestionsByUser: action.payload,
                cargando: false
            }
        case 'questionSelectedId':
            const unselectedQuestions = state.listQuestions.filter((list) => list.securityQuestionsId !== action.payload.id)
            return { 
                ...state,
                listQuestions: unselectedQuestions
            }
        case 'setIdSelected':
            return { 
                ...state,
                idSelected: action.payload.id
            }
        case 'userBlocked':
            return { 
                ...state,
                userBlocked: action.payload
            }
        case 'userNotEnabled':
            return { 
                ...state,
                userNotEnabled: action.payload
            }
        case 'handleChangeAnswers':
            state.answerAndQuestionsId[action.payload.name] = action.payload.value

            // seteo los id de las preguntas
            state.answerAndQuestionsId.securityQuestionsId0 = state.listQuestionsByUser[0].securityQuestionsId
            state.answerAndQuestionsId.securityQuestionsId1 = state.listQuestionsByUser[1].securityQuestionsId
            state.answerAndQuestionsId.securityQuestionsId2 = state.listQuestionsByUser[2].securityQuestionsId
            return { 
                ...state,
                answerAndQuestionsId: state.answerAndQuestionsId
            }
        case 'clickRecoverPassword':
            return { 
                ...state,
                clickRecoverPassword: action.payload
            }
        case 'openInvalidIdentity':
            return { 
                ...state,
                invalidIdentity: true
            }
        case 'handleCloseInvalidIdentity':
            return { 
                ...state,
                invalidIdentity: false
            }
        default:
            return state;
    }
}