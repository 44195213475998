import { useReducer } from 'react'
import { InsuranceState } from '../../../interfaces/Insurances';
import InsuranceContext from './InsuranceContext';
import InsuranceReducer from './InsuranceReducer';
import axios from 'axios';

const INITIAL_STATE: InsuranceState = {
    data: [],
    nameFilter: '',
    insuranceStateId: 0,
    filtered: false,
    loading: true,
    loadingInsurance: true,
    statusList: [],
    chapterList: [],
    planStatusList: [],
    codesPlansList: [],
    planIdSelected: 0,
    items: {
        insurancesId: 0,
        idFopbaInsider: 0,
        name: '',
        insuranceStateId: 0,
        phone: 0,
        cellPhone: 0,
        email: '',
        cuit: 0,
        street: '',
        height: 0,
        floor: 0,
        apartment: '',
        postalCode: 0,
        town: '',
        department: '',
        state: '',
        country: '',
    },
    dataInsurancePlan: [],
    planItems: {
        plansId: 0,
        code: 0,
        description: '',
        planStateId: 0,
        codes: []
    },
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const InsuranceProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(InsuranceReducer, INITIAL_STATE)

    const getInsurances = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'loadingInsurance', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurance/list`, {headers})
        .then(response => {
            dispatch({type: 'getInsurances', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'loadingInsurance', payload: false});
        })
    }

    const getInsuranceById = async (id: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});
        
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurance/${id}`, {headers})
        .then(response => {
            dispatch({type: 'getInsuranceById', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getInsurancePlan = async (id: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurance/${Number(id)}/plans`, {headers})
        .then(response => {
            dispatch({type: 'getInsurancePlan', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getInsurancePlanById = async (insuranceId: number, insurancePlanId: number) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurance/${Number(insuranceId)}/plans`, {headers})
        .then(response => {
            dispatch({type: 'getInsurancePlanById', payload: {data: response.data, insurancePlanId: insurancePlanId}})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getInsuranceFilter = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        let params = ''
        if(state.nameFilter && state.insuranceStateId){
            params = `&name=${state.nameFilter}&status=${state.insuranceStateId}`
        } else if(state.nameFilter){
            params = `&name=${state.nameFilter}`
        } else if(state.insuranceStateId){
            params = `&status=${state.insuranceStateId}`
        }

        const url = `${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurance/list?${params}`

        await axios.get(url, {headers})
        .then(response => {
            dispatch({type: 'getInsurances', payload: response.data})
            dispatch({type: 'filtered', payload: true})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getInsuranceStatus = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurance/status`, {headers})
        .then(response => {
            dispatch({type: 'getInsuranceStatus', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getPlanStatus = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}insurance/codes/status`, {headers})
        .then(response => {
            dispatch({type: 'getPlanStatus', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    // const getChapters = async () => {

    //     const headers = {
    //         'Authorization': `Bearer ${localStorage.getItem('token')}`,
    //     };

        // await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}chapters`, {headers})
        // .then(response => {
        //     dispatch({type: 'getChapters', payload: response.data})
        // })
        // .catch(error => {
        //     if(error.response.status === 401){
        //         window.location.href = '/'
        //     }
        // })
    // }

    const clearFilter = () => {
        dispatch({type: 'clearFilter'})
        // si se filtro llamo al get de obras sociales para traer todos los resultado nuevamente
        if(state.filtered){
            getInsurances()
            dispatch({type: 'filtered', payload: false})
        }
    }

    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeFilter', payload: {name: event.target.name, value: event.target.value}})
    };

    return(
        <InsuranceContext.Provider value={{
            state,
            getInsurances,
            getInsuranceFilter,
            clearFilter,
            getInsuranceById,
            getInsuranceStatus,
            handleChangeFilter,
            getPlanStatus,
            // getChapters,
            getInsurancePlanById,
            getInsurancePlan
        }}>
            {children}
        </InsuranceContext.Provider>
    )
}

export default InsuranceProvider