import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import Theme from './Theme';
import LoginProvider from './components/context/Login/LoginProvider';
import UserProvider from './components/context/User/UserProvider';
import CircleProvider from './components/context/Circles/CircleProvider';
import ContactProvider from './components/context/Contact/ContactProvider';
import InsuranceProvider from './components/context/Insurances/InsuranceProvider';
import EmpadronamientoProvider from './components/context/Empadronamiento/EmpadronamientoProvider';
import DentalRecordsProvider from './components/context/DentalRecords/DentalRecordsProvider';
import OfficesProvider from './components/context/Offices/OfficesProvider';
import Header from './components/Header';
import Footer from './components/Footer/Index';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const viewLogin = window.location.pathname === '/'
const viewRecoverPassword = window.location.pathname === '/recuperar-contraseña' || window.location.pathname === '/recuperar-contrase%C3%B1a'
const viewNewPassword = window.location.pathname === '/nueva-contraseña' || window.location.pathname === '/nueva-contrase%C3%B1a'
const viewSecurityQuestions = window.location.pathname === '/preguntas-de-seguridad'
const viewEmpadronamiento = window.location.pathname === '/empadronamiento'
const viewPerfilProfesional = window.location.pathname === '/perfil-profesional'

// si no tiene seteado el token y esta en alguna vista que no sea el "login" o "recuperar contraseña" lo redirijo al login para que inicie sesion
if(!localStorage.getItem('token') && !viewLogin && !viewRecoverPassword && !viewNewPassword){
  window.location.href = '/'
}

root.render(
  <BrowserRouter>
    <ThemeProvider theme={Theme}>
      <LoginProvider>
        <UserProvider>
          <ContactProvider>
            <CircleProvider>
              <InsuranceProvider>
                <EmpadronamientoProvider>
                  <DentalRecordsProvider>
                    <OfficesProvider>
                      {viewLogin || viewNewPassword || viewSecurityQuestions || viewRecoverPassword || viewEmpadronamiento || viewPerfilProfesional ? <></> : <Header/> }
                        <App />
                      {viewLogin || viewNewPassword || viewSecurityQuestions || viewRecoverPassword || viewEmpadronamiento || viewPerfilProfesional ? <></> : <Footer/>}
                    </OfficesProvider>
                  </DentalRecordsProvider>
                </EmpadronamientoProvider>
              </InsuranceProvider>
            </CircleProvider>
          </ContactProvider>
        </UserProvider>
      </LoginProvider>
    </ThemeProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
