import { createContext } from 'react'
import { CircleState } from '../../../interfaces/Circles';

export interface ContextProps {
    state: CircleState,
    getCirculos: () => void
    getCirculoById: (id: string | undefined) => void
    getUserCircles: () => void
    setUserCircle: (circleId: number) => void
    getCirclesFilter: () => void
    handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    clearFilter: () => void
    getProvincias: () => void
    getDepartamentos: () => void
    getLocalidades: () => void
    
}

const CirculoContext = createContext<ContextProps>({} as ContextProps);

export default CirculoContext;