import { createContext } from 'react'
import { EmpadronamientoState, EnrollmentDetailItems } from '../../../interfaces/Empadronamiento';
import { SelectChangeEvent } from '@mui/material';

export interface ContextProps {
    state: EmpadronamientoState,
    getEnrollment: () => void
    getEnrollmentDetail: (enrollmentId: number) => void
    getEnrollmentStatus: () => void
    getAllEnrollments: () => void
    getAllEnrollmentDetail: (enrollmentId: number) => void
    getAllEnrollmentsFilter: () => void
    modifyEnrollmentStatus: (registrationsId: number, status: number, comment?: string) => void
    getProfessionals: () => void
    saveProfessionals: () => void
    getCircles: () => void
    handleChangeSelectCircle: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handlePrevStep: () => void
    handleNextStep: () => void
    clearConsultorioValues: () => void
    removeConsultorio: (idToRemove: number) => void
    getInsurances: () => void
    getInsuranceType: () => void
    handleChangeFile: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleChangeFileSpeciality: (event: React.ChangeEvent<HTMLInputElement>) => void
    getInsuranceFilter: () => void
    clearFilter: () => void
    clearEnrollmentFilter: () => void
    handleClickCoverage: (event: React.ChangeEvent<HTMLInputElement>, insuranceId: number, coverageSelected: number) => void
    handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeSelectFilter: (event: SelectChangeEvent<string>) => void
    getMainSpeciality: () => void
    handleChangeSpeciality: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    clearSpecialityValues: () => void
    removeSpeciality: (idToRemove: number) => void
    handleChangeSecureCheck: (secureId: number) => void
    saveEnrollment: (enrollmentId?: number) => void
    getProvincias: () => void
    getDepartamentos: () => void
    getLocalidades: () => void
    getAllLocalidades: () => void
    autocompleteEnrollmentFields: (enrollmentsDetail: EnrollmentDetailItems) => void
    getGeneralRules: () => void
    getParticularRules: () => void
    rulesController: () => void
    handleChangeUbicacion: (value: any, name: string) => void
    handleChangeInput: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeInputConsultorio: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeDatePicker: (value: string, name: string) => void
    handleChangeYearPicker: (value: string, name: string) => void
    handleOpenConfirm: () => void
    handleCloseConfirm: () => void
    handleChangeView: (view: string) => void
    handleChangeHasXRay: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    closeSummaryModal: () => void
    handleClickCheck: (event: React.ChangeEvent<HTMLInputElement>) => void
    setLatAndLng: (latAndLng: string) => void
    setInsuranceResponse: (isSet: boolean) => void
    getUserCircles: () => void
}

const EmpadronamientoContext = createContext<ContextProps>({} as ContextProps);

export default EmpadronamientoContext;