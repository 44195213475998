import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const PaperStyled = styled(Paper)(() => ({
    margin: '18px 16px 120px 16px', 
    padding: '10px 30px 40px', 
    borderRadius: 10,
    position: 'relative'
}));

interface Props {
    child: JSX.Element
}

const Container = ({child}: Props) => {
    return (
        <PaperStyled elevation={5}>{child}</PaperStyled>
    )
}

export default Container