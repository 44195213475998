import { useContext, useEffect, useState } from "react"
import Container from '../Container'
import { Box, Divider, Grid, Paper, TextField } from '@mui/material'
import MuiButton from '@mui/material/Button'
import { TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext"
import Loader from "../Loader";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
import Button from '../Button';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import ListPermissions from "../../utils/ListPermissions";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3B72FF',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Index = () => {

    const { state, getProfessionals, getAllEnrollments, getEnrollmentStatus, getCircles, clearEnrollmentFilter, handleChangeFilter, handleChangeSelectFilter, getAllEnrollmentsFilter } = useContext(EmpadronamientoContext)

    useEffect(() => {
        getProfessionals()
        getAllEnrollments()
        getEnrollmentStatus()
        getCircles()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(!ListPermissions().listarEmpadronamientos){
        window.location.href = '/inicio'
    }

    if(state.loading || state.loadingAllEnrollments){
        return <Loader />
    }

    // habilito el boton de filtrar cuando se haya escrito al menos 3 caracteres de nombre o apellido
    const FilterDiabled = state.enrollmentNameFilter.length >= 3 || state.enrollmentLastNameFilter.toString().length >= 2 || state.enrollmentlicenseFilter || state.enrollmentStatusFilter

    return (
        <Container
            child={
                <Box>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                            <h4>Empadronamientos</h4>
                        </Grid>
                    </Grid>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />

                    <Grid container justifyContent='space-between' alignItems='center' spacing={3} mb={3}>
                        <Grid xs={6} sm={6} md={2.5} item>
                            <TextField fullWidth value={state.enrollmentNameFilter} name='enrollmentNameFilter' label='Nombre' onChange={(e) => handleChangeFilter(e)} />
                        </Grid>
                        <Grid xs={6} sm={6} md={2.5} item>
                            <TextField fullWidth value={state.enrollmentLastNameFilter ? state.enrollmentLastNameFilter : ''} name='enrollmentLastNameFilter' label='Apellido' onChange={(e) => handleChangeFilter(e)} />
                        </Grid>
                        <Grid xs={6} sm={6} md={2.5} item>
                            <TextField
                                fullWidth
                                value={state.enrollmentlicenseFilter}
                                name="enrollmentlicenseFilter"
                                label="Matrícula"
                                onChange={(e) => handleChangeFilter(e)}
                            />
                        </Grid>
                        <Grid xs={6} sm={6} md={2.5} item>
                            <FormControl
                                fullWidth
                                variant="outlined"
                                error={!state.enrollmentStatus}
                            >
                                <InputLabel>Estado</InputLabel>
                                <Select
                                    label="Estado"
                                    name="enrollmentStatusFilter"
                                    fullWidth
                                    value={state.enrollmentStatusFilter}
                                    onChange={(e) => handleChangeSelectFilter(e)}
                                >
                                    {state.enrollmentStatus.map((c) => (
                                        <MenuItem
                                            key={c.registrationStatesId}
                                            value={c.registrationStatesId}
                                            dense
                                        >
                                            {c.description}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} sm={12} md={2} item>
                            <MuiButton variant="outlined" size={state.enrollmentNameFilter || state.enrollmentLastNameFilter || state.enrollmentlicenseFilter || state.enrollmentStatusFilter ? 'small' : 'large'} fullWidth startIcon={<FilterAltIcon />} onClick={() => {getAllEnrollmentsFilter(); setPage(0)}} disabled={!FilterDiabled}>Filtrar</MuiButton>
                            {state.enrollmentNameFilter || state.enrollmentLastNameFilter || state.enrollmentlicenseFilter || state.enrollmentStatusFilter ? 
                                <MuiButton variant="outlined" size='small' fullWidth color='error' startIcon={<FilterAltOffIcon />} onClick={() => {clearEnrollmentFilter(); setPage(0)}}>Limpiar</MuiButton>
                            : null}
                        </Grid>
                    </Grid>

                    {state.allEnrollmentsData ? 
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">Nombre</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">Apellido</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">Círculo</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">Fecha de Empadronamiento</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">Estado</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="center">Detalle</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0 
                                        ? state.allEnrollmentsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : state.allEnrollmentsData
                                    ).map((row) => {
                                        const dataCircles = state.dataCircles.find(item => item.circlesId === row.circleId)
                                        return <StyledTableRow
                                            key={row.registrationsId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell sx={{padding: '12px'}} align="left">{row.name}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '12px'}} align="left">{row.lastName}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '12px'}} align="left">{dataCircles?.idFopbaInsider} - {dataCircles?.name}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '12px'}} align="left">{row.registrationFinishDate}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '12px'}} align="left">{state.enrollmentStatus.find(item => item.registrationStatesId === row.status)?.description}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '12px'}} align="center">
                                                <Link to={`/empadronamientos/${row.registrationsId}/${row.status}`} style={{color: '#3C78F8', textDecoration: 'none'}}>
                                                    <SearchIcon color="primary" sx={{cursor: 'pointer'}} />
                                                </Link>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                            colSpan={3}
                                            count={state.allEnrollmentsData.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'Filas por pagina',
                                                },
                                                native: true,
                                            }}
                                            labelRowsPerPage='Filas por Página'
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={() => <Pagination count={state.allEnrollmentsData.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    : null}

                    <Grid container justifyContent='end' mt={4}>
                        <Grid item xs={4} sm={4} md={3}>
                            <Button 
                                child={
                                    <Link style={{textDecoration: 'none', color: '#4e4e4e', width: '100%'}} to={'/inicio'}>
                                        Volver
                                    </Link>
                                } 
                                variant='outlined' 
                                color='secondary'
                            />
                        </Grid>
                    </Grid>
                </Box>
            }
        />
    )
}

export default Index