import { securityQuestions } from '../../../interfaces/Login';
import { EmpadronamientoState, ProfessionalItems, CircleItems, InsuranceItems, InsuranceTypeItems, MainSpecialityItems, ConsultorioItems, SpecialityItems, EnrollmentItems, EnrollmentStatusItems, EnrollmentDetailItems, GeneralRules, ParticularRules } from '../../../interfaces/Empadronamiento';
import { UserCircles } from '../../../interfaces/Circles';
import { CellPhoneFormat } from '../../../utils/CellPhoneFormat';

type Action = {type: 'getEnrollment', payload: EnrollmentItems[]}
    |{type: 'getEnrollmentStatus', payload: EnrollmentStatusItems[]}
    |{type: 'getEnrollmentDetail', payload: EnrollmentDetailItems}
    |{type: 'getAllEnrollments', payload: EnrollmentItems[]}
    |{type: 'getAllEnrollmentDetail', payload: EnrollmentDetailItems}
    |{type: 'getAllEnrollmentsFilter', payload: EnrollmentItems[]}
    |{type: 'getProfessionals', payload: ProfessionalItems}
    | {type: 'getCircles', payload: CircleItems[]}
    | {type: 'clearConsultorioValues', payload: ConsultorioItems}
    | {type: 'removeConsultorio', payload: number}
    | {type: 'getInsurances', payload: InsuranceItems[]}
    | {type: 'getInsuranceFiltered', payload: InsuranceItems[]}
    | {type: 'getInsuranceType', payload: InsuranceTypeItems[]}
    | {type: 'handleChangeFile', payload: {valueBase64: string, value: FileList | null, name: string }}
    | {type: 'handleChangeFileSpeciality', payload: {valueBase64: string, value: FileList | null, name: string }}
    | {type: 'handleStep', payload: number}
    | {type: 'openConfirm', payload: boolean}
    | {type: 'clearFilter'}
    | {type: 'clearEnrollmentFilter'}
    | {type: 'handleClickCoverage', payload: {status: boolean, insuranceId: number, coverageSelected: number}}
    | {type: 'filtered', payload: boolean}
    | {type: 'enrollmentFiltered', payload: boolean}
    | {type: 'setLoadingInsurance', payload: boolean}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'handleChangeSelectFilter', payload: {name: string, value: string}}
    | {type: 'getMainSpeciality', payload: MainSpecialityItems[]}
    | {type: 'clearSpecialityValues', payload: SpecialityItems}
    | {type: 'autocompleteEnrollmentFields', payload: any}
    | {type: 'removeSpeciality', payload: number}
    | {type: 'handleChangeSecureCheck', payload: number}
    | {type: 'getProvincias', payload: []}
    | {type: 'getDepartamentos', payload: []}
    | {type: 'getLocalidades', payload: []}
    | {type: 'getAllLocalidades', payload: []}
    | {type: 'changeUbicacion', payload: {name: string, value: string}}
    | {type: 'handleChangeInput', payload: {name: string, value: string}}
    | {type: 'handleChangeInputConsultorio', payload: {name: string, value: string}}
    | {type: 'changeYearPicker', payload: {name: string, value: string}}
    | {type: 'changeDatePicker', payload: {name: string, value: string}}
    | {type: 'userAdded', payload: boolean}
    | {type: 'setLoading', payload: boolean}
    | {type: 'getSecurityQuestions', payload: securityQuestions[]}
    | {type: 'handleChangeAnswerField', payload: {value: string, name: string}}
    | {type: 'handleChangeSelectCircle', payload: {value: string}}
    | {type: 'handleChangeSpeciality', payload: {value: string | boolean, name: string}}
    | {type: 'viewToShow', payload: string}
    | {type: 'handleChangeHasXRay', payload: boolean | string}
    | {type: 'openSummaryModal', payload: boolean}
    | {type: 'setLoadingEnrollmentDetail', payload: boolean}
    | {type: 'setLoadingEnrollment', payload: boolean}
    | {type: 'setLoadingAllEnrollments', payload: boolean}
    | {type: 'handleClickCheck', payload: boolean}
    | {type: 'setLatAndLng', payload: string}
    | {type: 'setInsuranceResponse', payload: boolean}
    | {type: 'getGeneralRules', payload: GeneralRules[]}
    | {type: 'getParticularRules', payload: ParticularRules[]}
    | {type: 'loadingDatosDemograficos', payload: boolean}
    | {type: 'showReempadronamientoButton', payload: boolean}
    | {type: 'messageError', payload: string[]}
    | {type: 'getUserCircles', payload: UserCircles[]}

export default function EmpadronamientoReducer (state: EmpadronamientoState, action: Action): EmpadronamientoState {
    switch (action.type) {
        case 'getEnrollment':
            return { 
                ...state, 
                enrollmentData: action.payload,
                loadingEnrollment: false
            }
        case 'getEnrollmentDetail':
            return { 
                ...state, 
                enrollmentDetail: action.payload,
                loadingEnrollmentDetail: false
            }
        case 'getEnrollmentStatus':
            return { 
                ...state, 
                enrollmentStatus: action.payload,
                loading: false
            }
        case 'getAllEnrollments':
            return { 
                ...state, 
                allEnrollmentsData: action.payload,
                loadingAllEnrollments: false
            }
        case 'getAllEnrollmentDetail':
            return { 
                ...state, 
                allEnrollmentsDetail: action.payload,
                loadingAllEnrollments: false
            }
        case 'getAllEnrollmentsFilter':
            return { 
                ...state, 
                allEnrollmentsData: action.payload,
                loadingAllEnrollments: false
            }
        case 'getProfessionals':
            return { 
                ...state, 
                dataProfessional: action.payload,
                loading: false
            }
        case 'getCircles':
            return { 
                ...state, 
                dataCircles: action.payload,
                loading: false
            }
        case 'handleChangeSelectCircle':
            return { 
                ...state,
                circleName: action.payload.value,
            }
        case 'handleStep':
            switch (action.payload) {
                case 0:
                    state.viewToShow = 'step1'
                    break;
                case 1:
                    state.viewToShow = 'step2'
                    break;
                case 2:
                    state.viewToShow = 'step3'
                    break;
                case 3:
                    state.viewToShow = 'step4'
                    break;
                case 4:
                    state.viewToShow = 'step5'
                    break;
                default:
                    state.viewToShow = 'step1'
            }
            return { 
                ...state, 
                activeStep: action.payload
            }
        case 'clearConsultorioValues':
            let consultorios = state.consultorioCargado
            state.consultorioItems.officesId = state.consultorioCargado.length + 1
            // agrego datos de consultorio en un nuevo array y luego limpio los datos cargados en el form
            consultorios.push(state.consultorioItems)
            return { 
                ...state,
                consultorioCargado: consultorios,
                consultorioItems: action.payload
            }
        case 'removeConsultorio':
            const consultoriosFiltrados = state.consultorioCargado.filter(consultorio => consultorio.officesId !== action.payload)
            return { 
                ...state,
                consultorioCargado: consultoriosFiltrados
            }
        case 'getInsurances':
            return { 
                ...state, 
                insuranceData: action.payload,
                loadingInsurance: false
            }
        case 'getInsuranceFiltered':
            const dataFiltrada: any = []
            action.payload.map(item => {
                const filter = state.insuranceData.filter(insurance => insurance.insurancesId === item.insurancesId)[0]
                if(filter){
                    dataFiltrada.push(filter)
                }
            })
   
            return { 
                ...state, 
                insuranceFiltered: dataFiltrada,
                loadingInsurance: false
            }
        case 'setLoadingInsurance':
            return {
                ...state,
                loadingInsurance: action.payload
            }
        case 'getInsuranceType':
            return { 
                ...state, 
                insuranceTypeData: action.payload,
                loading: false
            }
        case 'handleChangeFile':
            state.insuranceTypeItems[action.payload.name] = action.payload.value
            state.insuranceTypeItems.collectiveFileBase64 = action.payload.valueBase64
            return {
                ...state
            }
        case 'handleChangeFileSpeciality':
            state.specialityItems[action.payload.name] = action.payload.value
            state.specialityItems.certifyFileBase64 = action.payload.valueBase64
            return {
                ...state
            }
        case 'clearFilter':
            return { 
                ...state, 
                nameFilter: ''
            }
        case 'clearEnrollmentFilter':
            return { 
                ...state, 
                enrollmentNameFilter: '',
                enrollmentLastNameFilter: ''
            }
        case 'handleClickCoverage':
            state.insuranceData.forEach(insurance => {
                if(insurance.insurancesId === action.payload.insuranceId){
                    const coverageSelected = insurance.generalCoverages.filter(item => item.generalCoveragesId === action.payload.coverageSelected)[0]
                    // si se selecciona el check seteo el atributo status = true y si se desmarca elimino el atributo status
                    action.payload.status ? coverageSelected.status = true : delete coverageSelected.status 
                }
            })

            return { 
                ...state,
                InsurancesSelectedItems: state.InsurancesSelectedItems,
                insurancesSelected: state.insurancesSelected
            }
        case 'filtered':
            return { 
                ...state, 
                filtered: action.payload,
                insuranceFiltered: !action.payload ? [] : state.insuranceFiltered

            }
        case 'enrollmentFiltered':
            return { 
                ...state, 
                enrollmentFiltered: action.payload,
                allEnrollmentsData: state.allEnrollmentsData
            }
        case 'handleChangeFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'handleChangeSelectFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'getMainSpeciality':
            return { 
                ...state, 
                mainSpecialityData: action.payload,
                loading: false
            }
        case 'handleChangeSpeciality':
            state.specialityItems[action.payload.name] = action.payload.value

            return { 
                ...state
            }
        case 'clearSpecialityValues':
            let speciality = state.specialityLoad
            // agrego datos de consultorio en un nuevo array y luego limpio los datos cargados en el form
            speciality.push(state.specialityItems)
            return { 
                ...state,
                specialityLoad: speciality,
                specialityItems: action.payload
            }
        case 'autocompleteEnrollmentFields':
            state.consultorioCargado = action.payload.consultorioCargado
            state.specialityLoad = action.payload.specialityLoad
            state.secureId = action.payload.secureId
            state.circleId = action.payload.circleId
            return { 
                ...state,
                autocompleteEnrollmentFields: true
            }
        case 'removeSpeciality':
            const specialityFiltered = state.specialityLoad.filter(speciality => speciality.specialityId !== action.payload)
            return { 
                ...state,
                specialityLoad: specialityFiltered
            }
        case 'handleChangeSecureCheck':
            return { 
                ...state,
                secureId: action.payload
            }
        case 'openConfirm':
            return { 
                ...state,
                openConfirm: action.payload
            }
        case 'getProvincias':
            return { 
                ...state, 
                provincias: action.payload
            }
        case 'getDepartamentos':
            return { 
                ...state, 
                departamentos: action.payload
            }
        case 'getLocalidades':
            return { 
                ...state, 
                localidades: action.payload,
                loadingDatosDemograficos: false
            }
        case 'getAllLocalidades':
            return { 
                ...state, 
                allLocalidades: action.payload,
            }
        case 'changeUbicacion':
            state.consultorioItems[action.payload.name] = action.payload.value
            return { 
                ...state
            }
        case 'handleChangeInput':
            state.professionalItems[action.payload.name] = action.payload.value

            return {
                ...state
            }
        case 'handleChangeInputConsultorio':
            state.consultorioItems[action.payload.name] = action.payload.value

            if(action.payload.name === 'cellPhone'){
                state.consultorioItems.cellPhone = CellPhoneFormat(action.payload.value)
            }
            if(action.payload.name === 'phone'){
                state.consultorioItems.phone = CellPhoneFormat(action.payload.value)
            }
            if(action.payload.name === 'postalCode'){
                state.consultorioItems.postalCode = action.payload.value.replaceAll('-', '')
            }
            if(action.payload.name === 'height'){
                state.consultorioItems.height = action.payload.value.replaceAll('-', '')
            }

            return {
                ...state
            }
        case 'changeDatePicker':
            state.professionalItems[action.payload.name] = action.payload.value
            state.consultorioItems[action.payload.name] = action.payload.value
            if(action.payload.name === 'secureStartDate'){
                state.enrollmentDetail.secureStartDate = action.payload.value
            }
            if(action.payload.name === 'secureFinishDate'){
                state.enrollmentDetail.secureFinishDate = action.payload.value
            }
            return {
                ...state
            }
        case 'changeYearPicker':
            state.specialityItems[action.payload.name] = action.payload.value
            return {
                ...state
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'viewToShow':
            return { 
                ...state,
                viewToShow: action.payload
            }
        case 'handleChangeHasXRay':
            state.consultorioItems.hasXRay = action.payload
            return {
                ...state
            }
        case 'openSummaryModal':
            return {
                ...state,
                openSummaryModal: action.payload
            }
        case 'setLoadingEnrollmentDetail':
            return {
                ...state,
                loadingEnrollmentDetail: action.payload
            }
        case 'setLoadingEnrollment':
            return {
                ...state,
                loadingEnrollment: action.payload
            }
        case 'setLoadingAllEnrollments':
            return {
                ...state,
                loadingAllEnrollments: action.payload
            }
        case 'handleClickCheck':
            state.consultorioItems.height = action.payload ? 'S/N' : 0
            return {
                ...state
            }
        case 'setLatAndLng':
            state.consultorioItems.googlePlaceId = action.payload
            return {
                ...state
            }
        case 'setInsuranceResponse':
            state.setInsuranceResponse = action.payload
            return {
                ...state
            }
        case 'getGeneralRules':
            state.generalRules = action.payload
            return {
                ...state
            }
        case 'getParticularRules':
            state.particularRules = action.payload
            return {
                ...state
            }
        case 'loadingDatosDemograficos':
            return {
                ...state,
                loadingDatosDemograficos: action.payload
            }
        case 'showReempadronamientoButton':
            return {
                ...state,
                showReempadronamientoButton: action.payload
            }
        case 'messageError':
            return { 
                ...state, 
                messageError: action.payload
            }
        case 'getUserCircles':
            if(action.payload.length === 1){
                state.circleName = action.payload[0].name
            }
            return { 
                ...state, 
                userCircles: action.payload
            }
        default:
            return state;
    }
}