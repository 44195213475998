import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const DialogStyled = styled(Dialog)(() => ({
    '.MuiDialog-paper': {
        maxWidth: window.innerWidth < 500 ? '100%' : '70%',
        width: window.innerWidth < 500 ? '100%' : '70%'
    }
}))
const BoxStyled = styled(Box)(() => ({
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none'
}))

const ButtonStyled = styled(Button)(() => ({
    borderRadius: 15,
    backgroundColor: '#42C5B7',
    '&:hover': {background: '#389b90'}
}))

interface Props {
    openModal: boolean
    handleCloseModal: () => void
    handleNextStep: () => void
}

export const LegalTermsIomaModal = ({openModal, handleCloseModal, handleNextStep}:Props) => {
  return (
    <DialogStyled
        open={openModal}
        onClose={handleCloseModal}
    >
        <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
            }}
        >
            <CloseIcon />
        </IconButton>
        <BoxStyled p={5}>
            <DialogTitle textAlign={'center'} fontWeight={600}>
                TÉRMINOS Y CONDICIONES DE OBRA SOCIAL "IOMA"
            </DialogTitle>
            <p>Por medio de la presente y en carácter de la DECLARACIÓN JURADA, presto mi conformidad para brindar el servicio odontológico a los afiliados al IOMA respetando las Normativas de Atención Vigentes, todo ello bajo de apercibimiento de dejar de ser parte de la red de Prestadores.</p>
            <p><b>Si usted no acepta los términos y condiciones de IOMA, no podrá seguir el empadronamiento con esta obra social seleccionada.</b></p>
            <DialogActions sx={{flexDirection: 'column'}}>
            <Grid container spacing={2} justifyContent='center' mt={2} gap={3}>
                <ButtonStyled variant='contained' onClick={handleCloseModal}>Cerrar</ButtonStyled>
                <ButtonStyled variant='contained' onClick={handleNextStep}>Aceptar</ButtonStyled>
            </Grid>
        </DialogActions>
        </BoxStyled>
    </DialogStyled>
  )
}
