import { useReducer } from 'react'
import axios from 'axios';
import ContactReducer from './ContactReducer';
import ContactContext from './ContactContext';
import { ContactState } from '../../../interfaces/Contact';

const INITIAL_STATE: ContactState = {
    contact: {
        phone: 0,
        cellPhone: 0,
        street: '',
        height: 0,
        floor: 0,
        apartment: '',
        postalCode: 0,
        town: '',
        department: '',
        state: '',
        country: '',
        placeId: '',
        mail: '',
        availability: '',
        facebook: '',
        linkedin: '',
        instagram: '',
        twitter: '',
        youtube: '',
    },
    loading: true
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const LoginProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(ContactReducer, INITIAL_STATE)

    const getContact = async () => {
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'loading', payload: true})

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}fopbaContacts`, {headers})
        .then(response => {
            dispatch({type: 'getContact', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'loading', payload: false})
        }) 
    }

    return(
        <ContactContext.Provider value={{
            state,
            getContact,
        }}>
            {children}
        </ContactContext.Provider>
    )
}

export default LoginProvider