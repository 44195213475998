import { useContext, useEffect, useState } from "react"
import Container from '../Container'
import { Box, Divider, Grid, Paper, TextField, MenuItem } from '@mui/material'
import MuiButton from '@mui/material/Button'
import { TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import DentalRecordsContext from "../context/DentalRecords/DentalRecordsContext"
import Loader from "../Loader";
import Pagination from "../Pagination";
import { Link } from "react-router-dom";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { tokenDecode } from '../../interfaces/Login';
import EditIcon from '@mui/icons-material/Edit';
import jwt_decode from "jwt-decode";
import AffiliateDataModal from "./AffiliateDataModal";

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3B72FF',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Index = () => {
    let decoded: tokenDecode;
    const { state, getDentalRecords, getDentalRecordsFilter, getEnrollment, getDentalRecordStates, getInsurances, getInsuranceStatus, clearFilter, handleChangeFilter, getAffiliates, closeAlertAffiliateNotFound, getUserCircles, getEnrollmentDetail } = useContext(DentalRecordsContext)

    const [openAffiliateDataModal, setOpenAffiliateDataModal] = useState(false);

    useEffect(() => {
        if(!state.data.length){
            getDentalRecords()
            getInsurances()
            getInsuranceStatus()
            getDentalRecordStates()
            if(decoded?.profileId !== 4){
                getEnrollment()
            }
            getUserCircles()
        }
        if(openAffiliateDataModal){
            getEnrollment()
            getUserCircles()
        }
    }, [openAffiliateDataModal])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleClickOpenAffiliateDataModal = () => {
        setOpenAffiliateDataModal(!openAffiliateDataModal);
        if(!openAffiliateDataModal){
            closeAlertAffiliateNotFound()
        }
    } 

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(state.loading || state.loadingModal){
        return <Loader />
    }

    // habilito el boton de filtrar cuando se haya escrito al menos 1 caracter
    const FilterDiabled = state.insuranceId || state.professionalEnrollment || state.status || state.affiliateFilter
    const token = localStorage.getItem('token')
    decoded  = jwt_decode(token ? token : '');

    let hasActiveEnrollment = false
    if(state.enrollmentData.length){
        state.enrollmentData.map(enrollment => {
            if(enrollment.circleId === state.userCircleSelected){
                hasActiveEnrollment = true
            }
        })
    }

    return (
        <Container
            child={
                <Box>
                    <h4>Fichas</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />

                    {decoded.isProfessional &&
                        <MuiButton variant="contained" sx={{marginBottom: 2}} onClick={handleClickOpenAffiliateDataModal}>Agregar Ficha</MuiButton>
                    }

                    <Grid container justifyContent='space-between' alignItems='center' spacing={1} mb={3}>
                        <Grid xs={6} sm={decoded.profileId === 4 ? 2 : 3} md={decoded.profileId === 4 ? 2 : 3} item>
                            <TextField 
                                select 
                                fullWidth 
                                value={state.insuranceId ? state.insuranceId : ''} 
                                name='insuranceId' 
                                label='Obra Social' 
                                onChange={(e) => handleChangeFilter(e)}
                            >
                                {state.dataInsurance.map((insurance) => (
                                    <MenuItem key={insurance.insurancesId} value={insurance.insurancesId}>
                                        {`(${insurance.idFopbaInsider}) - ${insurance.name}`}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        {decoded.profileId === 4 ?
                            <Grid xs={6} sm={2} md={2} item>
                                <TextField fullWidth value={state.professionalEnrollment} name='professionalEnrollment' label='Matrícula' onChange={(e) => handleChangeFilter(e)} />
                            </Grid>
                        : null}
                        <Grid xs={6} sm={decoded.profileId === 4 ? 2 : 3} md={decoded.profileId === 4 ? 2 : 3} item>
                            <TextField 
                                select 
                                fullWidth 
                                value={state.status ? state.status : ''} 
                                name='status' 
                                label='Estado' 
                                onChange={(e) => handleChangeFilter(e)}
                            >
                                {state.dentalRecordStates.map((item) => (
                                    <MenuItem key={item.dentalRecordStatesId} value={item.dentalRecordStatesId}>
                                        {item.description}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid xs={6} sm={decoded.profileId === 4 ? 2 : 3} md={decoded.profileId === 4 ? 2 : 3} item>
                            <TextField fullWidth value={state.affiliateFilter} name='affiliateFilter' label='Nº de Afiliado' onChange={(e) => handleChangeFilter(e)} />
                        </Grid>
                        <Grid xs={4} sm={3} md={1.5} item>
                            <MuiButton variant="outlined" size={FilterDiabled ? 'small' : 'large'} fullWidth startIcon={<FilterAltIcon />} onClick={() => {getDentalRecordsFilter(); setPage(0)}} disabled={!FilterDiabled}>Filtrar</MuiButton>
                            {FilterDiabled ? 
                                <MuiButton variant="outlined" size='small' fullWidth color='error' startIcon={<FilterAltOffIcon />} onClick={clearFilter}>Limpiar</MuiButton>
                            : null}
                        </Grid>
                    </Grid>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Fecha</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Obra Social</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Plan</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">N° de Afiliado</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Nombre</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Apellido</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Estado</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Detalle</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 
                                    ? state.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : state.data
                                ).map((row) => (
                                    <StyledTableRow
                                        key={row.dentalRecordsId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell sx={{padding: '12px'}} component="th" scope="row" align="left">
                                            {row.createDate ? row.createDate : row.date}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{state.dataInsurance.filter(item => item.insurancesId === row.insuranceId).map(insurance => insurance.idFopbaInsider + ' - ' + insurance.name)}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.planId}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.code}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.professionalFullName.name}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.professionalFullName.lastname}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '5px'}} align="center">{state.dentalRecordStates.find(item => item.dentalRecordStatesId === row.status)?.description}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '5px'}} align="center">
                                            {/* redirect a edicion de ficha si el estado es rechazado fopba o rechazado circulo y si el usuario logueado es el mismo que creo la ficha  */}
                                            {(row.status === 5 || row.status === 6) && decoded.isProfessional ?
                                                <Link to={`/modificar-ficha/${row.dentalRecordsId}/${row.status}`} style={{color: '#3C78F8', textDecoration: 'none', width: '100%'}}>
                                                    <EditIcon color="primary" sx={{cursor: 'pointer'}} />
                                                </Link>
                                            :   <Link to={`/ficha/${row.dentalRecordsId}/${row.status}`} style={{color: '#3C78F8', textDecoration: 'none', width: '100%'}}>
                                                    <SearchIcon color="primary" sx={{cursor: 'pointer'}} />
                                                </Link>
                                            }
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                        colSpan={3}
                                        count={state.data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Filas por pagina',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage='Filas por Página'
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => <Pagination count={state.data.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    {openAffiliateDataModal && 
                        <AffiliateDataModal 
                            state={state}
                            hasActiveEnrollment={hasActiveEnrollment}
                            openModal={openAffiliateDataModal}
                            getEnrollmentDetail={getEnrollmentDetail}
                            handleCloseModal={handleClickOpenAffiliateDataModal}
                            getAffiliates={getAffiliates}
                        />
                    }
                </Box>
            }
        />
    )
}

export default Index