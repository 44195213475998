import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import { Link } from "react-router-dom";
import Logo from '../img/logo.png';
import { Divider, Typography } from '@mui/material';
import ListPermissions from '../utils/ListPermissions';
import CircleContext from '../components/context/Circles/CircleContext';
import UserContext from '../components/context/User/UserContext';
import { tokenDecode } from '../interfaces/Login';
import jwt_decode from "jwt-decode";

export default function Header() {

    const { state, getUserCircles, setUserCircle } = React.useContext(CircleContext)
    const { userState, getUser, getProfessionals } = React.useContext(UserContext)

    React.useEffect(() => {
        getUserCircles()
        getUser()
        // si es diferente a circulo llamo al get de profesionales
        if(decoded.profileId !== 4){
            getProfessionals()
        }
    }, [])
 
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    
    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };
    
    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };
    
    const logout = () => {
        localStorage.setItem('token', '');
        localStorage.setItem('user', '');
        window.location.reload();
    }
    
    const pathLength = window.location.pathname.length
    // saco la barra del principio del pathname
    const url = window.location.pathname.substring(1, pathLength)

    const [seccionActiva, setseccionActiva] = React.useState(url);

    const setActiva = (seccion: string) => {
        setseccionActiva(seccion)
    }

    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');

    //DESKTOP
    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={() => {handleMenuClose(); setActiva('')}}>
                <Link to={`/usuario`} style={{color: '#00071A', textDecoration: 'none', width: '100%'}}>
                    Perfil
                </Link>
            </MenuItem>
            <MenuItem onClick={() => {handleMenuClose(); setActiva('')}}>
                <Link to={`/contactenos`} style={{color: '#00071A', textDecoration: 'none'}}>
                    Contáctenos
                </Link>
            </MenuItem>
            <MenuItem onClick={logout}>Cerrar Sesión</MenuItem>
        </Menu>
    );

    //MOBILE
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <Link to='/inicio' style={{textDecoration: 'none'}}>
                    <Button onClick={handleMobileMenuClose} sx={{ color: '#00071A' }}>
                        Inicio
                    </Button>
                </Link>
            </MenuItem>
            <MenuItem>
                <Link to='/fichas' style={{textDecoration: 'none'}}>
                    <Button onClick={handleMobileMenuClose} sx={{ color: '#00071A' }}>
                        Fichas
                    </Button>
                </Link>
            </MenuItem>
            {ListPermissions().listarObrasSociales &&
                <MenuItem>
                    <Link to='/obras-sociales' style={{textDecoration: 'none'}}>
                        <Button onClick={handleMobileMenuClose} sx={{ color: '#00071A' }}>
                            Obras Sociales
                        </Button>
                    </Link>
                </MenuItem>
            }
            {ListPermissions().listarCirculos &&
                <MenuItem>
                    <Link to='/circulos' style={{textDecoration: 'none'}}>
                        <Button onClick={handleMobileMenuClose} sx={{ color: '#00071A' }}>
                            Círculos
                        </Button>
                    </Link>
                </MenuItem>
            }
            {(ListPermissions().listarEmpadronamientos && decoded.profileId !== 2) &&
                <MenuItem>
                    <Link to='/empadronamientos' style={{textDecoration: 'none'}}>
                        <Button onClick={handleMobileMenuClose} sx={{ color: '#00071A' }}>
                            Empadronamientos
                        </Button>
                    </Link>
                </MenuItem>
            }
            {ListPermissions().listarConsultorios &&
                <MenuItem>
                    <Link to='/Consultorios' style={{textDecoration: 'none'}}>
                        <Button onClick={handleMobileMenuClose} sx={{ color: '#00071A' }}>
                            Consultorios
                        </Button>
                    </Link>
                </MenuItem>
            }
            <Divider />
            <MenuItem>
                <Link to='/usuario' style={{textDecoration: 'none'}}>
                    <Button onClick={handleMobileMenuClose} sx={{ color: '#00071A' }}>
                        Mi perfil
                    </Button>
                </Link>
            </MenuItem>
            <MenuItem>
                <Button onClick={logout} sx={{ color: '#00071A' }}>
                    Cerrar sesión
                </Button>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" sx={{background: '#F6F9FF'}}>
                <Toolbar>
                    <img src={Logo} alt="logo" />
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center'}}>
                        <Box 
                            sx={{
                                marginRight: 2, 
                                padding: '21px 10px 20px 10px', 
                                background: seccionActiva === 'inicio' ? 'rgba(59, 114, 255, 0.1)' : '',
                                borderBottom: seccionActiva === 'inicio' ? '2px solid #42C5B7' : ''
                            }}
                        >
                            <Link to='/inicio' style={{textDecoration: 'none', color: '#00071A'}} onClick={() => setActiva('inicio')}>Inicio</Link>
                        </Box>
                        <Box 
                            sx={{
                                marginRight: 2, 
                                padding: '21px 10px 20px 10px', 
                                background: seccionActiva === 'fichas' ? 'rgba(59, 114, 255, 0.1)' : '',
                                borderBottom: seccionActiva === 'fichas' ? '2px solid #42C5B7' : ''
                            }}
                        >
                            <Link to='/fichas' style={{textDecoration: 'none', color: '#00071A'}} onClick={() => setActiva('fichas')}>Fichas</Link>
                        </Box>
                        {ListPermissions().listarObrasSociales &&
                            <Box 
                                sx={{
                                    marginRight: 2, 
                                    padding: '21px 10px 20px 10px', 
                                    background: seccionActiva === 'obras-sociales' ? 'rgba(59, 114, 255, 0.1)' : '',
                                    borderBottom: seccionActiva === 'obras-sociales' ? '2px solid #42C5B7' : ''
                                }}
                            >
                                <Link to='/obras-sociales' style={{textDecoration: 'none', color: '#00071A'}} onClick={() => setActiva('obras-sociales')}>Obras Sociales</Link>
                            </Box>
                        }
                        {ListPermissions().listarCirculos &&
                            <Box 
                                sx={{
                                    marginRight: 2, 
                                    padding: '21px 10px 20px 10px', 
                                    background: seccionActiva === 'circulos' ? 'rgba(59, 114, 255, 0.1)' : '',
                                    borderBottom: seccionActiva === 'circulos' ? '2px solid #42C5B7' : ''
                                }}
                            >
                                <Link to='/circulos' style={{textDecoration: 'none', color: '#00071A'}} onClick={() => setActiva('circulos')}>Círculos</Link>
                            </Box>
                        }
                        {(ListPermissions().listarEmpadronamientos && decoded.profileId !== 2) &&
                            <Box 
                                sx={{
                                    marginRight: 2, 
                                    padding: '21px 10px 20px 10px', 
                                    background: seccionActiva === 'empadronamientos' ? 'rgba(59, 114, 255, 0.1)' : '',
                                    borderBottom: seccionActiva === 'empadronamientos' ? '2px solid #42C5B7' : ''
                                }}
                            >
                                <Link to='/empadronamientos' style={{textDecoration: 'none', color: '#00071A'}} onClick={() => setActiva('empadronamientos')}>Empadronamientos</Link>
                            </Box>
                        }
                        {ListPermissions().listarConsultorios &&
                            <Box 
                                sx={{
                                    marginRight: 2, 
                                    padding: '21px 10px 20px 10px', 
                                    background: seccionActiva === 'consultorios' ? 'rgba(59, 114, 255, 0.1)' : '',
                                    borderBottom: seccionActiva === 'consultorios' ? '2px solid #42C5B7' : ''
                                }}
                            >
                                <Link to='/consultorios' style={{textDecoration: 'none', color: '#00071A'}} onClick={() => setActiva('consultorios')}>Consultorios</Link>
                            </Box>
                        }
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                        >
                            <AccountCircle />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            sx={{color: '#00071A'}}
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            <Box sx={{height: 45, background: '#3B72FF', padding: '10px 24px 20px 24px'}}>
                <Grid container justifyContent='space-between' alignItems='center'>
                    <Grid item xs={7} sm={8} md={6}>
                        <Typography sx={{color: '#fff'}}>Buenos Días {userState.items.isProfessional ? 'Dr/Dra. ' + userState.items.lastname : userState.items.name + ' ' + userState.items.lastname}</Typography>
                        {userState.dataProfessional.provincialEnrollment && userState.items.isProfessional ?
                            <div style={{display: 'flex', alignItems: 'center', color: '#fff'}}>
                                <Typography sx={{mr: '5px'}} variant='caption'>Matrícula Nº</Typography>
                                <Typography sx={{fontWeight: 'bold'}}>{userState.dataProfessional.provincialEnrollment}</Typography>
                            </div>
                        : null}
                    </Grid>
                    {state.userCircles.length ?
                        <Grid item xs={4} sm={4} md={2}>
                            <FormControl fullWidth variant='filled' sx={{background: '#F7F9FF', borderRadius: 2}}>
                                <InputLabel>Círculos</InputLabel>
                                <Select
                                    label="Circulo"
                                    sx={{background: '#F7F9FF', borderRadius: 2}}
                                    name='circle'
                                    value={state.userCircleSelected ? state.userCircleSelected : localStorage.getItem('circleUserId')}
                                    onChange={(evt) => setUserCircle(Number(evt.target.value))}
                                >
                                    {state.userCircles.map((item) => {
                                        return <MenuItem key={item.circleId} value={item.circleId}>{item.idFopbaInsider} - {item.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    : null}
                </Grid>
            </Box>
        </Box>
    );
}