import { InsuranceData, InsuranceItems, InsuranceState, StatusList, PlanStatusList, ChapterList, InsurancePlanItems } from '../../../interfaces/Insurances';

type Action = {type: 'getInsurances', payload: InsuranceData[]}
    | {type: 'getInsuranceById', payload: InsuranceItems}
    | {type: 'clearFilter'}
    | {type: 'filtered', payload: boolean}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'setLoading', payload: boolean}
    | {type: 'loadingInsurance', payload: boolean}
    | {type: 'getInsuranceStatus', payload: StatusList[]}
    | {type: 'getPlanStatus', payload: PlanStatusList[]}
    | {type: 'getChapters', payload: ChapterList[]}
    | {type: 'getInsurancePlanById', payload: {data: InsurancePlanItems[], insurancePlanId: number}}
    | {type: 'getInsurancePlan', payload: InsurancePlanItems[]}

export default function InsuranceReducer (state: InsuranceState, action: Action): InsuranceState {
    switch (action.type) {
        case 'getInsurances':
            return { 
                ...state, 
                data: action.payload,
                loadingInsurance: false
            }
        case 'getInsuranceById':
            state.items = action.payload
            return { 
                ...state, 
                items: state.items,
                loading: false
            }
        case 'clearFilter':
            return { 
                ...state, 
                nameFilter: '',
                insuranceStateId: 0
            }
        case 'filtered':
            return { 
                ...state, 
                filtered: action.payload
            }
        case 'getInsuranceStatus':
            return { 
                ...state, 
                statusList: action.payload,
                loading: false
            }
        case 'handleChangeFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'loadingInsurance':
            return {
                ...state,
                loadingInsurance: action.payload
            }
        case 'getPlanStatus':
            return { 
                ...state, 
                planStatusList: action.payload
            }
        case 'getChapters':
            return { 
                ...state, 
                chapterList: action.payload
            }
        case 'getInsurancePlanById':
            const planId = action.payload.insurancePlanId
            // Filtro la data total de planes con el id del plan seleccionado
            const data = action.payload.data.filter(data => data.plansId === planId)

            return { 
                ...state,
                codesPlansList: data[0] ? data[0].codes : [],
                planIdSelected: planId,
                loading: false,
            }
        case 'getInsurancePlan':
            return { 
                ...state, 
                dataInsurancePlan: action.payload,
                codesPlansList: action.payload[0] ? action.payload[0].codes : [],
                planIdSelected: action.payload[0] ? action.payload[0].plansId : 0,
                loading: false
            }
        default:
            return state;
    }
}