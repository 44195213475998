import { CircleItems, UserCircles } from '../../../interfaces/Circles';
import { DentalRecordsState, DentalRecords, DentalRecordsItems, DentalDetail, DentalRecordStates, SexType, DocumentType, AffiliateData, ProfessionalItems, EnrollmentItems, Treatments, ProviderTypes, ProviderDetail, ProviderState, Plans, IvaTypes, Providers } from '../../../interfaces/DentalRecords';
import { EnrollmentDetailItems } from '../../../interfaces/Empadronamiento';
import { InsuranceData, StatusList } from '../../../interfaces/Insurances';

type Action = {type: 'getDentalRecords', payload: DentalRecords[]}
    | {type: 'getDentalRecordsById', payload: DentalRecordsItems}
    | {type: 'getDentalDetail', payload: DentalDetail}
    | {type: 'getDentalRecordStates', payload: DentalRecordStates[]}
    | {type: 'getInsurances', payload: InsuranceData[]}
    | {type: 'getInsuranceStatus', payload: StatusList[]}
    | {type: 'getCirles', payload: CircleItems[]}
    | {type: 'getSexTypes', payload: SexType[]}
    | {type: 'getDocumentTypes', payload: DocumentType[]}
    | {type: 'getProvincias', payload: []}
    | {type: 'getDepartamentos', payload: []}
    | {type: 'getLocalidades', payload: []}
    | {type: 'loadingDatosDemograficos', payload: boolean}
    | {type: 'openConfirm', payload: boolean}
    | {type: 'clearValues', payload: DentalRecordsItems}
    | {type: 'clearFilter'}
    | {type: 'pushTreatmentsTable', payload: Treatments}
    | {type: 'pushProviders', payload: ProviderDetail}
    | {type: 'cleanProviders', payload: []}
    | {type: 'filtered', payload: boolean}
    | {type: 'changeUbicacion', payload: {name: string, value: string}}
    | {type: 'handleChangeInput', payload: {name: string, value: string}}
    | {type: 'handleChangeInputAdd', payload: {name: string, value: string}}
    | {type: 'handleChangeSelect', payload: {name: string, value: string | number | number[] | string[]}}
    | {type: 'changeDatePicker', payload: {name: string, value: string}}
    | {type: 'setLoading', payload: boolean}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'openAlert', payload: boolean}
    | {type: 'loadingInsurance', payload: boolean}
    | {type: 'getAffiliates', payload: AffiliateData}
    | {type: 'getProfessionals', payload: ProfessionalItems}
    | {type: 'getEnrollment', payload: EnrollmentItems[]}
    | {type: 'setLoadingEnrollmentDetail', payload: boolean}
    | {type: 'getEnrollmentDetail', payload: EnrollmentDetailItems}
    | {type: 'getProviderTypes', payload: ProviderTypes[]}
    | {type: 'removeTreatments', payload: number}
    | {type: 'affiliateNotFound', payload: boolean}
    | {type: 'getUserCircles', payload: UserCircles[]}
    | {type: 'setLoadingModal', payload: boolean}
    | {type: 'setProviders', payload: ProviderState[]}
    | {type: 'handleClickCheck', payload: boolean}
    | {type: 'handleClickCheckHealthyMouth', payload: boolean}
    | {type: 'getPlanCodes', payload: Plans[]}
    | {type: 'getIvaTypes', payload: IvaTypes[]}
    | {type: 'getProviderByAffiliateCode', payload: DentalRecords[]} 
    | {type: 'customGetDentalRecordsById', payload: Providers[] } 
    | {type: 'changeStateLoadProvider', payload:boolean }
    | {type: 'setLoadingAffiliateCode', payload: boolean}

export default function DentalRecordsReducer (state: DentalRecordsState, action: Action): DentalRecordsState {
    switch (action.type) {
        case 'getDentalRecords':
            return { 
                ...state, 
                data: action.payload
            }
        case 'getDentalRecordsById':
            state.items = {...state.items, ...action.payload };
            state.affiliateData = {...action?.payload?.patientDetail , documentType:action.payload?.patientDetail?.documentTypeId} as any;
            state.items.professionalDetail.enrollmentType = state.items.professionalDetail.isNationalEnrollment ? 'national' : 'provincial';
            state.items.treatments = action?.payload?.treatments?.map((treatment:any) => ({...treatment , code:treatment?.codeId}))
           state.items.loadProvider = true;
            return { 
                ...state,

            }
        case 'getDentalDetail':
            state.dentalDetail = action.payload
            return { 
                ...state
            }
        case 'getDentalRecordStates':
            return { 
                ...state,
                dentalRecordStates: action.payload
            }
        case 'getInsurances':
            return {
                ...state,
                dataInsurance: action.payload,
                loadingInsurance: false
            }
        case 'getInsuranceStatus':
            return {
                ...state,
                statusList: action.payload
            }
        case 'getCirles':
            return {
                ...state,
                circleList: action.payload
            }
        case 'getSexTypes':
            return {
                ...state,
                sexTypesList: action.payload
            }
        case 'getDocumentTypes':
            return {
                ...state,
                documentTypesList: action.payload
            }
        case 'getProvincias':
            return { 
                ...state, 
                provincias: action.payload
            }
        case 'getDepartamentos':
            return { 
                ...state, 
                departamentos: action.payload
            }
        case 'getLocalidades':
            return { 
                ...state, 
                localidades: action.payload,
                loadingDatosDemograficos: false
            }
        case 'getAffiliates':
            return { 
                ...state, 
                affiliateData: action.payload,
                // loading: false
            }
        case 'getProfessionals':
            state.professionalItems = action.payload
            if(!state.professionalItems.nationalEnrollment && state.professionalItems.provincialEnrollment){
                state.professionalItems.provincialEnrollment = action.payload.provincialEnrollment
                state.items.professionalDetail.isNationalEnrollment = false
            }
            return { 
                ...state, 
                professionalItems: action.payload,
                // loading: false
            }
        case 'getEnrollment':
            const activeEnrollment = action.payload.filter(item => item.status === 4)
            const lastEnrollment = activeEnrollment[activeEnrollment.length - 1]
            if(activeEnrollment.length){
                state.items.professionalDetail.professionalName = lastEnrollment.name
                state.items.professionalDetail.professionalLastname = lastEnrollment.lastName
                state.items.professionalDetail.circleId = lastEnrollment.circleId
            }
            return { 
                ...state, 
                enrollmentData: activeEnrollment,
                loadingModal: false
            }
        case 'getProviderTypes':
            return { 
                ...state,
                listProviderTypes: action.payload
            }
        case 'loadingDatosDemograficos':
            return { 
                ...state,
                loadingDatosDemograficos: action.payload
            }
        case 'changeUbicacion':
            state.items.patientDetail[action.payload.name] = action.payload.value
            state.affiliateData[action.payload.name] = action.payload.value
            return { 
                ...state
            }
        case 'openConfirm':
            return { 
                ...state,
                openConfirm: action.payload
            }
        case 'clearFilter':
            return { 
                ...state,
                insuranceId: 0,
                professionalEnrollment: '',
                status: 0,
                affiliateFilter: ''
            }
        case 'filtered':
            return { 
                ...state,
                filtered: action.payload
            }
        case 'handleChangeInput':
            state.items.patientDetail[action.payload.name] = action.payload.value
            if(action.payload.name === 'observers'){
                state.items.observers = action.payload.value
            }
            if(action.payload.name === 'enrollment'){
                state.items.professionalDetail.enrollment = action.payload.value
            }
            if(action.payload.name === 'streetNumber'){
                state.items.patientDetail.streetNumber = action.payload.value.replaceAll('-', '')
            }
            if(action.payload.name === 'postalCode'){
              
                state.items.patientDetail.postalCode = action.payload.value.replaceAll('-', '')
            }
            if(action.payload.name === 'bond'){
                state.items.bond = action.payload.value
            }
            if(action.payload.name === 'token'){
                state.items.token = action.payload.value
            }
            return {
                ...state,
            }
        case 'handleChangeInputAdd':
            state.affiliateData[action.payload.name] = action.payload.value
            if(action.payload.name === 'observers'){
                state.items.observers = action.payload.value
            }
            if(action.payload.name === 'enrollment'){
                state.items.professionalDetail.enrollment = action.payload.value
            }
            if(action.payload.name === 'code'){
                state.items.treatmentsItems.code = Number(action.payload.value)
            }
            if(action.payload.name === 'amountPieces'){
                state.items.amountPieces = Number(action.payload.value)
            }
        
            if(action.payload.name === 'professionalName'){
                state.items.professionalDetail.professionalName = action.payload.value
            }
            if(action.payload.name === 'professionalLastname'){
                state.items.professionalDetail.professionalLastname = action.payload.value
            }
            if(action.payload.name === 'age'){
                state.providerState = []                
            }
            if(action.payload.name === 'streetNumber'){
                state.affiliateData.streetNumber = action.payload.value.replaceAll('-', '')
            }
            if(action.payload.name === 'postalCode'){
                state.affiliateData.postalCode = action.payload.value.replaceAll('-', '')
            }

            return {
                ...state
            }
        case 'handleChangeSelect':
            state.items.patientDetail[action.payload.name] = action.payload.value as any
            if(action.payload.name === "documentTypeId") state.affiliateData.documentType = action.payload.value as any
            if(action.payload.name === 'enrollmentType'){
                state.items.professionalDetail.enrollmentType = typeof action.payload.value === 'string' ? action.payload.value : ''
            }
            if(action.payload.name === 'pieceId'){
                state.items.treatmentsItems.pieceId = Number(action.payload.value)
                const faces= state?.dentalDetail.pieces.find(({piecesId}) => piecesId == Number(action.payload.value) )?.faces ;
                state.dentalDetail.faces = faces
            }
            if(action.payload.name === 'faceId'){
                state.items.treatmentsItems.faceId = action.payload.value as any ;
            }
            if(action.payload.name === 'code'){
                state.items.treatmentsItems.code = Number(action.payload.value)
            }
            if(action.payload.name === 'enrollmentTypeAdd'){
                state.items.professionalDetail.enrollmentType = typeof action.payload.value === 'string' ? action.payload.value : ''
                const enrollmentTypeSplit = state.items.professionalDetail.enrollmentType.split('-')
                state.items.professionalDetail.isNationalEnrollment = enrollmentTypeSplit[0] === 'national'
                state.items.professionalDetail.enrollment = enrollmentTypeSplit[1]
            }
            if(action.payload.name === 'sexTypeId'){
                state.affiliateData.sexTypeId = Number(action.payload.value)
            }
            if(action.payload.name === 'ivaTypeId'){
                state.affiliateData.ivaTypeId = Number(action.payload.value)
            }

            return {
                ...state
            }
        case 'changeDatePicker':
            if(action.payload.name === 'bornDateEdit'){
                state.items.patientDetail.bornDate = action.payload.value
            } else {
                state.affiliateData.bornDate = action.payload.value
            }
            if(action.payload.name === 'createDate'){
                state.items.createDate = action.payload.value
            }
            return {
                ...state
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'handleChangeFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value
            }
        case 'openAlert':
            return { 
                ...state,
                openAlert: action.payload
            }
        case 'loadingInsurance':
            return { 
                ...state,
                loadingInsurance: action.payload
            }
        case 'pushTreatmentsTable':
            // agrego datos de tratamiento en un nuevo array y luego limpio los datos cargados
            const pieceNumber = state?.dentalDetail?.pieces.find(item => Number(item.piecesId) === Number(state.items.treatmentsItems.pieceId))?.pieceNumber ?? -1;
            const faces = state.items.treatmentsItems.faceId;
            if(Array.isArray(faces)) {
                faces.forEach((face) =>{
                    state.items.treatmentsItems.treatmentsId = state.items.treatments.length + 1
                    if(state.items.treatmentsItems.faceId && state.items.treatmentsItems.pieceId && state.items.treatmentsItems.code){
                        const description= state?.dentalDetail?.faces?.find(({facesId}) =>Number(facesId) == Number(face))?.name ?? ''
                        state.items.treatments.push({...state.items.treatmentsItems , faceName: description, face: Number(face) , pieceNumber })
                    
                    }
                })
            }
            else{
                state.items.treatmentsItems.treatmentsId = state.items.treatments.length + 1
                    if(state.items.treatmentsItems.faceId && state.items.treatmentsItems.pieceId && state.items.treatmentsItems.code){
                        const description= state?.dentalDetail?.faces?.find(({facesId}) =>Number(facesId) == Number(state.items.treatmentsItems.faceId))?.name ?? ''
                        state.items.treatments.push({...state.items.treatmentsItems , faceName: description, face: Number(state.items.treatmentsItems.faceId) , pieceNumber })
                        state.items.treatmentsItems = action.payload
                    }
            }
            state.items.treatmentsItems = action.payload
            state.dentalDetail.faces = []
            return { 
                ...state
            }
        case 'removeTreatments':
            const treatmentsFiltered = state.items.treatments.filter(treatment => treatment.treatmentsId !== action.payload)
            state.items.treatments = treatmentsFiltered
            return { 
                ...state
            }
        case 'pushProviders':
            // si viene string significa que viene el valor "limpiar"
            const clearValue = typeof action.payload.providerType === 'string'
            if(clearValue){
                // saco del array el valor que corresponde a la pieza eliminada
                const providerDelete = state.providerDetail.filter(provider => provider.pieceId !== action.payload.pieceId)
                state.providerDetail = providerDelete;
            } else {
                if(Object.keys( action?.payload?.faces)?.length > 0 ){
                state.providerDetail.push({pieceId: action.payload.pieceId, providerType: action.payload.providerType, description: action.payload.description , faces:action?.payload?.faces, required:action?.payload?.required,providersId: action?.payload?.providersId ?? null })
                }else{
                state.providerDetail.push({pieceId: action.payload.pieceId, providerType: action.payload.providerType, description: action.payload.description, required:action?.payload?.required,providersId: action?.payload?.providersId ?? null })
                }
            }
            return { 
                ...state
            }
        case 'cleanProviders':
            
             state.providerDetail = action.payload
            return { 
                ...state
            }
        case 'affiliateNotFound':
            return { 
                ...state,
                affiliateNotFound: action.payload
            }
        case 'getUserCircles':
            const circleUsing = action.payload.filter(item => item.using === true)
            if(circleUsing.length){
                state.userCircleSelected = circleUsing[0].circleId
            }
            return { 
                ...state, 
                userCircles: action.payload,
                loadingModal: false
            }
        case 'setLoadingModal':
            return { 
                ...state, 
                loadingModal: action.payload
            }
        case 'setProviders':
            return { 
                ...state, 
                providerState: action.payload
            }
        case 'handleClickCheck':
            state.items.patientDetail.streetNumber = action.payload ? 'S/N' : ''
            state.affiliateData.streetNumber = action.payload ? 'S/N' : ''
            return {
                ...state
            }

        case 'handleClickCheckHealthyMouth':
            state.items.healthyMouth = action.payload
            return {
                ...state
            }
        case 'setLoadingEnrollmentDetail':
            return { 
                ...state, 
                loadingEnrollmentDetail: action.payload
            }
        case 'getEnrollmentDetail':
            return { 
                ...state, 
                enrollmentDetail: action.payload,
                loadingEnrollmentDetail: false
            }
        case 'getPlanCodes':
            const codesIds = action.payload.map(plan => plan.codes.map(code => ({'codesId': code.codesId, 'description': code.description, 'codeTreatment': code.code , "textCode" : code?.textCode}))).flat();
            return { 
                ...state, 
                plans: codesIds,
                loading: false
            }
        case 'getIvaTypes':
            return { 
                ...state, 
                ivaTypesData: action.payload
            }

        case 'getProviderByAffiliateCode':
        let providerByAffiliateCode =Array.isArray(action.payload) ? action.payload.sort((a,b) => b?.dentalRecordsId - a?.dentalRecordsId) :[];
            if(providerByAffiliateCode?.length >1){
            let lastItem = providerByAffiliateCode.pop();
            providerByAffiliateCode.unshift({...lastItem, date:"Primer Odontograma"} as any);
            }
    
            return { 
                ...state, 
                providerByAffiliateCode:providerByAffiliateCode,
            }

        case 'setLoadingAffiliateCode':
            return { 
                ...state, 
               loadingAffiliateCode: action.payload
            }
            
        case 'customGetDentalRecordsById':
            const providers = action.payload.map((provider) => { 
            if (Array.isArray(provider?.faces)){
                return {
                    ...provider,
                    required: false,
                    faces:provider?.faces?.map((face) =>({...face})),
            
                }
            }     
            return { ...provider, required: false }
            })     
            state.items.providers = providers;
            state.items.loadProvider = true;
            return { 
                ...state
            }

        case 'changeStateLoadProvider':
            state.items.loadProvider = action.payload
            return { 
                ...state
            }

        case 'changeStateLoadProvider':
            state.items.loadProvider = action.payload
            return { 
                ...state
        }
        default:
            return state;
    }
}