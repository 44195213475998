import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination, Box, Grid, TextField, Button, MenuItem } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom'
import Pagination from '../Pagination';
import InsuranceContext from '../context/Insurances/InsuranceContext';
import Divider from '@mui/material/Divider';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import Loader from '../Loader';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import Container from '../Container';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import GeneralRuleModal from '../GeneralRuleModal';

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3B72FF',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Index = () => {

    const {
        state, 
        getInsurances,
        getInsuranceFilter,
        getInsuranceStatus,
        handleChangeFilter,
        clearFilter,
    } = useContext(InsuranceContext)

    useEffect(() => {
        getInsurances()
        getInsuranceStatus()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openGeneralRuleModal, setOpenGeneralRuleModal] = useState(false)
    const [generalRules, setGeneralRules] = useState({particularRegulations: '', arancelNomenclature: '', generalInformation: ''})

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    

    const handleOpenGeneralRuleModal = (insuranceId: number) => {
        setOpenGeneralRuleModal(true)
        const insuranceSelected = state.data.filter(d => d.insurancesId === insuranceId)[0]
        setGeneralRules({particularRegulations: insuranceSelected.particularRegulations, arancelNomenclature: insuranceSelected.arancelNomenclature, generalInformation: insuranceSelected.generalInformation })
    }

    const handleCloseGeneralRuleModal = () => {
        setOpenGeneralRuleModal(false)
    }

    if(state.loadingInsurance){
        return <Loader />
    }

    const FilterDiabled = state.nameFilter.length >= 3 || state.insuranceStateId

    return (
        <Container 
            child={
                <Box>
                    <h4>Obras Sociales</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />

                    <Grid container justifyContent='space-between' alignItems='center' spacing={3} mb={3}>
                        <Grid xs={6} sm={4} md={5} item>
                            <TextField fullWidth value={state.nameFilter} name='nameFilter' label='Nombre' onChange={(e) => handleChangeFilter(e)} />
                        </Grid>
                        <Grid xs={6} sm={4} md={5} item>
                            <TextField 
                                select 
                                fullWidth 
                                value={state.insuranceStateId ? state.insuranceStateId : ''} 
                                name='insuranceStateId' 
                                label='Estado' 
                                onChange={(e) => handleChangeFilter(e)} 
                            >
                                {state.statusList.map(item => {
                                    return <MenuItem key={item.insuranceStateId} value={item.insuranceStateId}>
                                        {item.description}
                                    </MenuItem>
                                })}
                            </TextField>
                        </Grid>
                        <Grid xs={4} sm={3} md={2} item>
                            <Button variant="outlined" size={state.nameFilter || state.insuranceStateId ? 'small' : 'large'} fullWidth startIcon={<FilterAltIcon />} onClick={() => {getInsuranceFilter(); setPage(0)}} disabled={!FilterDiabled}>Filtrar</Button>
                            {state.nameFilter || state.insuranceStateId ? 
                                <Button variant="outlined" size='small' fullWidth color='error' startIcon={<FilterAltOffIcon />} onClick={() => {clearFilter(); setPage(0)}}>Limpiar</Button>
                            : null}
                        </Grid>
                    </Grid>

                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Código</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Nombre</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Datos Prestacionales</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Estado</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Detalle</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 
                                    ? state.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : state.data
                                ).map((row) => {
                                    const particularRulesDisabled = !row.particularRegulations && !row.arancelNomenclature && !row.generalInformation
                                    const statusDescription = state.statusList.map((status) => {
                                        if(row.insuranceStateId === status.insuranceStateId){
                                            return status.description
                                        }
                                    })

                                    return <StyledTableRow
                                        key={row.insurancesId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell sx={{padding: '12px'}} component="th" scope="row" align="left">
                                            {row.idFopbaInsider}
                                        </StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.name}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '5px'}} align="center">
                                        <LibraryBooksIcon 
                                            onClick={particularRulesDisabled ? () => {} : () => handleOpenGeneralRuleModal(row.insurancesId)} 
                                            style={particularRulesDisabled ? {color: 'gray', textDecoration: 'none', width: '100%'} : {color: '#3C78F8', textDecoration: 'none', width: '100%', cursor: 'pointer'}} 
                                        />
                                        </StyledTableCell>
                                        <StyledTableCell sx={{padding: '5px'}} align="center">{statusDescription}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '5px'}} align="center">
                                            <Link to={`/detalle-de-obra-social/${row.insurancesId}`} style={{color: '#3C78F8', textDecoration: 'none', width: '100%'}}>
                                                <RemoveRedEyeOutlinedIcon />
                                            </Link>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                        colSpan={3}
                                        count={state.data.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Filas por pagina',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage='Filas por Página'
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => <Pagination count={state.data.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    <GeneralRuleModal 
                        handleCloseGeneralRuleModal={handleCloseGeneralRuleModal}
                        openGeneralRuleModal={openGeneralRuleModal}
                        particularRegulations={generalRules.particularRegulations}
                        arancelNomenclature={generalRules.arancelNomenclature}
                        generalInformation={generalRules.generalInformation}
                    />
                </Box>
            } 
        />
    )
}

export default Index