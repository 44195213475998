import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext"
import { useContext, useEffect } from "react"
import PaperContainer from '../PaperContainer';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Container from '../Container';
import { EnrollmentDetailItems } from '../../interfaces/Empadronamiento';

const TextFieldStyled = styled(TextField)(() => ({
    '.MuiInputLabel-root': {
        'span': {
            color: 'red'
        }
    }
}));

interface Props {
    section: string
    propData?: EnrollmentDetailItems
}

const Step1 = (props: Props) => {

    const { state, handleChangeSelectCircle, getUserCircles, handleNextStep } = useContext(EmpadronamientoContext)
    
    useEffect(() => {
        getUserCircles()
    }, [])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        handleNextStep()
    }

    const findCircleName = state.userCircles.find(circle => circle.circleId === props.propData?.circleId)?.name

    const content = <Box textAlign='center' mb={6} mt={2} pt={2} pr={7} pl={7} >
        <Box sx={{width: '60%', marginLeft: '20%', marginTop: 6}}>
            <Typography variant='h5' sx={{fontWeight: 700}}>Círculo/s a los que perteneces</Typography>
        </Box>

        <Box 
            component='form' 
            onSubmit={handleSubmit}
            className='formContainer'
            sx={{width: '90%', marginLeft: '5%', marginTop: 5}}
        >
            <TextFieldStyled
                fullWidth
                required
                disabled={!!findCircleName}
                select
                label="Círculos "
                value={findCircleName && !state.circleName ? findCircleName : state.circleName}
                onChange={(event) => handleChangeSelectCircle(event)}
            >
                {state.userCircles.map(circle => (
                    <MenuItem value={circle.name} key={circle.circleId}>{circle.idFopbaInsider} - {circle.name}</MenuItem>
                ))}
            </TextFieldStyled>

            <Stepper nonLinear activeStep={state.activeStep} sx={{marginTop: 5}}>
                {state.steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Button 
                type='submit' 
                variant='contained' 
                sx={{
                    background: '#42C5B7',
                    '&:hover': {background: '#389b90'},
                    borderRadius: 30,
                    padding: '5px 40px 5px 40px',
                    margin: '65px 0px 30px 0px',
                }}
            >
                Siguiente
            </Button> 
        </Box>
    </Box>

    return (
        // esto se hace ya que se reutiliza el componente y para el empadronamiento se muestra como modal y para el reempadronamiento no
        props.section === 'empadronamiento' ? <PaperContainer>{content}</PaperContainer> : <Container child={content}/>
    )
}

export default Step1