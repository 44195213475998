import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext"
import { useContext, useEffect, useRef, useState } from "react"
import PaperContainer from '../PaperContainer';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Paper } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Confirm from '../Confirm';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';
import moment from 'moment';
import Container from '../Container';
import { EnrollmentDetailItems } from '../../interfaces/Empadronamiento';

const TextFieldStyled = styled(TextField)(() => ({
    '.MuiInputLabel-root': {
        'span': {
            color: 'red'
        }
    }
}));

const TypographyStyled = styled(Typography)(() => ({
    color: '#3B72FF',
    fontWeight: 'bolder',
    marginRight: 8
}));

interface Props {
    section: string
    propData?: EnrollmentDetailItems
}

const Step5 = (props: Props) => {
    const [openConfirm, handleOpenConfirm] = useState(false)
    const [idToDelete, LoadIdToDelete] = useState(0)

    const inputRef = useRef<HTMLInputElement>(null);

    const { state, handlePrevStep, getMainSpeciality, handleChangeSpeciality, handleChangeYearPicker, handleChangeFileSpeciality, handleChangeView, clearSpecialityValues, removeSpeciality } = useContext(EmpadronamientoContext)

    useEffect(() => {
        getMainSpeciality()
    }, [])

    const handleClick = () => {
        if(inputRef.current){
            inputRef.current.click()
        }
    }

    const file = state.specialityItems.certifyFile ? state.specialityItems.certifyFile[0] : ''
    // const antiquity = moment().diff(state.specialityItems.awardedYear, 'year')
    const addNewConsultorio = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        clearSpecialityValues()
    }

    const handleConfirmDelete = () => {
        removeSpeciality(idToDelete)
        handleOpenConfirm(false)
    }

    let datosObligatoriosCargados = false
    if(state.specialityItems.specialityId && state.specialityItems.awardedBy && state.specialityItems.awardedYear && state.specialityItems.isExclusive){
        datosObligatoriosCargados = true
    }

    const nextStep = () => {
        if(datosObligatoriosCargados){
            clearSpecialityValues()
        }
        handleChangeView('summary')
    }
    
    const insurancesChecked = state.insuranceData.filter(item => item.generalCoverages.some(gc => gc.status === true))

    const specialityIds = state.specialityLoad.map(item => item.specialityId);
    // saco del array de especialidad la especialidades ya agregadas para que no se pueda volver a elegir la misma opcion
    const optionFiltered = state.mainSpecialityData.filter(item => !specialityIds.includes(item.specialityId))

    const content = <>
        <Box textAlign='center' mb={6} mt={2} pt={2} pr={1} pl={1}>
            <Box sx={{width: '60%', marginLeft: '20%', marginTop: 6}}>
                <Typography variant='h5' sx={{fontWeight: 700}}>Tu/s Especialidad/Orientaciones</Typography>
            </Box>

            <Box 
                component='form' 
                onSubmit={addNewConsultorio}
                className='formContainer'
                sx={{width: '90%', marginLeft: '5%', marginTop: 5}}
            >
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            fullWidth
                            required
                            select
                            label="Especialidad"
                            name='specialityId'
                            value={state.specialityItems.specialityId ? state.specialityItems.specialityId : ''}
                            onChange={handleChangeSpeciality}
                        >
                            {optionFiltered.filter(({generalCoverageId}) => 
                                insurancesChecked.some(ch => ch.generalCoverages.some(c => c.status && c.generalCoveragesId === generalCoverageId))
                            ).sort(((a, b) => a.idFopbaInsider - b.idFopbaInsider)).map(speciality => (
                                <MenuItem value={speciality.specialityId} key={speciality.specialityId}>{speciality.idFopbaInsider} - {speciality.description}</MenuItem>
                            ))}
                        </TextFieldStyled>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            label="Institución"
                            name='awardedBy'
                            variant="outlined"
                            fullWidth
                            value={state.specialityItems.awardedBy ? state.specialityItems.awardedBy : ''}
                            onChange={handleChangeSpeciality}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Año de Finalización"
                                value={state.specialityItems.awardedYear ? state.specialityItems.awardedYear : null}
                                onChange={(newValue) => handleChangeYearPicker(moment(newValue).format('YYYY'), 'awardedYear')}
                                renderInput={(params) => <TextFieldStyled {...params} variant='outlined' fullWidth />}
                                views={["year"]}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            fullWidth
                            required
                            select
                            label="¿Especialidad Exclusiva?"
                            value={state.specialityItems.isExclusive}
                            onChange={handleChangeSpeciality}
                            name='isExclusive'
                        >
                            <MenuItem value='true'>Si</MenuItem>
                            <MenuItem value='false'>No</MenuItem>
                        </TextFieldStyled>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <input type="file" hidden name='certifyFile' ref={inputRef} onChange={handleChangeFileSpeciality} />
                        <div style={{display: 'flex', marginTop: 16, cursor: 'pointer'}}>
                            <AttachFileIcon onClick={handleClick} color='primary' />
                            <Typography onClick={handleClick} color='primary'>Adjuntar Documentos</Typography>
                        </div>
                        <Typography>{file && typeof file !== 'string' ? file.name : null}</Typography>
                    </Grid>
                </Grid>

                <Box sx={{width: '100%', display: 'flex', paddingTop: '20px'}}>
                    <Button type='submit' sx={{textTransform: 'inherit'}}>
                        <AddCircleIcon />
                        <Typography sx={{marginLeft: 1}}>Agregar Especialidad</Typography>
                    </Button>
                </Box>

                {state.specialityLoad.length ?
                    state.specialityLoad.map(speciality => {
                        const specialitySelected = state.mainSpecialityData.find(mainSpeciality => mainSpeciality.specialityId === speciality.specialityId)
                        return <Paper key={speciality.specialityId} sx={{mt: 3, width: '100%'}} elevation={3}>
                            <Grid container spacing={3} padding={2} position={'relative'}>
                                <Grid item container xs={6} sm={6} md={4}>
                                    <TypographyStyled>Especialidad principal:</TypographyStyled>{specialitySelected?.idFopbaInsider} - {specialitySelected?.description}
                                </Grid>
                                <Grid item container xs={6} sm={6} md={4}>
                                    <TypographyStyled>Institución: </TypographyStyled>{speciality.awardedBy ? speciality.awardedBy : '-'}
                                </Grid>
                                <Grid item container xs={12} sm={12} md={4}>
                                    <TypographyStyled>Año de Finalización: </TypographyStyled>{speciality.awardedYear}
                                </Grid>
                                <Grid item container xs={6} sm={6} md={6}>
                                    <TypographyStyled>¿Especialidad exclusiva?:</TypographyStyled>{speciality.isExclusive === 'true' ? 'Si' : 'No'}
                                </Grid>
                                <DeleteIcon 
                                    sx={{position: 'absolute', bottom: 10, right: 10, color: '#e33d3d', cursor: 'pointer'}} 
                                    onClick={() => {handleOpenConfirm(true); LoadIdToDelete(speciality.specialityId ? speciality.specialityId : 0)}}
                                />
                            </Grid>
                        </Paper>
                    })
                : null}

                <Stepper nonLinear activeStep={state.activeStep} sx={{marginTop: 5}}>
                    {state.steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Grid container justifyContent='center' spacing={2}>
                    <Grid item>
                        <Button 
                            type='button' 
                            variant='contained' 
                            sx={{
                                background: '#42C5B7',
                                '&:hover': {background: '#389b90'},
                                borderRadius: 30,
                                padding: '5px 40px 5px 40px',
                                margin: '45px 0px 30px 0px',
                            }}
                            onClick={handlePrevStep}
                        >
                            Volver
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button 
                            type={datosObligatoriosCargados || state.specialityLoad.length ? 'button' : 'submit'}
                            variant='contained' 
                            sx={{
                                background: '#42C5B7',
                                '&:hover': {background: '#389b90'},
                                borderRadius: 30,
                                padding: '5px 40px 5px 40px',
                                margin: '45px 0px 30px 0px',
                            }}
                            onClick={datosObligatoriosCargados || state.specialityLoad.length ? nextStep : () => {}}
                        >
                            Siguiente
                        </Button> 
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Confirm
            openConfirm={openConfirm}
            handleCloseConfirm={() => handleOpenConfirm(false)}
            handleConfirm={() => handleConfirmDelete()}
            title='¿Estas seguro?'
            description='Si eliminas esta especialidad no podras volver a recuperarla luego'
        />
    </>

    return (
        props.section === 'empadronamiento' ? <PaperContainer>{content}</PaperContainer> : <Container child={content}/>
    )
}

export default Step5