import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddBoxIcon from '@mui/icons-material/AddBox';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';

const ListItemButtonStyled = styled(ListItemButton)(() => ({
    background: '#fbfbfb',
    margin: '2px 10px',
    border: '1px solid #dfdfdf',
    borderRadius: '8px',
    alignItems: 'flex-start', 
    flexGrow: 0
}))

const DialogStyled = styled(Dialog)(() => ({
    '.MuiDialog-paper': {
        maxWidth: '70%',
        width: '70%'
    }
}))

interface Props {
    openGeneralRuleModal: boolean
    particularRegulations: string
    arancelNomenclature: string
    generalInformation: string
    handleCloseGeneralRuleModal: () => void
}

export default function GeneralRuleModal({openGeneralRuleModal, particularRegulations, arancelNomenclature, generalInformation, handleCloseGeneralRuleModal} : Props) {

    const [openOptionOne, setOpenOptionOne] = useState(false)
    const [openOptionTwo, setOpenOptionTwo] = useState(false)
    const [openOptionThree, setOpenOptionThree] = useState(false)

    const closeModal = () => {
        handleCloseGeneralRuleModal()
        setOpenOptionOne(false)
        setOpenOptionTwo(false)
    }

    return (
        <div>
            <DialogStyled
                open={openGeneralRuleModal}
                onClose={closeModal}
            >
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle mb={4}>Datos prestacionales</DialogTitle>
                {/* Normas Particulares */}
                <ListItemButtonStyled onClick={() => setOpenOptionOne(!openOptionOne)}>
                    <ListItemIcon>
                        <AddBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Normas Particulares" />
                    {openOptionOne ? <ExpandLess /> : <ExpandMore />}
                </ListItemButtonStyled>
                <Collapse in={openOptionOne} timeout="auto" unmountOnExit sx={{minHeight: 'auto!important'}}>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <div dangerouslySetInnerHTML={{__html: particularRegulations}}></div>
                        </ListItemButton>
                    </List>
                </Collapse>
                {/* Nomenclador y Arancel */}
                <ListItemButtonStyled onClick={() => setOpenOptionTwo(!openOptionTwo)}>
                    <ListItemIcon>
                        <AddBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Nomenclador y Arancel" />
                    {openOptionTwo ? <ExpandLess /> : <ExpandMore />}
                </ListItemButtonStyled>
                <Collapse in={openOptionTwo} timeout="auto" unmountOnExit sx={{minHeight: 'auto!important'}}>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <div dangerouslySetInnerHTML={{__html: arancelNomenclature}}></div>
                        </ListItemButton>
                    </List>
                </Collapse>
                {/* Información general*/}
                <ListItemButtonStyled onClick={() => setOpenOptionThree(!openOptionThree)}>
                    <ListItemIcon>
                        <AddBoxIcon />
                    </ListItemIcon>
                    <ListItemText primary="Información General" />
                    {openOptionThree ? <ExpandLess /> : <ExpandMore />}
                </ListItemButtonStyled>
                <Collapse in={openOptionThree} timeout="auto" unmountOnExit sx={{minHeight: 'auto!important'}}>
                    <List component="div" disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <div dangerouslySetInnerHTML={{__html: generalInformation}}></div>
                        </ListItemButton>
                    </List>
                </Collapse>
            </DialogStyled>
        </div>
    );
}