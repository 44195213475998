import { createContext } from 'react'
import { ContactState } from '../../../interfaces/Contact';

export interface ContextProps {
    state: ContactState
    getContact: () => void
}

const ContactContext = createContext<ContextProps>({} as ContextProps);

export default ContactContext;