import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { TextField, Grid, MenuItem, Typography, FormControl, Select, InputLabel, SelectChangeEvent } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, useContext } from 'react';
import { DentalRecordsState } from '../../interfaces/DentalRecords';
import { styled } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import DentalRecordsContext from '../context/DentalRecords/DentalRecordsContext';

const TextFieldStyled = styled(TextField)(() => ({
    '.MuiInputLabel-root': {
        color: '#3B72FF',
        'span': {
            color: 'red'
        }
    }
}));

const InputLabelStyled = styled(InputLabel)(() => ({
    color: '#3B72FF',
    'span': {
        color: 'red'
    }
}));

interface Props {
    openModal: boolean
    hasActiveEnrollment: boolean
    state: DentalRecordsState
    getEnrollmentDetail: (enrollmentId: number) => void
    handleCloseModal: () => void
    getAffiliates: (code: string, insurancesIdFopbaInsider: number ,insuranceId: number) => void
}


export default function AffiliateDataModal({ openModal, hasActiveEnrollment, state, handleCloseModal, getAffiliates, getEnrollmentDetail }:Props) {

    const [code, setCode] = useState<string>('')
    const [insuranceId, setInsuranceId] = useState<number>(0)
    const [errorMessage, setErrorMessage] = useState<boolean>(false)

    const { handleChangeDatePicker, handleChangeInput } = useContext(DentalRecordsContext)

    const enrollment = state.enrollmentData.filter(enrollment => enrollment.circleId === state.userCircleSelected)
    useEffect(() => {
        setInsuranceId(0)
        setCode('')
        if(enrollment.length){
            getEnrollmentDetail(enrollment[enrollment.length - 1].registrationsId)
        }
    }, [])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCode(event.target.value)
    }

    const handleSelectChange = (event: SelectChangeEvent<number>) => {
        setInsuranceId(typeof event.target.value === 'number' ? event.target.value : 0)
    }

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        const createDate: Date = new Date(state.items.createDate)
        const currentDate: Date = new Date(moment().format('YYYY-MM-DD'))
        const diffSeconds: number = createDate.getTime() - currentDate.getTime()
        const selectedInsurance = insurancesToShow.find((insurance) => insurance.insurancesId == insuranceId)

        //Obtengo la diferencia en días entre la fecha de creación y la actual
        const diffDays: number = Math.floor(diffSeconds / (1000 * 60 * 60 * 24))
        
        if(diffDays > 30 || diffDays < -60) {
            setErrorMessage(true)
        } else {
            getAffiliates(code, Number(selectedInsurance?.idFopbaInsider), Number(selectedInsurance?.insurancesId))
        }
    }

    const insurancesIdSelectedInEnrollment = state.enrollmentDetail.insurancesResponse.map(item => item.insurancesId);
    const insurancesToShow = state.dataInsurance.filter(item => insurancesIdSelectedInEnrollment.includes(item.insurancesId))

    return (
        <Dialog
            open={openModal}
            onClose={handleCloseModal}
        >
            <IconButton
                aria-label="close"
                onClick={handleCloseModal}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            {!hasActiveEnrollment ? 

                <>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 4}} mt={6}>
                        <Typography color='error' textAlign='center'>
                            No tienes empadronamientos activos para el circulo seleccionado, por lo que no podrás avanzar con el alta de ficha.
                        </Typography>
                    </Box>
                    <DialogActions sx={{flexDirection: 'column'}}>
                        <Grid container spacing={1} justifyContent='center'>
                            <Button 
                                type='button'
                                onClick={handleCloseModal}
                                variant='contained' 
                                sx={{margin: '20px', justifyContent: 'center', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                            >
                                Cerrar
                            </Button>
                        </Grid>
                    </DialogActions>
                </>
               
            :   

                <>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} mt={6}>
                        <DialogContent>
                            <Typography>
                                Ingrese los datos <b>obligatorios</b> para generar una nueva ficha.
                            </Typography>
                        </DialogContent>
                    </Box>
                    <DialogActions sx={{flexDirection: 'column'}}>
                        <Box component='form' onSubmit={handleSubmit} sx={{width: '90%'}}>
                            <Grid container spacing={1}>
                                <Grid item sm={6} xs={12}>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <DatePicker
                                            label="Fecha de Creación"
                                            value={state.items.createDate}
                                            onChange={(newValue) => handleChangeDatePicker(moment(newValue).format('YYYY-MM-DD'), 'createDate')}
                                            renderInput={(params) => <TextFieldStyled {...params} required variant='outlined' fullWidth name='createDate' />}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                <FormControl fullWidth variant='outlined' required>
                                    <InputLabelStyled>Obra Social</InputLabelStyled>
                                    <Select
                                        label="Obra social"
                                        value={insuranceId ? insuranceId : ''} 
                                        name='insuranceId' 
                                        onChange={(e) => handleSelectChange(e)}
                                        sx={{mb: 3}}
                                    >
                                        {insurancesToShow.map((insurance) => {
                                            return <MenuItem key={insurance.insurancesId} value={insurance.insurancesId}>
                                                {`(${insurance.idFopbaInsider}) - ${insurance.name}`}
                                            </MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <TextFieldStyled 
                                        label="N° de Afiliado"
                                        name='code'
                                        variant="outlined"
                                        value={code}
                                        fullWidth
                                        required
                                        onChange={(e) => handleChange(e)}
                                    />
                                </Grid>
                                <Grid item sm={12} xs={12} sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <DialogContent>
                                        <Typography>
                                            Datos <b>Opcionales</b>.
                                        </Typography>
                                    </DialogContent>
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <TextFieldStyled 
                                        label="N° de Bono"
                                        name='bond'
                                        variant="outlined"
                                        value={state.items.bond}
                                        fullWidth
                                        onChange={handleChangeInput}
                                        inputProps={{ maxLength: 8 }}
                                    />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <TextFieldStyled 
                                        label="Token"
                                        name='token'
                                        variant="outlined"
                                        value={state.items.token}
                                        fullWidth
                                        onChange={handleChangeInput}
                                        inputProps={{ maxLength: 10 }}
                                    />
                                </Grid>

                                {errorMessage ?
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                    <DialogContent>
                                        <Typography sx={{color: 'red'}}>
                                            Error, verifique la fecha de creación de la ficha, no debe ser posterior a 30 días ni anterior a 60.
                                        </Typography>
                                    </DialogContent>
                                </Box>
                                : null}

                            </Grid>
                            <Grid mt={4} container justifyContent='center'>
                                {state.affiliateNotFound ?
                                    <Typography color='error' className="errorMessage" variant="body1">Afiliado no encontrado</Typography>
                                : null}
                            </Grid> 
                            <Grid container spacing={1} justifyContent='center' mt={1}>
                                <Button 
                                    type='submit'
                                    variant='contained' 
                                    sx={{margin: '25px', justifyContent: 'center', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                                >
                                    Siguiente
                                </Button>
                            </Grid>
                        </Box>
                    </DialogActions>
                </>
            }
        </Dialog>
    );
}