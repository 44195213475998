import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#497cff',
            main: '#3B72FF',
            dark: '#2f6aff',
            contrastText: '#fff',
        },
        secondary: {
            light: '#52c3b7',
            main: '#42C5B7',
            dark: '#32c9b9',
            contrastText: '#fff',
        },
    },
});

export default theme