import { useContext, useEffect, useState } from "react"
import { useParams } from 'react-router-dom'
import Container from '../Container'
import { Box, Divider, Grid, Typography, Paper } from '@mui/material'
import { TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Button as MuiButton } from '@mui/material';
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext"
import Loader from "../Loader";
import Button from "../Button";
import Pagination from "../Pagination";
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";

const TypographyStyled = styled(Typography)(() => ({
    color: '#3B72FF',
    fontWeight: 'bolder',
    marginRight: 8
}));

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3B72FF',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Detail = () => {

    const { state, getUserCircles, getEnrollmentDetail, getInsuranceType, getMainSpeciality } = useContext(EmpadronamientoContext)

    const {id} = useParams();

    useEffect(() => {
        getEnrollmentDetail(Number(id))
        getUserCircles()
        getInsuranceType()
        getMainSpeciality()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleDownload = (base64data: string, fileName: string) => {
        const byteCharacters = atob(base64data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.pdf`;
        link.click();
        URL.revokeObjectURL(link.href);
      };

    const consultorios = (street: string, height: number | string, index: number) => {
        const coma = index + 1 !== state.enrollmentDetail.officesResponse.length
        return `${street ? street : ''} ${height ? height : ''} ${!street && !height ? 'Sin dirección' : ''}${coma ? ', ' : ''}`
    }

    const especialidades = (specialityId: number, index: number) => {
        const coma = index + 1 !== state.enrollmentDetail.specialitiesResponse.length
        const specialitySelected = state.mainSpecialityData.find(item => item.specialityId === specialityId)
        return `${specialitySelected?.description}${coma ? ', ' : ''}`
    }

    const secureSelected = state.insuranceTypeData.find(insurance => insurance.insuranceTypeId === state.enrollmentDetail.secureId)
    const specialitySelected = state.mainSpecialityData.find(item => item.specialityId === state.enrollmentDetail.specialitiesResponse[0].specialityId)


    if(state.loadingEnrollmentDetail){
        return <Loader />
    }

    return (
        <Container
            child={
                <Box>
                    <h4>Detalle</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                    <Grid container spacing={3} justifyContent='space-between' mb={5}>
                        {state.enrollmentDetail.name ? 
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Nombre:</TypographyStyled>{state.enrollmentDetail.name}
                        </Grid>
                        : null}
                        {state.enrollmentDetail.lastName ? 
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Apellido:</TypographyStyled>{state.enrollmentDetail.lastName}
                        </Grid>
                        : null}
                        {state.enrollmentDetail.provincialEnrollment ? 
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Matrícula Provincial:</TypographyStyled>{state.enrollmentDetail.provincialEnrollment}
                        </Grid>
                        : null}
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Circulo:</TypographyStyled>{state.userCircles.filter(item => item.circleId === state.enrollmentDetail.circleId).map(circle => circle.idFopbaInsider + ' - ' + circle.name)}
                        </Grid>

                        <Grid item container xs={12} sm={6} md={6}>
                            {state.enrollmentDetail.officesResponse.length > 1 ? <TypographyStyled>Consultorios:</TypographyStyled> : null}
                            {state.enrollmentDetail.officesResponse.length > 1 ?
                                state.enrollmentDetail.officesResponse.map((consultorio, index) => (
                                    consultorios(consultorio.street, consultorio.height, index)
                                ))
                            :
                                <>
                                    <TypographyStyled>Consultorio:</TypographyStyled>
                                    {!state.enrollmentDetail.officesResponse[0].street && !state.enrollmentDetail.officesResponse[0].height ? 'Sin dirección' : state.enrollmentDetail.officesResponse[0].street + ' ' + state.enrollmentDetail.officesResponse[0].height}
                                </>
                            }
                        </Grid>

                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Seguro:</TypographyStyled>{secureSelected?.description}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Fecha de Inicio de Seguro: </TypographyStyled>{state.enrollmentDetail.secureStartDate ? state.enrollmentDetail.secureStartDate : ' - '}
                        </Grid>
                        <Grid item container xs={12} sm={6} md={6}>
                            <TypographyStyled>Fecha de Finalización de Seguro: </TypographyStyled>{state.enrollmentDetail.secureFinishDate ? state.enrollmentDetail.secureFinishDate : ' - '}
                        </Grid>

                        <Grid item container xs={12} sm={6} md={6}>
                        <Grid item container xs={12} sm={6} md={6}>
                            {state.enrollmentDetail.specialitiesResponse.length > 1 ? <TypographyStyled>Especialidades:</TypographyStyled> : null}
                            {state.enrollmentDetail.specialitiesResponse.length > 1 ?
                                state.enrollmentDetail.specialitiesResponse.map((item, index) => (
                                    especialidades(item.specialityId, index)
                                ))
                            :
                                <>
                                    <TypographyStyled>Especialidad:</TypographyStyled>
                                    {specialitySelected?.idFopbaInsider} - {specialitySelected?.description}
                                </>
                            }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item container xs={12} sm={6} md={4} mt={-3} mb={3}>
                        <MuiButton variant="contained" disabled={!state.enrollmentDetail.collectiveFile} onClick={() => handleDownload(state.enrollmentDetail.collectiveFile, 'Póliza de Seguro')}>
                            Descargar Archivo de Seguro
                        </MuiButton>
                    </Grid>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Código</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Obra Social</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Odontología General</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Prótesis</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Ortodoncia</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 
                                    ? state.enrollmentDetail.insurancesResponse.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : state.enrollmentDetail.insurancesResponse
                                ).map((row) => {
                                    return <StyledTableRow
                                        key={row.insurancesId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.idFopbaInsider}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row.name}</StyledTableCell>
                                        {row.generalCoverages.map((coverage, index) => {
                                            return <StyledTableCell key={index} sx={{padding: '12px'}} align="center"><Checkbox checked={coverage.status ? coverage.status : false} disabled={!coverage.status} onChange={() => {}} /></StyledTableCell>
                                        })}
                                    </StyledTableRow>
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                        colSpan={3}
                                        count={state.enrollmentDetail.insurancesResponse.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Filas por pagina',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage='Filas por página'
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => <Pagination count={state.enrollmentDetail.insurancesResponse.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>

                    <Grid container justifyContent='end' mt={4}>
                        <Grid item xs={4} sm={4} md={3}>
                            <Button 
                                child={
                                    <Link style={{textDecoration: 'none', color: '#4e4e4e', width: '100%'}} to={'/usuario/detalle-de-empadronamiento'}>
                                        Volver
                                    </Link>
                                } 
                                variant='outlined' 
                                color='secondary'
                            />
                        </Grid>
                    </Grid>
                </Box>
            }
        />
    )
}

export default Detail