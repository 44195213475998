import { useReducer } from 'react'
import { CircleState } from '../../../interfaces/Circles';
import CircleContext from './CircleContext';
import CircleReducer from './CircleReducer';
import axios from 'axios';

const INITIAL_STATE: CircleState = {
    data: [],
    loading: false,
    userCircles: [],
    userCircleSelected: 0,
    items: {
        circlesId: 0,
        idFopbaInsider: 0,
        cuit: 0,
        email: '',
        name: '',
        circleStateId: 0,
        phone: '',
        cellPhone: '',
        street: '',
        height: 0,
        floor: 0,
        apartment: '',
        postalCode: 0,
        town: '',
        department: '',
        state: '',
        country: '',
        authorityName: '',
        secretaryName: ''
    },
    filterCircleName: '',
    filterCircleId: '',
    filtered: false,
    provincias: [],
    departamentos: [],
    localidades: []
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const CirculoProvider = ({children}: Props) => {

    const [state, dispatch] = useReducer(CircleReducer, INITIAL_STATE)

    const getCirculos = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}circles`, {headers})
        .then(response => {
            dispatch({type: 'getCirculos', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
        
    }

    const getCirculoById = async (id: string | undefined) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}circles/${Number(id)}`, {headers})
        .then(response => {
            dispatch({type: 'getCirculoById', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getUserCircles = async () => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}usersCircles/${userId}`, {headers})
        .then(response => {
            dispatch({type: 'getUserCircles', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const setUserCircle = async (circleId: number) => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            "userId" : userId,
            "circleId " : circleId,
        }

        await axios.patch(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}usersCircles/${userId}/circle/${circleId}`, data, {headers})
        .then(() => {
            localStorage.setItem('circleUserId', `${circleId}`);
            dispatch({type: 'setUserCircle', payload: circleId})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    const getCirclesFilter = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        let params = ''
        if(state.filterCircleName && state.filterCircleId){
            params = `&name=${state.filterCircleName}&status=${state.filterCircleId}`
        } else if(state.filterCircleName){
            params = `&name=${state.filterCircleName}`
        } else if(state.filterCircleId){
            params = `&idFopbaInsider=${state.filterCircleId}`
        }

        const url = `${process.env.REACT_APP_SITIO_DE_USUARIOS_API}circles?${params}`

        await axios.get(url, {headers})
        .then(response => {
            dispatch({type: 'getCirculos', payload: response.data})
            dispatch({type: 'filtered', payload: true})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const clearFilter = () => {
        dispatch({type: 'clearFilter'})
        // si se filtro llamo al get de círculos para traer todos los resultado nuevamente
        if(state.filtered){
            getCirculos()
            dispatch({type: 'filtered', payload: false})
        }
    }

    const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeFilter', payload: {name: event.target.name, value: event.target.value}})
    };

    const getProvincias = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}states`, {headers})
        .then(response => {
            dispatch({type: 'getProvincias', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    
    const getDepartamentos = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}departments?stateId=${state.items.state}&offset=0&limit=200`, {headers})
        .then(response => {
            dispatch({type: 'getDepartamentos', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    
    const getLocalidades = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}towns?stateId=${state.items.state}&departmentId=${state.items.department}&offset=0&limit=200`, {headers})
        .then(response => {
            dispatch({type: 'getLocalidades', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    return(
        <CircleContext.Provider value={{
            state,
            getCirculos,
            getCirculoById,
            getUserCircles,
            setUserCircle,
            handleChangeFilter,
            getCirclesFilter,
            clearFilter,
            getProvincias,
            getDepartamentos,
            getLocalidades
        }}>
            {children}
        </CircleContext.Provider>
    )
}

export default CirculoProvider