import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import 'moment/locale/es';
import { useContext, useEffect, useRef, useState } from "react"
import PaperContainer from '../PaperContainer';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Loader from '../Loader';
import Container from '../Container';
import { EnrollmentDetailItems } from '../../interfaces/Empadronamiento';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { TextField } from '@mui/material';
import styled from '@emotion/styled';

const TextFieldStyled = styled(TextField)(() => ({
    '.MuiInputLabel-root': {
        'span': {
            color: 'red'
        }
    }
}));

interface Props {
    section: string
    propData?: EnrollmentDetailItems
}

const Step3 = (props: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    const { state, handlePrevStep, handleNextStep, getInsuranceType, handleChangeFile, handleChangeSecureCheck, handleChangeDatePicker } = useContext(EmpadronamientoContext)

    useEffect(() => {
        getInsuranceType()
    }, [])

    const [uncheckedError, setUncheckedError] = useState(false)
    const [documentationRequired, setDocumentationRequired] = useState(false)

    const handleOpenFile = () => {
        if(inputRef.current){
            inputRef.current.click()
        }
    }

    if(!state.insuranceTypeData.length){
        return <Loader />
    }

    const file = state.insuranceTypeItems.collectiveFile ? state.insuranceTypeItems.collectiveFile[0] : ''

    let extensionError = false;

    if(file){
        const extension = file.name.split('.').pop()?.toLowerCase()
        if(extension !== 'pdf'){
            extensionError = true
        }else{
            extensionError = false
        }
    }
    const insuranceThatRequiresDocumentation = state.insuranceTypeData.filter(data => data.requireDocumentation === true)
    // verifico si el seguro id seleccionado requiere documentacion
    const requireDocumentation = insuranceThatRequiresDocumentation.filter(data => data.insuranceTypeId === state.secureId)

    const dateRequired = state.secureId === 3

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if(state.secureId){
            setUncheckedError(false)
            if(requireDocumentation.length && state.insuranceTypeItems.collectiveFile){
                setDocumentationRequired(false)
                handleNextStep()
            } else {
                setDocumentationRequired(true)
            }

            if(!requireDocumentation.length) {
                handleNextStep()
            }
        } else {
            setUncheckedError(true)
        }
    }

    const content = <Box textAlign='center' mb={6} mt={2} pt={2} pr={1} pl={1}>
        <Box sx={{width: '60%', marginLeft: '20%', marginTop: 6}}>
            <Typography variant='h5' sx={{fontWeight: 700}}>Tipo de Seguro</Typography>
        </Box>

        <Box 
            component='form' 
            onSubmit={handleSubmit}
            className='formContainer'
            sx={{width: '90%', marginLeft: '5%', marginTop: 5}}
        >
            <Box sx={{width: '70%', marginBottom: 5}}>
                <Grid container justifyContent='space-between' mb={3}>
                    {
                        state.insuranceTypeData.map(insuranceType => (
                            <FormControlLabel key={insuranceType.insuranceTypeId} control={<Checkbox checked={insuranceType.insuranceTypeId === state.secureId} onChange={() => {handleChangeSecureCheck(insuranceType.insuranceTypeId); setUncheckedError(false)}} />} label={insuranceType.description} />
                        ))
                    }
                    {uncheckedError ? <Typography color='error'>Debes seleccionar al menos un seguro</Typography> : null}
                </Grid>
                <Grid container mb={2} width={'100%'} spacing={2}>
                    <Grid item xs={6} sm={6} md={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Fecha de Inicio"
                            value={state.enrollmentDetail.secureStartDate ? state.enrollmentDetail.secureStartDate : null}
                            onChange={(newValue) =>
                                handleChangeDatePicker(
                                moment(newValue).format("YYYY-MM-DD"),
                                "secureStartDate"
                                )
                            }
                            renderInput={(params) => (
                                <TextFieldStyled
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    required={dateRequired}
                                />
                            )}
                        />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Fecha de Finalización"
                            value={state.enrollmentDetail.secureFinishDate ? state.enrollmentDetail.secureFinishDate : null}
                            onChange={(newValue) =>
                                handleChangeDatePicker(
                                moment(newValue).format("YYYY-MM-DD"),
                                "secureFinishDate"
                                )
                            }
                            renderInput={(params) => (
                                <TextFieldStyled
                                    {...params}
                                    variant="outlined"
                                    fullWidth
                                    required={dateRequired}
                                />
                            )}
                        />
                        </LocalizationProvider>
                    </Grid>
                </Grid>

                <Box sx={{border: '1px dashed #8C1AC4', width: '93%', padding: '20px 10px'}} position='relative'>
                    <CloudUploadIcon />
                    <Typography mb={2}>
                        Arrastre el Documento Acá
                        (El Documento debe ser PDF)
                    </Typography>
                    <input 
                        type="file" 
                        name='collectiveFile' 
                        ref={inputRef} 
                        onChange={(evt) => {handleChangeFile(evt); setDocumentationRequired(false)}}
                        style={{
                            opacity: 0,
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            cursor: 'pointer'
                        }}
                    />
                    <Button type='button' variant='contained' onClick={handleOpenFile}>Seleccionar</Button>
                </Box>
                <Typography sx={{display: file ? 'flex' : 'none', justifyContent: 'center', mt: 2}}>
                    {file ? file.name : null}
                    <CheckCircleIcon sx={{color: 'green', ml: 1}}/>
                </Typography>
                {documentationRequired ? <Typography mt={1} color='error'>Debe adjuntar la póliza del seguro seleccionado obligatoriamente</Typography> : null}
            </Box>

            <Stepper nonLinear activeStep={state.activeStep}>
                {state.steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>

            <Grid container justifyContent='center' spacing={2}>
                <Grid item>
                    <Button 
                        type='button' 
                        variant='contained' 
                        sx={{
                            background: '#42C5B7',
                            '&:hover': {background: '#389b90'},
                            borderRadius: 30,
                            padding: '5px 40px 5px 40px',
                            margin: '45px 0px 30px 0px',
                        }}
                        onClick={handlePrevStep}
                    >
                        Volver
                    </Button> 
                </Grid>
                <Grid item>
                    <Button 
                        type='submit' 
                        variant='contained' 
                        disabled={extensionError}
                        sx={{
                            background: '#42C5B7',
                            '&:hover': {background: '#389b90'},
                            borderRadius: 30,
                            padding: '5px 40px 5px 40px',
                            margin: '45px 0px 30px 0px',
                        }}
                    >
                        Siguiente
                    </Button> 
                </Grid>
            </Grid>
        </Box>
    </Box>

    return (
        props.section === 'empadronamiento' ? <PaperContainer>{content}</PaperContainer> : <Container child={content}/>
    )
}

export default Step3