import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import EmpadronamientoContext from "../context/Empadronamiento/EmpadronamientoContext"
import { useContext, useState, useEffect } from "react"
import PaperContainer from '../PaperContainer';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { Paper, Tooltip } from '@mui/material';
import Confirm from '../Confirm';
import { useLoadScript } from "@react-google-maps/api";
import { getGeocode, getLatLng } from "use-places-autocomplete";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Loader from '../Loader';
import Container from '../Container';
import { EnrollmentDetailItems } from '../../interfaces/Empadronamiento';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

const TextFieldStyled = styled(TextField)(() => ({
    '.MuiInputLabel-root': {
        'span': {
            color: 'red'
        }
    }
}));

const TypographyStyled = styled(Typography)(() => ({
    color: '#3B72FF',
    fontWeight: 'bolder',
    marginRight: 8
}));

const LibrarieIsLoaded = () => {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ? process.env.REACT_APP_GOOGLE_API_KEY : '',
        libraries: ["places"],
    });
    return isLoaded
}

interface Props {
    section: string
    propData?: EnrollmentDetailItems
}

const Step2 = (props: Props) => {

    const [openConfirm, handleOpenConfirm] = useState(false)
    const [idToDelete, LoadIdToDelete] = useState(0)

    const { 
        state, 
        handlePrevStep, 
        handleNextStep, 
        handleChangeUbicacion, 
        handleChangeInputConsultorio, 
        getDepartamentos, 
        getLocalidades, 
        getAllLocalidades, 
        getProvincias, 
        clearConsultorioValues, 
        removeConsultorio,
        handleChangeHasXRay,
        handleClickCheck,
        setLatAndLng,
        handleChangeDatePicker
    } = useContext(EmpadronamientoContext)

    const formatAddress = async () => {
        const address = `${state.consultorioItems.street} ${state.consultorioItems.height}, ${state.consultorioItems.town}, ${state.consultorioItems.state}, Argentina`
        const results = await getGeocode({ address });
        const { lat, lng } = await getLatLng(results[0]);
        // seteo latitud y longitud separado por coma en el campo googlePlaceId
        const latAndLng = lat + ',' + lng
        setLatAndLng(latAndLng)
    };

    useEffect(() => {
        // si se seleccionaron los campos obligatorios para obtener la latitud y longitud formateo la direccion
        if(state.consultorioItems.street && state.consultorioItems.town && state.consultorioItems.height && state.consultorioItems.town && state.consultorioItems.state){
            formatAddress()
        }
    }, [state.consultorioItems.street, state.consultorioItems.town, state.consultorioItems.height, state.consultorioItems.town, state.consultorioItems.state])

    useEffect(() => {
        if(!state.consultorioItems.state){
            getProvincias();
            getDepartamentos();
            getAllLocalidades();
        } else if(state.consultorioItems.state && !state.loadingDatosDemograficos){
            getDepartamentos();
            getLocalidades();
        }
    }, [state.consultorioItems.state, state.consultorioItems.department])
    

    // recorro array de localidades y me fijo si hay algun nombre repetido y lo saco del array
    let arrayLocalidad: any[] = [];
    if (state.localidades.length) {
        state.localidades.map((l) => {
            if (!arrayLocalidad.some(item => item.name === l.name.charAt(0) + l.name.slice(1).toLowerCase())) {
                arrayLocalidad.push({name: l.name.charAt(0) + l.name.slice(1).toLowerCase(), townsId: l.townsId});
            }
        });
    }
    const localidadesFormat = arrayLocalidad;

    const addNewConsultorio = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        clearConsultorioValues()
    }

    let datosObligatoriosCargados = false
    if(state.consultorioItems.postalCode && state.consultorioItems.state && state.consultorioItems.department && state.consultorioItems.town && state.consultorioItems.phone && state.consultorioItems.email && state.consultorioItems.availabilityDate){
        datosObligatoriosCargados = true
    }

    const nextStep = () => {
        if(datosObligatoriosCargados){
            clearConsultorioValues()
        }
        handleNextStep()
    }

    const handleConfirmDelete = () => {
        removeConsultorio(idToDelete)
        handleOpenConfirm(false)
    }

    if((process.env.NODE_ENV !== 'production' && !LibrarieIsLoaded()) || (process.env.NODE_ENV === 'production' && !LibrarieIsLoaded()) || state.loading){
        return <Loader />
    }

    const provinciaSelected = state.provincias.find(p => p.statesId === Number(state.consultorioItems.state))
    const departamentSelected = state.departamentos.find(d => d.departmentsId === Number(state.consultorioItems.department))
    const localidadSelected = state.localidades.find(l => l.townsId  === Number(state.consultorioItems.town))

    if(Number(localidadSelected?.postalCode) !== 0){
        state.consultorioItems.postalCode = localidadSelected?.postalCode ? localidadSelected.postalCode : state.consultorioItems.postalCode
    }

    const content = <>
        <Box textAlign='center' mb={6} mt={2} pt={2} pr={1} pl={1}>
            <Box sx={{width: '60%', marginLeft: '20%', marginTop: 6}}>
                <Typography variant='h5' sx={{fontWeight: 700}}>Datos del Consultorio</Typography>
            </Box>


            <Box 
                component='form' 
                onSubmit={addNewConsultorio}
                className='formContainer'
                sx={{width: '90%', marginLeft: '5%', marginTop: 5}}
            >

                <h4>Datos Demográficos</h4>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={4}>
                        <TextFieldStyled 
                            fullWidth 
                            label='Calle' 
                            onChange={handleChangeInputConsultorio}
                            name="street"
                            value={state.consultorioItems.street}
                            required
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextFieldStyled
                            label="Número"
                            name='height'
                            variant="outlined"
                            required
                            fullWidth
                            value={state.consultorioItems.height ? state.consultorioItems.height : ''}
                            onChange={handleChangeInputConsultorio}
                        />
                        <FormControlLabel control={<Checkbox onChange={handleClickCheck} />} label="Sin Número" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={5}>
                        <TextFieldStyled 
                            fullWidth 
                            label='Entre Calles' 
                            onChange={handleChangeInputConsultorio}
                            name="betweenStreet"
                            inputProps={{maxLength: '50'}}
                            value={state.consultorioItems.betweenStreet ? state.consultorioItems.betweenStreet : ''}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="Piso"
                            name='floor'
                            variant="outlined"
                            fullWidth
                            inputProps={{maxLength: "2"}}
                            value={state.consultorioItems.floor ? state.consultorioItems.floor : ''}
                            onChange={handleChangeInputConsultorio}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="Depto"
                            name='apartment'
                            variant="outlined"
                            fullWidth
                            inputProps={{maxLength: '2'}}
                            value={state.consultorioItems.apartment ? state.consultorioItems.apartment : ''}
                            onChange={handleChangeInputConsultorio}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>
                        <Tooltip title="Código Postal">
                        <TextFieldStyled
                            label="CP"
                            name='postalCode'
                            required
                            variant="outlined"
                            fullWidth
                            value={state.consultorioItems.postalCode ? state.consultorioItems.postalCode : ''}
                            onChange={handleChangeInputConsultorio}
                        />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                value={{
                                    statesId: provinciaSelected?.statesId ? provinciaSelected.statesId : 0,
                                    name: provinciaSelected?.name ? provinciaSelected.name : ''
                                }}
                                onChange={(event, newValue) =>
                                    handleChangeUbicacion(newValue?.statesId ? newValue.statesId : 0, "state")
                                }
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                options={state.provincias}
                                renderInput={(params) => (
                                    <TextFieldStyled
                                        {...params}
                                        key={params.id}
                                        required
                                        label="Provincia"
                                        fullWidth
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                value={{
                                    departmentsId: departamentSelected?.departmentsId ? departamentSelected.departmentsId : 0,
                                    name: departamentSelected?.name ? departamentSelected.name : ''
                                }}
                                onChange={(event, newValue) =>
                                    handleChangeUbicacion(newValue?.departmentsId ? newValue.departmentsId : 0, "department")
                                }
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                options={state.departamentos}
                                renderInput={(params) => (
                                    <TextFieldStyled
                                        {...params}
                                        key={params.id}
                                        required
                                        label="Partido"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth>
                            <Autocomplete
                                value={{
                                    townsId: localidadSelected?.townsId ? localidadSelected.townsId : 0,
                                    name: localidadSelected?.name ? localidadSelected.name.charAt(0) + localidadSelected.name.slice(1).toLowerCase() : ''
                                }}
                                onOpen={!state.localidades.length ? getLocalidades : () => {}}
                                onChange={(event, newValue) =>
                                    handleChangeUbicacion(newValue?.townsId ? newValue.townsId : 0, "town")
                                }
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                options={localidadesFormat}
                                renderInput={(params) => (
                                    <TextFieldStyled
                                        {...params}
                                        key={params.id}
                                        required
                                        label="Localidad"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <h4>Datos de Contacto</h4>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={6}>
                        <TextFieldStyled
                            label="Teléfono / Celular"
                            required
                            name='phone'
                            variant="outlined"
                            fullWidth
                            value={state.consultorioItems.phone ? state.consultorioItems.phone : ''}
                            onChange={handleChangeInputConsultorio}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <TextFieldStyled
                            label="Teléfono / Celular"
                            name='cellPhone'
                            variant="outlined"
                            fullWidth
                            value={state.consultorioItems.cellPhone ? state.consultorioItems.cellPhone : ''}
                            onChange={handleChangeInputConsultorio}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} pt={3}>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextFieldStyled
                            label="Email"
                            name='email'
                            type="email"
                            variant="outlined"
                            fullWidth
                            required
                            value={state.consultorioItems.email ? state.consultorioItems.email : ''}
                            onChange={handleChangeInputConsultorio}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextFieldStyled
                            label="Email Opcional"
                            name='secondEmail'
                            type="email" 
                            variant="outlined"
                            fullWidth
                            value={state.consultorioItems.secondEmail ? state.consultorioItems.secondEmail : ''}
                            onChange={handleChangeInputConsultorio}
                        />
                    </Grid>
                </Grid>
                <h4>Detalles de Consultorio</h4>
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={6}>
                        <TextFieldStyled
                            fullWidth
                            select
                            label="¿Equipo de Rayos X?"
                            value={state.consultorioItems.hasXRay}
                            onChange={handleChangeHasXRay}
                            name='hasXRay'
                        >
                            <MenuItem value='true'>Si</MenuItem>
                            <MenuItem value='false'>No</MenuItem>
                        </TextFieldStyled>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker
                            label="Fecha de Habilitación"
                            value={state.consultorioItems.availabilityDate ? state.consultorioItems.availabilityDate : null}
                            onChange={(newValue) =>
                                handleChangeDatePicker(
                                moment(newValue).format("YYYY-MM-DD"),
                                "availabilityDate"
                                )
                            }
                            renderInput={(params) => (
                                <TextFieldStyled
                                    {...params}
                                    variant="outlined"
                                    required
                                    fullWidth
                                />
                            )}
                        />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextFieldStyled
                            fullWidth
                            label="Horarios de Atención"
                            value={state.consultorioItems.attentionHours}
                            onChange={handleChangeInputConsultorio}
                            name='attentionHours'
                            multiline
                            rows={2}
                            inputProps={{maxLength: '150'}}
                        >
                        </TextFieldStyled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                        <TextFieldStyled
                            fullWidth
                            label="Observaciones"
                            value={state.consultorioItems.observations}
                            onChange={handleChangeInputConsultorio}
                            name='observations'
                            multiline
                            rows={3}
                            inputProps={{maxLength: "250"}}
                        >
                        </TextFieldStyled>
                    </Grid>
                </Grid>

                <Grid container sx={{paddingTop: '30px', justifyContent: 'flex-start'}}>
                    <Grid item md={5} xs={6}>
                        <Button type='submit' variant='contained' fullWidth>
                            Agregar Consultorio
                        </Button>
                    </Grid>
                </Grid>

                {state.consultorioCargado.length ?
                    state.consultorioCargado.map(consultorio => (
                        <Paper key={consultorio.officesId} sx={{mt: 3, width: '100%'}} elevation={3}>
                            <Grid container spacing={3} padding={2} position={'relative'}>
                                {consultorio.street ?
                                    <Grid item container xs={6} sm={6} md={6}>
                                        <TypographyStyled>Calle:</TypographyStyled>{consultorio.street}
                                    </Grid>
                                : null}
                                {consultorio.height ?
                                    <Grid item container xs={6} sm={6} md={6}>
                                        <TypographyStyled>Número:</TypographyStyled>{consultorio.height}
                                    </Grid>
                                : null}
                                {consultorio.betweenStreet ?
                                    <Grid item container xs={6} sm={6} md={6}>
                                        <TypographyStyled>Entre Calles:</TypographyStyled>{consultorio.betweenStreet}
                                    </Grid>
                                : null}
                                {consultorio.floor ?
                                    <Grid item container xs={12} sm={12} md={6}>
                                        <TypographyStyled>Piso:</TypographyStyled>{consultorio.floor}
                                    </Grid>
                                : null}
                                {consultorio.apartment ?
                                    <Grid item container xs={6} sm={6} md={6}>
                                        <TypographyStyled>Depto:</TypographyStyled>{consultorio.apartment}
                                    </Grid>
                                : null}
                                {consultorio.postalCode ?
                                    <Grid item container xs={6} sm={6} md={6}>
                                        <TypographyStyled>Código Postal:</TypographyStyled>{consultorio.postalCode}
                                    </Grid>
                                : null}
                                <Grid item container xs={6} sm={6} md={6}>
                                    <TypographyStyled>Provincia:</TypographyStyled>
                                    {state.provincias.find(p => p.statesId === Number(consultorio.state))?.name}
                                </Grid>
                                <Grid item container xs={6} sm={6} md={6}>
                                    <TypographyStyled>Partido:</TypographyStyled>
                                    {state.departamentos.find(d => d.departmentsId === Number(consultorio.department))?.name}
                                </Grid>
                                <Grid item container xs={6} sm={6} md={6}>
                                    <TypographyStyled>Localidad:</TypographyStyled>
                                    {state.allLocalidades.find(d => d.townsId === Number(consultorio.town))?.name}
                                </Grid>
                                {consultorio.phone ?
                                    <Grid item container xs={6} sm={6} md={6}>
                                        <TypographyStyled>Teléfono / Celular:</TypographyStyled>{consultorio.phone}
                                    </Grid>
                                : null}
                                {consultorio.cellPhone ?
                                    <Grid item container xs={6} sm={6} md={6}>
                                        <TypographyStyled>Teléfono / Celular:</TypographyStyled>{consultorio.cellPhone}
                                    </Grid>
                                : null}
                                {consultorio.email ?
                                    <Grid item container xs={12} sm={6} md={6}>
                                        <TypographyStyled>Email: </TypographyStyled>{consultorio.email}
                                    </Grid>
                                : null}
                                {consultorio.secondEmail ?
                                    <Grid item container xs={12} sm={6} md={6}>
                                        <TypographyStyled>Email Opcional: </TypographyStyled>{consultorio.secondEmail}
                                    </Grid>
                                : null}
                                {consultorio.hasXRay ?
                                    <Grid item container xs={6} sm={6} md={6}>
                                        <TypographyStyled>Equipo de Rayos X:</TypographyStyled>{consultorio.hasXRay === "true" ? 'Sí' : 'No'}
                                    </Grid>
                                : null}
                                {consultorio.availabilityDate ?
                                    <Grid item container xs={6} sm={6} md={6}>
                                        <TypographyStyled>Fecha de Habilitación:</TypographyStyled>{consultorio.availabilityDate}
                                    </Grid>
                                : null}
                                {consultorio.attentionHours ?
                                    <Grid item container xs={12} sm={12} md={12}>
                                        <TypographyStyled>Horario de Atención:</TypographyStyled>{consultorio.attentionHours}
                                    </Grid>
                                : null}
                                {consultorio.observations ?
                                    <Grid item container xs={12} sm={12} md={12}>
                                        <TypographyStyled>Observaciones:</TypographyStyled>{consultorio.observations}
                                    </Grid>
                                : null}
                                <DeleteIcon 
                                    sx={{position: 'absolute', bottom: 10, right: 10, color: '#e33d3d', cursor: 'pointer'}} 
                                    onClick={() => {handleOpenConfirm(true); LoadIdToDelete(consultorio.officesId ? consultorio.officesId : 0)}}
                                />
                            </Grid>
                        </Paper>
                    ))
                : null}

                <Stepper nonLinear activeStep={state.activeStep} sx={{marginTop: 5}}>
                    {state.steps.map((label, index) => (
                        <Step key={index}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Grid container justifyContent='center' spacing={2}>
                    <Grid item>
                        <Button 
                            type='button' 
                            variant='contained' 
                            sx={{
                                background: '#42C5B7',
                                '&:hover': {background: '#389b90'},
                                borderRadius: 30,
                                padding: '5px 40px 5px 40px',
                                margin: '45px 0px 30px 0px',
                            }}
                            onClick={handlePrevStep}
                        >
                            Volver
                        </Button> 
                    </Grid>
                    <Grid item>
                        <Button 
                            type={datosObligatoriosCargados || state.consultorioCargado.length ? 'button' : 'submit'} 
                            variant='contained' 
                            sx={{
                                background: '#42C5B7',
                                '&:hover': {background: '#389b90'},
                                borderRadius: 30,
                                padding: '5px 40px 5px 40px',
                                margin: '45px 0px 30px 0px',
                            }}
                            onClick={datosObligatoriosCargados || state.consultorioCargado.length ? nextStep : () => {}}
                        >
                            Siguiente
                        </Button> 
                    </Grid>
                </Grid>
            </Box>
        </Box>
        <Confirm
            openConfirm={openConfirm}
            handleCloseConfirm={() => handleOpenConfirm(false)}
            handleConfirm={() => handleConfirmDelete()}
            title='¿Estas seguro?'
            description='Si eliminas este Consultorio no podrás volver a recuperarlo luego'
        />
    </> 

    return (
        props.section === 'empadronamiento' ? <PaperContainer>{content}</PaperContainer> : <Container child={content}/>
    )
}

export default Step2