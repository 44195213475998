const ListPermissions = () => {
    
    const permissionsStorage = localStorage.getItem('permissions')
    const permissions: [{description: string, policiesId: number, status: boolean}] = JSON.parse(permissionsStorage ? permissionsStorage : '');
    
    const listarCirculos = permissions.find(p => p.description === 'sitio-usuarios__listar-circulos')
    const listarEmpadronamientos = permissions.find(p => p.description === 'sitio-usuarios__manejo-de-empadronamientos')
    const listarObrasSociales = permissions.find(p => p.description === 'sitio-usuarios__listar-obras-sociales')
    const listarConsultorios = permissions.find(p => p.description === 'sitio-usuarios__manejo-de-consultorios')

    return (
        {
            listarCirculos,
            listarEmpadronamientos,
            listarObrasSociales,
            listarConsultorios
        }
    )
}

export default ListPermissions