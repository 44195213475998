import { useContext, useEffect, useState } from "react"
import Container from '../Container'
import { Box, Divider, Grid, Paper, Typography } from '@mui/material'
import { TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Button as MuiButton } from '@mui/material'
import DentalRecordsContext from "../context/DentalRecords/DentalRecordsContext"
import Loader from "../Loader";
import Pagination from "../Pagination";
import { Link, useParams } from "react-router-dom";
import Button from '../Button';
import ConfirmModal from "./ConfirmModal";
import { tokenDecode } from '../../interfaces/Login';
import jwt_decode from "jwt-decode";
import Odontogram from "./Odontogram/Odontogram";

const TypographyStyled = styled(Typography)(() => ({
    color: '#3B72FF',
    fontWeight: 'bolder',
    marginRight: 8
}));

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3B72FF',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Detail = () => {

    const { state,setProviders, getDentalRecordsById, getInsurances, getInsuranceStatus, getSexTypes, getDentalRecordStates, getUserCircles, modifyDentalRecordStatus, getLocalidades, getDentalDetail, getIvaTypes } = useContext(DentalRecordsContext)


    const {id, status} = useParams();

    useEffect(() => {
        setProviders([]);
        getDentalRecordsById(Number(id))
        getInsurances()
        getInsuranceStatus()
        getDentalDetail()
        getUserCircles()
        getSexTypes()
        getIvaTypes()
        getDentalRecordStates()
    }, [])

    useEffect(() => {
        if(state.items.patientDetail.state && state.items.patientDetail.department){
            getLocalidades(state.items.patientDetail.state, state.items.patientDetail.department)
        }
    }, [state.items.patientDetail.state, state.items.patientDetail.department])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [approved, setApproved] = useState(false);

    const handleOpenConfirmModal = () => {
        setOpenConfirmModal(!openConfirmModal)
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //funcion para decodificar base64 y hacer la descarga
    const downloadFiles = (data: string, fileName: string) => {
        const byteCharacters = atob(data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/octet-stream' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
      };
      
      //función para descargar los archivos
    const handleDownloadArchives = () => {
        state.items.dentalRecordsFiles.forEach((record: any, index: number) => {
        const [contentType, base64Data] = record.dentalFile.split(';base64,');
        const fileExtension = contentType.split('/')[1];
        const fileName = `Adjunto Nº${index + 1}.${fileExtension}`;
        downloadFiles(base64Data, fileName);
        });
    };

    if(state.loading){
        return <Loader />
    }

    const token = localStorage.getItem('token')
    const decoded: tokenDecode = jwt_decode(token ? token : '');
    
    const enrollmentType = state.items.professionalDetail.isNationalEnrollment ? 'Nacional' : 'Provincial'

    const circlesInfo = state.userCircles.find(item => item.circleId === state.items.professionalDetail.circleId)
    const insuranceInfo = state.dataInsurance.find(item => item.insurancesId === state.items.patientDetail.insuranceId)
    const ivaTypeInfo = state.ivaTypesData.find(item => item.ivaTypeId === state.items.patientDetail.ivaTypeId)

    const localidadSelected = state.localidades.find(l => l.townsId  === Number(state.items.patientDetail.town))

    return (
        <Container
            child={
                <Box>
                    <Box sx={{background: "#BEEBFF", padding: '15px', borderRadius: 2}}>
                        <Grid container justifyContent='space-between'>
                            <Grid item>
                                <Typography sx={{fontWeight: 'bold'}}>F.O.P.B.A</Typography>
                            </Grid>
                            {state.items.createDate ? 
                            <Grid item>
                                <Typography sx={{fontWeight: 'bold'}}>Fecha de Creación: {state.items.createDate}</Typography>
                            </Grid>
                            : null }
                            {state.items.bond ? 
                            <Grid item>
                                <Typography sx={{fontWeight: 'bold'}}>N° de Bono: {state.items.bond}</Typography>
                            </Grid>
                            : null }
                            {state.items.token ? 
                            <Grid item>
                                <Typography sx={{fontWeight: 'bold'}}>Token: {state.items.token}</Typography>
                            </Grid>
                            : null }
                        </Grid>
                    </Box>
                    <h4>Datos Personales del Paciente</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />

                    <Grid container spacing={3} mb={3}>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Nombre:</TypographyStyled>{state.items.patientDetail.name}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Apellido:</TypographyStyled>{state.items.patientDetail.lastname}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>N° Afiliado:</TypographyStyled>{state.items.patientDetail.affiliateId}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>DNI:</TypographyStyled>{state.items.patientDetail.documentValue}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Domicilio:</TypographyStyled>{state.items.patientDetail.streetNumber ? state.items.patientDetail.street + ' ' + state.items.patientDetail.streetNumber : state.items.patientDetail.street}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Localidad:</TypographyStyled>{localidadSelected?.name}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Edad:</TypographyStyled>{state.items.patientDetail.age}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Sexo:</TypographyStyled>{state.sexTypesList.find(item => item.sexTypeId === state.items.patientDetail.sexTypeId)?.description}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Teléfono / celular:</TypographyStyled>{state.items.patientDetail.phone}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Condición Fiscal: </TypographyStyled>{ivaTypeInfo?.description}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Boca Sana: </TypographyStyled>{state.items.healthyMouth ? "SI" : "NO"}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Cantidad de Piezas: </TypographyStyled>{state.items.amountPieces ?? 0}
                        </Grid>
                    </Grid>

                    <Box sx={{background: "#BEEBFF", padding: '15px', borderRadius: 2}} mb={3}>
                        <Grid container justifyContent='space-between'>
                            <Grid item container md={3}>
                                <TypographyStyled>Obra Social:</TypographyStyled>{insuranceInfo?.idFopbaInsider} - {insuranceInfo?.name}
                            </Grid>
                            <Grid item container md={6}>
                                <TypographyStyled>Entidad Primaria:</TypographyStyled>{circlesInfo?.idFopbaInsider} - {circlesInfo?.name}
                            </Grid>
                        </Grid>
                    </Box>
                    
                    <h4>Datos Personales del Profesional</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                    <Grid container spacing={3} mb={7}>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Nombre:</TypographyStyled>{state.items.professionalDetail.professionalName}
                        </Grid>
                        <Grid item container xs={6} sm={6} md={4}>
                            <TypographyStyled>Apellido:</TypographyStyled>{state.items.professionalDetail.professionalLastname}
                        </Grid>
                        <Grid item container xs={12} sm={12} md={4}>
                            <TypographyStyled>N° Matricula {enrollmentType}:</TypographyStyled>{state.items.professionalDetail.enrollment}
                        </Grid>
                    </Grid>

                    <h4>Detalle de ficha</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>  
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Nomenclador</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Pieza</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="left">Cara</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 
                                    ? state.items.treatments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : state.items.treatments
                                  ).map((row:any) => { 
                                    return (
                                    <StyledTableRow
                                        key={row.treatmentsId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >   
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{`${row?.textCode ?? ''} - ${row.description}`}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} component="th" scope="row" align="left">{state.dentalDetail.pieces.filter(item => item.piecesId === row.pieceId).map(i => i.pieceNumber + ' - ' + i.description)}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">{row?.faceName}</StyledTableCell>
                                    </StyledTableRow>
                                )})}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                        colSpan={3}
                                        count={state.items.treatments.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Filas por pagina',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage='Filas por Página'
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => <Pagination count={state.items.treatments.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                    
                    {/* <h4>Odontograma</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                    <Box sx={{width: '100%', marginTop: 7}}>
                    <Odontogram 
                            pushProviders={pushProviders}
                            providers={state.listProviderTypes} 
                            bornDate={state.affiliateData.bornDate}
                    />
                    </Box> */}
                    
                    <Grid container>
                        <Grid item md={6} mt={2}>
                            <MuiButton variant="contained" onClick={handleDownloadArchives} disabled={state.items.dentalRecordsFiles.length === 0}>
                                Descargar Archivos Adjuntos
                            </MuiButton>
                        </Grid>
                    </Grid>

                    {state.items.observers ? 
                    <Grid container mt={4} mb={4}>
                        <TypographyStyled>Diagnóstico General:</TypographyStyled>{state.items.observers}
                    </Grid>
                    : null}

                    <h4>Estado de Autorización de Fichas</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Estado</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Comentario</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Fecha</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Usuario</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {(rowsPerPage > 0 
                                    ? state.items.histories.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : state.items.histories
                                ).map((row) => (
                                    <StyledTableRow
                                        key={row.dentalRecordHistoriesId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell sx={{padding: '5px'}} component="th" scope="row" align="center">{state.dentalRecordStates.find(item => item.dentalRecordStatesId === row.status)?.description}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '5px'}} align="center">{row.comment}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '5px'}} align="center">{row.date}</StyledTableCell>
                                        <StyledTableCell sx={{padding: '5px'}} align="center">{row.userId}</StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                        colSpan={3}
                                        count={state.items.treatments.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Filas por pagina',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage='Filas por Página'
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => <Pagination count={state.items.treatments.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>

                    <Box sx={{mt:2}}>
                      <Odontogram pushProviders={() =>{}} providers={state?.items?.providers ?? []}  disabled={true}/>
                    </Box>
                    {/* muestro botones si es perfil "Circulo" y si el estado es "Pendiente Circulo" */}
                    
                    {decoded.profileId === 4 && Number(status) === 2 ?
                        <Grid container justifyContent='center' spacing={3} alignItems='center' mt={2}>
                            <Grid item xs={6} sm={6} md={6}>
                                <Button child='Rechazar' handleClick={() => {handleOpenConfirmModal(); setApproved(false)}} variant='outlined' color="error" />
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <Button child='Aprobar' handleClick={() => {handleOpenConfirmModal(); setApproved(true)}} variant='contained' color="secondary" />
                            </Grid>
                        </Grid>
                    : null}
                    
          
                    <Grid container justifyContent='end' mt={4}>
                        <Grid item xs={4} sm={4} md={3}>
                            <Button 
                                child={
                                    <Link style={{textDecoration: 'none', color: '#4e4e4e', width: '100%'}} to={'/fichas'}>
                                        Volver
                                    </Link>
                                } 
                                variant='outlined' 
                                color='secondary'
                            />
                        </Grid>
                    </Grid>

                    <ConfirmModal
                        openModal={openConfirmModal}
                        handleCloseModal={handleOpenConfirmModal}
                        modifyDentalRecordStatus={modifyDentalRecordStatus}
                        status={approved ? 3 : 5}
                        id={Number(id)}
                        approved={approved}
                    />
                </Box>
            }
        />
    )
}

export default Detail