import { Grid, Typography } from "@mui/material"
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Tooth from '../../img/tooth.png'
import Tooth2 from '../../img/tooth2.png'
import Container from "../Container";

const Index = () => {
    return (
        <Container 
            child={
                <Box>
                    <Grid container alignItems='center' spacing={2}>
                        <Grid xs={12} sm={12} md={2} item>
                            <Card 
                                sx={{
                                    background: 'linear-gradient(180deg, #3B73FD 0%, #50CBAD 100%);', 
                                    border: '1px solid #4DBEB9',
                                    width: {xs: '100%', md: '90%'},
                                    height: 150,
                                    marginTop: 3
                                }}
                            >
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 3}}>
                                    <Typography sx={{color: '#fff', display: 'flex', alignItems: 'center'}}>Nueva Ficha</Typography>
                                    <img src={Tooth} alt='tooth' />
                                </Box>
                            </Card>
                        </Grid>
                        <Grid xs={6} sm={6} md={5} item>
                            <Box>
                                <Typography>Ultimas Fichas Cargadas</Typography>
                                <Card 
                                    sx={{
                                        border: '1px solid #4DBEB9',
                                        width: '90%',
                                        height: 150,
                                        borderRadius: 5
                                    }}
                                >
                                </Card>
                            </Box>

                        </Grid>
                        <Grid xs={6} sm={6} md={5} item>
                            <Box>
                                <Typography>Pendientes de Aprobación</Typography>
                                <Card 
                                    sx={{
                                        border: '1px solid #B92F2F',
                                        width: '90%',
                                        height: 150,
                                        borderRadius: 5
                                    }}
                                    >
                                </Card>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container mt={10} alignItems='center'>
                        <Grid item xs={12} sm={12} md={4}>
                            <img src={Tooth2} alt='tooth2' />
                        </Grid>
                        <Grid container item xs={12} sm={12} md={8}>
                            <Box mb={3}>
                                <Typography sx={{color: '#484BF2', fontWeight: 900}} variant='h6'>Cursos</Typography>
                                <Typography sx={{color: '#484BF2'}} variant='body2'>La Federación realiza Talleres y Cursos Teórico-Práctico para el desarrollo y para la formación del odontólogo.</Typography>
                            </Box>
                            <Grid xs={4} sm={4} md={4} item>
                                <Card 
                                    sx={{
                                        width: '90%',
                                        height: 150,
                                        background: '#D9D9D9'
                                    }}
                                >
                                </Card>
                            </Grid>
                            <Grid xs={4} sm={4} md={4} item>
                                <Card 
                                    sx={{
                                        width: '90%',
                                        height: 150,
                                        background: '#D9D9D9'
                                    }}
                                >
                                </Card>
                            </Grid>
                            <Grid xs={4} sm={4} md={4} item>
                                <Card 
                                    sx={{
                                        width: '90%',
                                        height: 150,
                                        background: '#D9D9D9'
                                    }}
                                >
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            }
        />
    )
}
export default Index