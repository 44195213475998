import { Routes, Route } from "react-router-dom";
import Login from "./components/Login/Index";
import Home from "./components/Home/Index";
import NewPassword from "./components/NewPassword";
import SecurityQuestions from "./components/SecurityQuestions/Index";
import User from "./components/User/Index";
import EmpadronamientoList from "./components/EmpadronamientoDetail/Index";
import EmpadronamientoDetail from "./components/EmpadronamientoDetail/Detail";
import Contact from "./components/Contact/Index";
import Circles from "./components/Circles/Index";
import CircleDetail from "./components/Circles/Detail";
import Insurance from "./components/Insurance/Index";
import InsuranceDetail from "./components/Insurance/Detail";
import RecoverPassword from "./components/RecoverPassword/Index";
import Empadronamiento from "./components/Empadronamiento/Index";
import Empadronamientos from "./components/Empadronamientos/Index";
import EmpadronamientosDetail from "./components/Empadronamientos/Detail";
import EmpadronamientoEdit from "./components/Empadronamientos/Edit";
import ProfessionalProfile from "./components/ProfessionalProfile/Index";
import DentalRecords from "./components/DentalRecords/Index";
import DentalRecordsDetail from "./components/DentalRecords/Detail";
import DentalRecordsEditDetail from "./components/DentalRecords/EditDetail";
import DentalRecordsAdd from "./components/DentalRecords/Add";
import Offices from "./components/Offices/Index";
import OfficesDetail from "./components/Offices/Detail";
import OfficesAdd from "./components/Offices/Add";
import Reempadronamiento from "./components/Reempadronamiento/Index";
import Edit from "./components/DentalRecords/Edit";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="/inicio" element={<Home /> } />
      <Route path="/nueva-contraseña" element={<NewPassword /> } />
      <Route path="/preguntas-de-seguridad" element={<SecurityQuestions /> } />
      <Route path="/usuario" element={<User /> } />
      <Route path="/usuario/detalle-de-empadronamiento" element={<EmpadronamientoList /> } />
      <Route path="/usuario/detalle-de-empadronamiento/:id" element={<EmpadronamientoDetail /> } />
      <Route path="/contactenos" element={<Contact /> } />
      <Route path="/circulos" element={<Circles /> } />
      <Route path="/detalle-de-circulo/:id" element={<CircleDetail /> } />
      <Route path="/obras-sociales" element={<Insurance /> } />
      <Route path="/detalle-de-obra-social/:id" element={<InsuranceDetail /> } />
      <Route path="/recuperar-contraseña" element={<RecoverPassword /> } />
      <Route path="/empadronamiento" element={<Empadronamiento /> } />
      <Route path="/empadronamientos" element={<Empadronamientos /> } />
      <Route path="/empadronamientos/:id/:empadronamientoId" element={<EmpadronamientosDetail /> } />
      <Route path="/modificar-empadronamiento/:id/" element={<EmpadronamientoEdit /> } />
      <Route path="/perfil-profesional" element={<ProfessionalProfile /> } />
      <Route path="/fichas" element={<DentalRecords /> } />
      <Route path="/ficha/:id/:status" element={<DentalRecordsDetail /> } />
      <Route path="/modificar-ficha/:id/:status" element={<Edit /> } />
      <Route path="/agregar-ficha/:code/:insurancesIdFopbaInsider/:insuranceId" element={<DentalRecordsAdd /> } />
      <Route path="/consultorios" element={<Offices /> } />
      <Route path="/consultorio/:id" element={<OfficesDetail /> } />
      <Route path="/agregar-consultorio" element={<OfficesAdd /> } />
      <Route path="/reempadronamiento/:id" element={<Reempadronamiento /> } />
    </Routes>
  );
}

export default App;
