import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination, Box, Grid, Typography } from '@mui/material';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import React, { useState, useContext, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom'
import Pagination from '../Pagination';
import InsuranceContext from '../context/Insurances/InsuranceContext';
import Divider from '@mui/material/Divider';
import Loader from '../Loader';
import Container from '../Container';
import Button from '../Button';
import Card from './Card';

const TypographyStyled = styled(Typography)(() => ({
    color: '#3B72FF',
    fontWeight: 'bolder',
    marginRight: 8
}));

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3B72FF',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const Detail = () => {

    const {
        state, 
        getInsuranceById,
        getPlanStatus,
        getInsurancePlan,
        getInsurancePlanById
    } = useContext(InsuranceContext)

    const {id} = useParams()

    useEffect(() => {
        getInsuranceById(Number(id))
        getInsurancePlan(Number(id))
        getPlanStatus()
    }, [])

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if(state.loading){
        return <Loader />
    }

    return (
        <Container 
            child={
                <Box>
                    <h4>{state.items.name}</h4>
                    <Divider sx={{borderColor: '#64AAB2', mb: 2}} />

                    <Grid container justifyContent='space-around' spacing={3} mt={2} mb={2}>
                        {state.dataInsurancePlan.map((plan) => {
                            return <Grid key={plan.plansId} item xs={6} sm={4} md={2.4}>
                                <Card 
                                    code={plan.code} 
                                    planId={plan.plansId}
                                    insuranceId={Number(id)}
                                    planIdSelected={state.planIdSelected} 
                                    getInsurancePlanById={getInsurancePlanById}
                                />
                            </Grid>
                        })}
                    </Grid>

                    {state.codesPlansList.length ?
                        <>
                            <h4>Detalle</h4>
                            <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                        </>
                    : null}

                    {state.codesPlansList.length ? 
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">Nomenclador</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">Capitulo</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">Arancel</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">% a abonar por afiliado</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="left">Descripción</StyledTableCell>
                                        <StyledTableCell sx={{padding: '12px'}} align="center">Estado</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {(rowsPerPage > 0 
                                        ? state.codesPlansList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : state.codesPlansList
                                    ).map((row) => {

                                        const statusDescription = state.planStatusList.map((status) => {
                                            if(row.codeStateId === status.codeStatesId){
                                                return status.description
                                            }
                                        })

                                        return <StyledTableRow
                                            key={row.codesId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell sx={{padding: '12px'}} component="th" scope="row" align="left">
                                                {row.code}
                                            </StyledTableCell>
                                            <StyledTableCell sx={{padding: '12px'}} align="left">{row.chapterId}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '12px'}} align="left">{row.customsDuty}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '12px'}} align="left">{row.member}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '12px'}} align="left">{row.description}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '12px'}} align="left">{statusDescription}</StyledTableCell>
                                        </StyledTableRow>
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                            colSpan={3}
                                            count={state.data.length}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                inputProps: {
                                                    'aria-label': 'Filas por pagina',
                                                },
                                                native: true,
                                            }}
                                            labelRowsPerPage='Filas por Página'
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                            ActionsComponent={() => <Pagination count={state.data.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    : null}
                    <Grid container justifyContent='end' mt={8}>
                        <Grid item xs={4} sm={4} md={3}>
                            <Button 
                                child={
                                    <Link style={{textDecoration: 'none', color: '#4e4e4e', width: '100%'}} to={'/obras-sociales'}>
                                        Volver
                                    </Link>
                                } 
                                variant='outlined' 
                                color='secondary'
                            />
                        </Grid>
                    </Grid>
                </Box>
            } 
        />
    )
}

export default Detail