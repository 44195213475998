import { useReducer } from 'react'
import { UserState } from '../../../interfaces/User';
import UserContext from './UserContext';
import UserReducer from './UserReducer';
import { SelectChangeEvent } from '@mui/material';
import axios from 'axios';
import { CuitSinGuiones } from '../../../utils/CuitFormat';
import { tokenDecode } from '../../../interfaces/Login';
import jwt_decode from "jwt-decode";

const INITIAL_STATE: UserState = {
    openConfirm: false,
    provincias: [],
    departamentos: [],
    localidades: [],
    calles: [],
    userEdited: false,
    passwordsDontMatch: false,
    loading: false,
    openAlert: false,
    buttonDisabled: true,
    listQuestions: [{securityQuestionsId: 0, question: ''}],
    answerAndQuestions: {
        firstQuestion: 0,
        firstAnswer: '',
        secondQuestion: 0,
        secondAnswer: '',
        thirdQuestion: 0,
        thirdAnswer: ''
    },
    ivaTypeList: [],
    items: {
        usersId: 0,
        name: '',
        lastname: '',
        bornDate: '',
        email: '',
        password: '',
        repeatPassword: '',
        documentType: {documentTypeId: '', name: '', code: ''},
        documentValue: '',
        cuil: '',
        phone: '',
        cellPhone: '',
        street: '',
        streetNumber: '',
        floor: '',
        apartment: '',
        postalCode: '',
        town: '',
        department: '',
        state: '',
        country: '',
        isProfessional: '',
        ivaTypesId: 0,
        updateProfile: false,
        answers: [{
            answerId: 0,
            userId: 0,
            securityQuestionsId: 0,
            answer: ''
        }],
    },
    loadingDatosDemograficos: false,
    dataProfessional: {
        nationalEnrollment: '',
        provincialEnrollment: '',
        startActivity: '',
        fopbaRegistration: ''
    }
}

interface Props {
    children: JSX.Element | JSX.Element[]
}

const UserProvider = ({children}: Props) => {

    const [userState, dispatch] = useReducer(UserReducer, INITIAL_STATE)

    const getUser = async () => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        dispatch({type: 'setLoading', payload: true});

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}users/detail/${userId}`, {headers})
        .then(response => {
            dispatch({type: 'getUser', payload: response.data})
            dispatch({type: 'setLoading', payload: false});
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
            dispatch({type: 'setLoading', payload: false});
        })
    }

    const getSecurityQuestions =  async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}securityQuestions`, {headers})
        .then(response => {
            dispatch({type: 'getSecurityQuestions', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    };

    const changePassword = async (newPassword: string) => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const token = localStorage.getItem('token')
        const decoded: tokenDecode = jwt_decode(token ? token : '');

        const data = {
            "password" : newPassword,
            "username": decoded.email
        }

        await axios.patch(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}users/newPassword`, data, { headers })
        .then(() => {
            return true
        })
        .catch(() => {
            return false
        })
    }

    const editUser = async (iva: boolean) => {

        const userId = localStorage.getItem('user')
        const token = localStorage.getItem('token')
        const decoded: tokenDecode = jwt_decode(token ? token : '');

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        const data = {
            "name" : userState.items.name,
            "lastname" : userState.items.lastname,
            "bornDate" : userState.items.bornDate,
            "documentTypeId" : userState.items.documentTypeId,
            "documentValue" : userState.items.documentValue,
            "cuil" : CuitSinGuiones(userState.items.cuil),
            "phone" : userState.items.phone,
            "cellPhone" : userState.items.cellPhone,
            "street" : userState.items.street ? userState.items.street.toLowerCase() : userState.items.street,
            "streetNumber" : userState.items.streetNumber,
            "floor" : userState.items.floor,
            "apartment" : userState.items.apartment,
            "postalCode" : userState.items.postalCode,
            "town" : userState.items.town,
            "department" : userState.items.department,
            "state" : userState.items.state,
            "country" : 'Argentina',
            "answers": userState.items.answers
        }

        const dataWithIva = { ...data, ivaTypesId: userState.items.ivaTypesId, updateProfile: true };
        const dataToSend = iva ? dataWithIva : data

        dispatch({type: 'setLoading', payload: true});

        // verifico que las contraseñas sean iguales, sino muestro mensaje de error
        if(userState.items.password === userState.items.repeatPassword){
            dispatch({type: 'passwordsDontMatch', payload: false});
            if(userState.items.password || userState.items.repeatPassword){
                changePassword(userState.items.password)
            }
            // verifico que todos los campos obligatorios esten seteados
            if(userState.items.name && userState.items.lastname && userState.items.bornDate && userState.items.rolId && userState.items.documentValue 
                && userState.items.cuil && userState.items.phone && userState.items.street && userState.items.streetNumber 
                && userState.items.postalCode && userState.items.town && userState.items.state && userState.items.department
            ){
                await axios.put(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}users/${userId}`, dataToSend, {headers})
                .then(response => {
                    if(response.status === 200) {
                        if(iva && decoded.requireEnrollment && decoded.isProfessional){
                            window.location.href = '/empadronamiento'
                        } else if(iva) {
                            window.location.href = '/inicio'
                        }
                        
                        dispatch({type: 'setLoading', payload: false});
                        openAlert()
                    }
                })
                .catch(error => {
                    if(error.response.status === 401){
                        window.location.href = '/'
                    }
                })
                dispatch({type: 'userEdited', payload: false});
            } else {
                dispatch({type: 'userEdited', payload: true});
                dispatch({type: 'setLoading', payload: false});
            }
        } else {
            dispatch({type: 'passwordsDontMatch', payload: true});
            dispatch({type: 'setLoading', payload: false});
        }
    }

    const getProvincias = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}states`, {headers})
        .then(response => {
            dispatch({type: 'getProvincias', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    
    const getDepartamentos = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}departments?stateId=${userState.items.state}&offset=0&limit=200`, {headers})
        .then(response => {
            dispatch({type: 'getDepartamentos', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }
    
    const getLocalidades = async () => {
        const headers = {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };
    
        dispatch({ type: "loadingDatosDemograficos", payload: true });
    
        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}towns?stateId=${userState.items.state}&departmentId=${userState.items.department}&offset=0&limit=200`, {headers})
        .then(response => {
            dispatch({type: 'getLocalidades', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
          dispatch({ type: "loadingDatosDemograficos", payload: false });
        })
    }

    const getCalles = async () => {
        const urlCalles = `https://apis.datos.gob.ar/georef/api/calles?departamento=${userState.items.department}&localidad_censal=${userState.items.town}&max=1000`
        const res = await axios.get(urlCalles)
        dispatch({type: 'getCalles', payload: res.data.calles});
    }

    const clearValues = () => {
        dispatch({type: 'clearValues', payload: {
            usersId: 0,
            name: '',
            lastname: '',
            bornDate: '',
            email: '',
            password: '',
            repeatPassword: '',
            documentType: {documentTypeId: '', name: '', code: ''},
            documentValue: '',
            cuil: '',
            phone: '',
            cellPhone: '',
            street: '',
            streetNumber: '',
            floor: '',
            apartment: '',
            postalCode: '',
            town: '',
            department: '',
            state: '',
            country: '',
            isProfessional: '',
            ivaTypesId: 0,
            updateProfile: false,
            answers: [{
                answerId: 0,
                userId: 0,
                securityQuestionsId: 0,
                answer: ''
            }]
        }})
    }

    const getIvaTypes = async () => {

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}ivaType/`, {headers})
        .then(response => {
            dispatch({type: 'getIvaTypes', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const getProfessionals = async () => {

        const userId = localStorage.getItem('user')

        const headers = {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        };

        await axios.get(`${process.env.REACT_APP_SITIO_DE_USUARIOS_API}users/${userId}/professionals/`, {headers})
        .then(response => {
            dispatch({type: 'getProfessionals', payload: response.data})
        })
        .catch(error => {
            if(error.response.status === 401){
                window.location.href = '/'
            }
        })
    }

    const handleChangeAnswerField = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeAnswerField', payload: {name: e.target.name, value: e.target.value}})
    }

    const handleChangeSelect = (event: SelectChangeEvent<string | number>) => {
        dispatch({type: 'handleChangeSelect', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeUbicacion = (value: any, name: string) => {
        dispatch({type: 'changeUbicacion', payload: {value: value, name: name}})
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch({type: 'handleChangeInput', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleUserChangeSelect = (event: SelectChangeEvent<string | number>) => {
        dispatch({type: 'handleUserChangeSelect', payload: {value: event.target.value, name: event.target.name}})
    };

    const handleChangeDatePicker = (value: string, name: string) => {
        dispatch({type: 'changeDatePicker', payload: {value: value, name: name}})
    }

    const handleOpenConfirm = () => {
        dispatch({type: 'openConfirm', payload: true})
    }

    const handleCloseConfirm = () => {
        dispatch({type: 'openConfirm', payload: false})
    }

    const openAlert = () => {
        dispatch({type: 'openAlert', payload: true})
    };

    const closeAlert = () => {
        dispatch({type: 'openAlert', payload: false})
    };

    const handleClickCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({type: 'handleClickCheck', payload: event.target.checked})
    }

    return(
        <UserContext.Provider value={{
            userState,
            getUser,
            getSecurityQuestions,
            editUser,
            getProvincias,
            getDepartamentos,
            getLocalidades,
            getCalles,
            handleChangeAnswerField,
            handleChangeSelect,
            handleChangeUbicacion,
            handleChangeInput,
            handleUserChangeSelect,
            handleChangeDatePicker,
            handleOpenConfirm,
            handleCloseConfirm,
            clearValues,
            getIvaTypes,
            closeAlert,
            getProfessionals,
            handleClickCheck
        }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider