import Button from '../Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Grid } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useContext, useEffect } from "react"
import UserContext from "../context/User/UserContext"
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import 'moment/locale/es';
import moment from 'moment';
import Loader from '../Loader';
import Alert from '../Alert';
import { styled } from '@mui/material/styles';
import PaperContainer from '../PaperContainer';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const TextFieldStyled = styled(TextField)(() => ({
    '.MuiInputLabel-root': {
        color: '#3B72FF',
        'span': {
            color: 'red'
        }
    }
}));

const InputLabelStyled = styled(InputLabel)(() => ({
    color: '#3B72FF',
    'span': {
        color: 'red'
    }
}));

const Index = () => {

    const { 
        getUser,
        getProvincias, 
        getDepartamentos, 
        getLocalidades,
        handleChangeUbicacion, 
        handleChangeInput, 
        handleUserChangeSelect, 
        handleChangeDatePicker,
        editUser,
        getSecurityQuestions,
        handleChangeAnswerField,
        handleChangeSelect,
        closeAlert,
        getIvaTypes,
        handleClickCheck,
        userState 
    } = useContext(UserContext)

    useEffect(() => {
        if(!userState.items.usersId){
            getUser()       
            getSecurityQuestions()
            getIvaTypes()
            // getDocumentTypes()
            getProvincias();
            getDepartamentos();
        } else if(userState.items.usersId && !userState.loadingDatosDemograficos){
            getLocalidades();
        }
    }, [userState.items.usersId, userState.items.department])

    // recorro array de localidades y me fijo si hay algun nombre repetido y lo saco del array
    let arrayLocalidad: any[] = [];
    if (userState.localidades.length) {
        userState.localidades.map((l) => {
            if (!arrayLocalidad.some(item => item.name === l.name.charAt(0) + l.name.slice(1).toLowerCase())) {
                arrayLocalidad.push({name: l.name.charAt(0) + l.name.slice(1).toLowerCase(), townsId: l.townsId});
            }
        });
    }
    const localidadesFormat = arrayLocalidad;

    let questionAndAnswers: any[] = [];
    userState.listQuestions.map((questions) => {
        return userState.items.answers.map((answers) => {
            if(answers.securityQuestionsId === questions.securityQuestionsId){
                questionAndAnswers.push({questionId: questions.securityQuestionsId, question: questions.question, answer: answers.answer})
            }
        })
    })

    if(userState.loading || !userState.items.usersId){
        return <Loader />
    }

    // armo array con preguntas que no estan seleccionadas para que no se repitan y se conteste la misma dos veces
    const firstQuestionArray = userState.listQuestions.filter(question => question.securityQuestionsId !== userState.answerAndQuestions.secondQuestion && question.securityQuestionsId !== userState.answerAndQuestions.thirdQuestion)
    const secondQuestionArray = userState.listQuestions.filter(question => question.securityQuestionsId !== userState.answerAndQuestions.firstQuestion && question.securityQuestionsId !== userState.answerAndQuestions.thirdQuestion)
    const thirdQuestionArray = userState.listQuestions.filter(question => question.securityQuestionsId !== userState.answerAndQuestions.firstQuestion && question.securityQuestionsId !== userState.answerAndQuestions.secondQuestion)

    const provinciaSelected = userState.provincias.find(p => p.statesId === Number(userState.items.state))
    const departamentSelected = userState.departamentos.find(d => d.departmentsId === Number(userState.items.department))
    const localidadSelected = userState.localidades.find(l => l.townsId  === Number(userState.items.town))

    if(Number(localidadSelected?.postalCode) !== 0){
        userState.items.postalCode = localidadSelected?.postalCode ? localidadSelected.postalCode : ''
    }

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        editUser(true)
    }

    return (
        <PaperContainer>
            <Box component='form' onSubmit={handleSubmit} textAlign='center' mb={6} mt={2} pt={2} pr={2} pl={2} pb={3}>
                <h4>Datos Personales</h4>
                <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={6}>
                        <TextFieldStyled
                            margin="dense"
                            label="Nombre"
                            name='name'
                            required
                            variant="outlined"
                            fullWidth
                            error={!userState.items.name && userState.userEdited} 
                            value={userState.items.name}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <TextFieldStyled
                            margin="dense"
                            label="Apellido"
                            name='lastname'
                            required
                            variant="outlined"
                            fullWidth
                            error={!userState.items.lastname && userState.userEdited} 
                            value={userState.items.lastname}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            margin="dense"
                            label="Cuil / Cuit"
                            name='cuil'
                            required
                            inputProps={{ maxLength: 13 }}
                            variant="outlined"
                            fullWidth
                            error={!userState.items.cuil && userState.userEdited}
                            value={userState.items.cuil ? userState.items.cuil : ''}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl fullWidth variant='outlined' required margin='dense' error={!userState.items.documentType.documentTypeId && userState.userEdited}>
                            <InputLabelStyled>Tipo de Documento</InputLabelStyled>
                            <Select
                                label="Tipo de Documento"
                                name='documentTypeId'
                                value={userState.items.documentType.documentTypeId}
                                onChange={handleUserChangeSelect}
                            >
                                <MenuItem value={userState.items.documentType.documentTypeId}>{userState.items.documentType.code}</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            margin="dense"
                            label="Número de Documento"
                            name='documentValue'
                            required
                            variant="outlined"
                            fullWidth
                            error={!userState.items.documentValue && userState.userEdited}
                            value={userState.items.documentValue ? userState.items.documentValue : ''}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Fecha de Nacimiento"
                                value={userState.items.bornDate ? userState.items.bornDate : null}
                                onChange={(newValue) => handleChangeDatePicker(moment(newValue).format('YYYY-MM-DD'), 'bornDate')}
                                renderInput={(params) => <TextFieldStyled {...params} error={!userState.items.bornDate && userState.userEdited} required variant='outlined' fullWidth margin="dense" />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            margin="dense"
                            label="Teléfono / Celular"
                            name='phone'
                            required
                            variant="outlined"
                            fullWidth
                            error={!userState.items.phone && userState.userEdited} 
                            value={userState.items.phone ? userState.items.phone : ''}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            margin="dense"
                            label="Teléfono / Celular"
                            name='cellPhone'
                            variant="outlined"
                            fullWidth
                            value={userState.items.cellPhone ? userState.items.cellPhone : ''}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <TextFieldStyled
                            margin="dense"
                            label="Correo Electrónico"
                            name='email'
                            required
                            variant="outlined"
                            fullWidth
                            value={userState.items.email ? userState.items.email : ''}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth variant='outlined' required margin='dense' error={!userState.items.documentType.documentTypeId && userState.userEdited}>
                            <InputLabelStyled>Condición Fiscal</InputLabelStyled>
                            <Select
                                label="Condición Fiscal"
                                name='ivaTypesId'
                                value={userState.items.ivaTypesId ? userState.items.ivaTypesId : ''}
                                onChange={handleUserChangeSelect}
                                >
                                {userState.ivaTypeList.map((item) => {
                                    return <MenuItem key={item.ivaTypeId} value={item.ivaTypeId}>{item.description}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
                <h4 style={{marginTop: 40}}>Domicilio</h4>
                <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth margin='dense'>
                            <Autocomplete
                                value={{
                                    statesId: provinciaSelected?.statesId ? provinciaSelected.statesId : 0,
                                    name: provinciaSelected?.name ? provinciaSelected.name : ''
                                }}
                                onChange={(event, newValue) =>
                                    handleChangeUbicacion(newValue?.statesId ? newValue.statesId : 0, "state")
                                }
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                options={userState.provincias}
                                renderInput={(params) => (
                                    <TextFieldStyled
                                        {...params}
                                        error={!userState.items.state && userState.userEdited}
                                        key={params.id}
                                        required
                                        label="Provincia"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth margin='dense'>
                            <Autocomplete
                                value={{
                                    departmentsId: departamentSelected?.departmentsId ? departamentSelected.departmentsId : 0,
                                    name: departamentSelected?.name ? departamentSelected.name : ''
                                }}
                                onChange={(event, newValue) =>
                                    handleChangeUbicacion(newValue?.departmentsId ? newValue.departmentsId : 0, "department")
                                }
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                options={userState.departamentos}
                                renderInput={(params) => (
                                    <TextFieldStyled
                                        {...params}
                                        error={!userState.items.department && userState.userEdited}
                                        key={params.id}
                                        required
                                        label="Partido"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <FormControl fullWidth margin='dense'>
                            <Autocomplete
                                value={{
                                    townsId: localidadSelected?.townsId ? localidadSelected.townsId : 0,
                                    name: localidadSelected?.name ? localidadSelected.name.charAt(0) + localidadSelected.name.slice(1).toLowerCase() : ''
                                }}
                                onOpen={!userState.localidades.length ? getLocalidades : () => {}}
                                onChange={(event, newValue) =>
                                    handleChangeUbicacion(newValue?.townsId ? newValue.townsId : 0, "town")
                                }
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                options={localidadesFormat}
                                renderInput={(params) => (
                                    <TextFieldStyled
                                        {...params}
                                        error={!userState.items.town && userState.userEdited}
                                        key={params.id}
                                        required
                                        label="Localidad"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <TextFieldStyled
                            margin="dense"
                            label="Calle"
                            name="street"
                            required
                            variant="outlined"
                            fullWidth
                            value={userState.items.street}
                            error={!userState.items.street && userState.userEdited}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextFieldStyled
                            margin="dense"
                            label="Número"
                            name='streetNumber'
                            required    
                            variant="outlined"
                            fullWidth
                            error={!userState.items.streetNumber && userState.userEdited} 
                            value={userState.items.streetNumber && userState.items.streetNumber !== "0" ? userState.items.streetNumber : ''}
                            onChange={handleChangeInput}
                        />
                        <FormControlLabel control={<Checkbox onChange={handleClickCheck} checked={userState.items.streetNumber === 'S/N'} />} label="Sin Número" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextFieldStyled
                            margin="dense"
                            label="Piso"
                            name='floor'
                            variant="outlined"
                            fullWidth
                            value={userState.items.floor && userState.items.floor !== "0" ? userState.items.floor : ''}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextFieldStyled
                            margin="dense"
                            label="Depto"
                            name='apartment'
                            variant="outlined"
                            fullWidth
                            value={userState.items.apartment ? userState.items.apartment : ''}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={3}>
                        <TextFieldStyled
                            margin="dense"
                            label="Código Postal"
                            name='postalCode'
                            required
                            variant="outlined"
                            fullWidth
                            error={!userState.items.postalCode && userState.userEdited} 
                            value={userState.items.postalCode ? userState.items.postalCode : ''}
                            onChange={handleChangeInput}
                        />
                    </Grid>
                </Grid>
                <h4>Seguridad</h4>
                <Divider sx={{borderColor: '#64AAB2', mb: 3}} />
                <Grid container spacing={3}>
                    <Grid item xs={4} sm={4} md={4}>
                        <FormControl fullWidth >
                            <InputLabelStyled>Pregunta 1</InputLabelStyled>
                            <Select
                                value={userState.answerAndQuestions.firstQuestion}
                                label='Pregunta 1'
                                name='firstQuestion'
                                onChange={handleChangeSelect}
                            >
                                {firstQuestionArray.map((question) => {
                                    return <MenuItem key={question.securityQuestionsId} value={question.securityQuestionsId}>{question.question}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <FormControl fullWidth >
                            <InputLabelStyled>Pregunta 2</InputLabelStyled>
                            <Select
                                value={userState.answerAndQuestions.secondQuestion}
                                label='Pregunta 2'
                                name='secondQuestion'
                                onChange={handleChangeSelect}
                            >
                                {secondQuestionArray.map((question) => (
                                    <MenuItem key={question.securityQuestionsId} value={question.securityQuestionsId}>{question.question}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <FormControl fullWidth >
                            <InputLabelStyled>Pregunta 3</InputLabelStyled>
                            <Select
                                value={userState.answerAndQuestions.thirdQuestion}
                                label='Pregunta 3'
                                name='thirdQuestion'
                                onChange={handleChangeSelect}
                            >
                                {thirdQuestionArray.map((question) => (
                                    <MenuItem key={question.securityQuestionsId} value={question.securityQuestionsId}>{question.question}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <TextFieldStyled 
                            label='Respuesta 1'
                            variant="outlined"
                            name='firstAnswer'
                            value={userState.answerAndQuestions.firstAnswer}
                            onChange={(e) => handleChangeAnswerField(e)}
                            margin="dense"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <TextFieldStyled 
                            label='Respuesta 2'
                            variant="outlined"
                            name='secondAnswer'
                            value={userState.answerAndQuestions.secondAnswer}
                            onChange={(e) => handleChangeAnswerField(e)}
                            margin="dense"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        <TextFieldStyled 
                            label='Respuesta 3'
                            variant="outlined"
                            name='thirdAnswer'
                            value={userState.answerAndQuestions.thirdAnswer}
                            onChange={(e) => handleChangeAnswerField(e)}
                            margin="dense"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <h4>Cambiar Contraseña</h4>
                <Divider sx={{borderColor: '#64AAB2', mb: 3}} />
                <Grid container spacing={3}>
                    <Grid item xs={6} sm={6} md={6}>
                        <TextFieldStyled
                            margin="dense"
                            label="Contraseña"
                            name='password'
                            type='password'
                            variant="outlined"
                            fullWidth
                            error={userState.passwordsDontMatch} 
                            onChange={handleChangeInput}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <TextFieldStyled
                            margin="dense"
                            label="Repetir Contraseña"
                            name='repeatPassword'
                            type='password'
                            variant="outlined"
                            fullWidth
                            helperText={userState.passwordsDontMatch ? 'Las contraseñas no coinciden' : ''}
                            error={userState.passwordsDontMatch} 
                            onChange={handleChangeInput}
                        />
                    </Grid>
                </Grid>
                {/* BUTTONS */}
                <Grid container justifyContent='center' sx={{mt: 2}} spacing={4}>
                    <Grid item md={6}>
                        <Button color='secondary' type='submit' child='Continuar' variant='contained' />
                    </Grid>
                </Grid>
                <Alert openAlert={userState.openAlert} handleClose={closeAlert} severity='success' text={'Usuario editado correctamente'} />
            </Box>
        </PaperContainer>
    )
}

export default Index