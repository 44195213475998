import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { Typography } from '@mui/material';

interface Props {
    openModal: boolean
    circlesLength: number
}

export default function SummaryModal({ openModal, circlesLength }:Props) {

    return (
        <Dialog
            open={openModal}
        >
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} mt={6}>
                <TaskAltIcon sx={{fontSize: '80px', color: '#42C5B7'}}/>
                <Typography variant='h5'>Felicitaciones</Typography>
            </Box>
            <DialogContent>
                <DialogContentText>
                    Terminaste de cargar tus datos, ya podes ingresar a utilizar la plataforma.
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{flexDirection: 'column'}}>
                {circlesLength > 1 ?
                    <Typography 
                        onClick={() => window.location.reload()} 
                        sx={{color: '#2639ED', textDecoration: 'underline', cursor: 'pointer'}}
                    >
                        Cargar Nuevo Empadronamiento
                    </Typography>
                : null}
                <Button 
                    onClick={() => window.location.href = '/inicio'} 
                    variant='contained' 
                    sx={{margin: '25px 0px 20px 0px', justifyContent: 'center', background: '#42C5B7', '&:hover': {background: '#389b90'}, borderRadius: 10, padding: '6px 50px'}}
                >
                    Ingresar
                </Button>
            </DialogActions>
        </Dialog>
    );
}