import { createContext } from 'react'
import { InsuranceState } from '../../../interfaces/Insurances';

export interface ContextProps {
    state: InsuranceState,
    getInsurances: () => void
    getInsuranceFilter: () => void
    clearFilter: () => void
    getInsuranceById: (id: number) => void
    getInsuranceStatus: () => void
    handleChangeFilter: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    getPlanStatus: () => void
    // getChapters: () => void
    getInsurancePlanById: (insuranceId: number, insurancePlanId: number) => void
    getInsurancePlan: (id: number) => void
}

const InsuranceContext = createContext<ContextProps>({} as ContextProps);

export default InsuranceContext;