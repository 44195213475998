import { createContext } from 'react'
import { OfficesState } from '../../../interfaces/Offices';

export interface ContextProps {
    state: OfficesState,
    getOffices: () => void
    getOfficesById: (id: number) => void
    saveOffices: () => void
    updateOffices: (id: number, deleteOffice: boolean) => void
    clearConsultorioValues: () => void
    getProvincias: () => void
    getDepartamentos: () => void
    getLocalidades: () => void
    getAllLocalidades: () => void
    handleChangeUbicacion: (value: any, name: string) => void
    handleChangeDatePicker: (value: string, name: string) => void
    handleChangeInputConsultorio: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    openAlert: () => void
    closeAlert: () => void
    handleChangeHasXRay: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleClickCheck: (event: React.ChangeEvent<HTMLInputElement>) => void
    setLatAndLng: (latAndLng: string) => void
}

const EmpadronamientoContext = createContext<ContextProps>({} as ContextProps);

export default EmpadronamientoContext;