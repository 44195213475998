import {useContext, useEffect} from 'react'
import { Typography, TextField, Box, Grid } from "@mui/material"
import LoginContext from "../context/Login/LoginContext"
import Loader from '../Loader'
import ToothImg from '../../img/g10.png'
import Logo from '../../img/logo.png'
import Button from '../Button'
import { styled } from '@mui/material/styles';
import InvalidIdentity from './InvalidIdentity'

const GridContainer = styled(Grid)(() => ({
    padding: '80px 50px 0px 50px'
}));

const TypographyTitle = styled(Typography)(() => ({
    textAlign: 'center',
    fontWeight: 700,
    marginTop: 10
}));

const TypographySubitle = styled(Typography)(() => ({
    textAlign: 'center',
}));

const TypographyQuestion = styled(Typography)(() => ({
    color: '#430EBE'
}));

const Index = () => {
    const { state, getSecurityQuestionsByUser, handleChangeAnswers, validateSecurityQuestion, handleCloseInvalidIdentity } = useContext(LoginContext)

    const emailParameter = window.location.search
    const email = emailParameter.split("=")[1]

    useEffect(() => {
        getSecurityQuestionsByUser(email)       
    }, [])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>)  => {
        e.preventDefault()
        validateSecurityQuestion(email)
    }

    if(state.cargando){
        return <Loader />
    }

    return (
        <Box component='form' onSubmit={handleSubmit}>
            <Grid container justifyContent='space-between' alignItems='center'>
                <Grid sx={{ display: { xs: 'none', sm: 'flex' } }} xs={0} sm={6} md={6} item>
                    <img src={ToothImg} alt='Logo' width='100%' />
                </Grid>
                <GridContainer xs={12} sm={6} md={6} item>
                    <Box sx={{textAlign: 'center'}}>
                        <img src={Logo} width='130px' alt='Logo' />
                    </Box>
                    <TypographyTitle variant="h5">Vamos a recuperar tu contraseña</TypographyTitle>
                    <TypographySubitle variant="body2">Responde estas tres preguntas para que podamos validar tu identidad en el sitio.</TypographySubitle>

                    <Box mt={5}>
                        {state.listQuestionsByUser.length ?
                            state.listQuestionsByUser.map((questions, index) => {
                                return <Box key={questions.securityQuestionsId} mb={5}>
                                    <TypographyQuestion variant="body2">{questions.question}</TypographyQuestion>
                                    <TextField
                                        fullWidth
                                        variant="standard" 
                                        name={`answer${index}`}
                                        required
                                        onChange={(e) => handleChangeAnswers(e)}
                                        margin="dense"
                                    />
                                </Box>
                            })
                        : null}
                        <Grid container justifyContent='center'>
                            <Grid item xs={6} sm={6} md={6}>
                                <Button color='secondary' child='Validar identidad' variant='contained' type='submit' />
                            </Grid>
                        </Grid>
                    </Box>
                </GridContainer>
            </Grid>
            <InvalidIdentity open={state.invalidIdentity} handleCloseInvalidIdentity={handleCloseInvalidIdentity}/>
        </Box>
    )
}

export default Index