import { useContext, useEffect, useState } from "react"
import { Box, Tabs, Tab } from '@mui/material'
import DentalRecordsContext from "../context/DentalRecords/DentalRecordsContext"
import Loader from "../Loader";
import { useParams } from "react-router-dom";
import ViewOdontogram from "./ViewOdontogram";
import AttentionSheet from "./AttentionSheet";
import EditDetail from "./EditDetail";
import ModalImportOdontogram from "./ModalImportOdontogram/ModalImportOdontogram";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface DentalRecordFile {
    dentalFile: string;
    dentalRecordsId: 0;
    dentalRecordFileId: 0
  }
 

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}
  
function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Edit = () => {
   const {id, status} = useParams();
   const [dentalRecordsId, setDentalRecordsId] = useState<number>(0);
   const { 
        state, 
        getDentalRecordsById,
        getAffiliates,
        getProfessionals,
        getEnrollment,
        getProviderTypes,
        getInsurances,
        getInsuranceStatus, 
        getSexTypes, 
        getDocumentTypes, 
        handleChangeInputAdd, 
        getDentalDetail,
        getProvincias,
        getDepartamentos,
        getLocalidades,
        handleChangeSelect,
        handleChangeUbicacion,
        saveDentalRecords,
        pushTreatmentsTable,
        removeTreatments,
        pushProviders,
        handleChangeDatePicker,
        getUserCircles,
        handleClickCheck,handleClickCheckHealthyMouth,
        getPlanCodes,
        getIvaTypes,
        getProviderByAffiliateCode, 
        customGetDentalRecordsById,
        modifyDentalRecord
    } = useContext(DentalRecordsContext)

    const {code, insurancesIdFopbaInsider, insuranceId} = useParams();
    
    useEffect(() => {
        if(insurancesIdFopbaInsider ||insuranceId)getAffiliates(code ? code : '', Number(insurancesIdFopbaInsider), Number(insuranceId))
        getProfessionals()
        getEnrollment()
        getProviderTypes()
        getInsurances()
        getInsuranceStatus()
        getDentalDetail()
        getSexTypes()
        getDocumentTypes()
        getProvincias()
        getUserCircles()
        getIvaTypes()
        getDentalRecordsById(Number(id))
        getDepartamentos(state.affiliateData.state);
        getLocalidades(state.affiliateData.state, state.affiliateData.department);
        getProviderByAffiliateCode(code ? code : '')

    }, [])
    useEffect(() => {
    if(Number(insuranceId) || state?.items?.patientDetail?.insuranceId)getPlanCodes(Number(insuranceId ??  state.items.patientDetail.insuranceId))
    }, [state?.items?.patientDetail?.insuranceId])

    useEffect(() => {
        getDepartamentos(state.affiliateData.state);
        getLocalidades(state.affiliateData.state, state.affiliateData.department);
    }, [state.items.patientDetail.state, state.items.patientDetail.department])

    const [files, setFiles] = useState<File[]>([]);

    useEffect(() => {
        const convertToBase64 = async () => {
        const base64Results: DentalRecordFile[] = [];
        for (const file of files) {
            const base64Data = await readFileAsDataURL(file);
            base64Results.push({ dentalFile: base64Data, dentalRecordFileId: 0, dentalRecordsId: 0 });
        }
            state.items.dentalRecordsFiles = base64Results
        }
        convertToBase64();
      }, [files]);

      const readFileAsDataURL = (file: File): Promise<string> => {
        return new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            if (reader.result) {
              const base64Data = reader.result.toString();
              resolve(base64Data);
            }
          };
          reader.readAsDataURL(file);
        });
      };

      const handleDeleteArchives = () => {
        setFiles([])
        state.items.dentalRecordFile = []
      };

      const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
          const newFiles: File[] = Array.from(event.target.files);
          setFiles((prevFiles) => [...prevFiles, ...newFiles]);
        }
      };

    // recorro array de localidades y me fijo si hay algun nombre repetido y lo saco del array
    let arrayLocalidad: any[] = [];
    if (state.localidades.length) {
        state.localidades.map((l) => {
            if (!arrayLocalidad.some(item => item.name === l.name.charAt(0) + l.name.slice(1).toLowerCase())) {
                arrayLocalidad.push({name: l.name.charAt(0) + l.name.slice(1).toLowerCase(), townsId: l.townsId});
            }
        });
    }
    const localidadesFormat = arrayLocalidad;

    const [value, setValue] = useState(0);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    if(state.loading && state.loadingModal){
        return <Loader />
    }

    const provinciaSelected = state.provincias.find(p => p.statesId === Number(state.affiliateData.state) ? Number(state.affiliateData.state) : null)
    const departamentSelected = state.departamentos.find(d => d.departmentsId === Number(state.affiliateData.department) ? Number(state.affiliateData.department) : null)
    const localidadSelected = state.localidades.find(l => l.townsId  === Number(state.affiliateData.town) ? Number(state.affiliateData.town) : null)

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', mt: 2 }}>
                <Tabs value={value} textColor="inherit" onChange={handleChangeTab} aria-label="basic tabs example">
                    <Tab label="Ficha de atención" {...a11yProps(0)} sx={value === 0 ? {background: '#3B72FF', color: '#fff', borderTopLeftRadius: 20} : {border: 1, borderColor: 'inherit', borderTopLeftRadius: 20}}/>
                    <Tab label="Odontograma" {...a11yProps(1)} sx={value === 1 ? {background: '#3B72FF', color: '#fff', borderTopRightRadius: 20} : {border: 1, borderColor: 'inherit', borderTopRightRadius: 20}} />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <EditDetail 
                    state={state} 
                    handleChangeInputAdd={handleChangeInputAdd}
                    handleClickCheck={handleClickCheck}
                    saveDentalRecords={saveDentalRecords}
                    handleChangeSelect={handleChangeSelect}
                    handleChangeUbicacion={handleChangeUbicacion}
                    getLocalidades={getLocalidades}
                    removeTreatments={removeTreatments}
                    pushTreatmentsTable={pushTreatmentsTable}
                    handleChangeDatePicker={handleChangeDatePicker}
                    handleFileChange={handleFileChange}
                    handleDeleteArchives={handleDeleteArchives}
                    provinciaSelected={provinciaSelected}
                    departamentSelected={departamentSelected}
                    localidadSelected={localidadSelected}
                    localidadesFormat={localidadesFormat}
                    insuranceId={Number(insuranceId)}
                    filesTypeFile={files}
                    modifyDentalRecord={modifyDentalRecord}
                />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <ViewOdontogram 
                    state={state}
                    handleChangeInputAdd={handleChangeInputAdd}
                    handleClickCheck={handleClickCheck}
                    handleClickCheckHealthyMouth={handleClickCheckHealthyMouth}
                    saveDentalRecords={saveDentalRecords}
                    handleChangeSelect={handleChangeSelect}
                    handleChangeUbicacion={handleChangeUbicacion}
                    getLocalidades={getLocalidades}
                    pushProviders={pushProviders}
                    handleChangeDatePicker={handleChangeDatePicker}
                    provinciaSelected={provinciaSelected}
                    departamentSelected={departamentSelected}
                    localidadSelected={localidadSelected}
                    localidadesFormat={localidadesFormat}
                    insuranceId={Number(insuranceId)}
                    customGetDentalRecordsById={customGetDentalRecordsById}
                    providerByAffiliateCode={state?.providerByAffiliateCode}
                    setDentalRecordsId={setDentalRecordsId}
                    dentalRecordsId={dentalRecordsId}
                    modifyDentalRecord={modifyDentalRecord}               
                    />
            </CustomTabPanel>
        </Box>
    )
}

export default Edit