import { useState } from "react"
import Container from '../Container'
import { Box, Divider, Grid, Paper, TextField, Typography, MenuItem, InputLabel, Select, SelectChangeEvent} from '@mui/material';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableFooter, TablePagination } from '@mui/material';
import MuiButton from '@mui/material/Button';
import TableCell,{ tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import Pagination from "../Pagination";
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '../Button';
import { DentalRecordsState, DataDepartamento, DataLocalidad, DataProvincia } from "../../interfaces/DentalRecords";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'moment/locale/es';
import DeleteIcon from '@mui/icons-material/Delete';
import Confirm from "../Confirm";
import moment from "moment";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


const TextFieldStyled = styled(TextField)(() => ({
    '.MuiInputLabel-root': {
        color: '#3B72FF',
        'span': {
            color: 'red'
        }
    },
}));

const TypographyStyled = styled(Typography)(() => ({
    color: '#3B72FF',
    fontWeight: 'bolder',
    marginRight: 8
}));

const InputLabelStyled = styled(InputLabel)(() => ({
    color: '#3B72FF',
    'span': {
        color: 'red'
    }
}));

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#3B72FF',
      color: 'white',
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#eafaff',
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
}));

const BoxStyled = styled(Box)(() => ({
    border: '1px dashed #3B72FF',
    padding: '20px 10px', 
    display: 'flex', 
    justifyContent: 'center', 
    position: 'relative', 
    borderRadius: '5px',
    marginTop: '10px'
}));

interface Props {
    state: DentalRecordsState
    handleChangeInputAdd: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleClickCheck: (event: React.ChangeEvent<HTMLInputElement>) => void
    saveDentalRecords: (insuranceId: number) => void
    handleChangeSelect: (event:SelectChangeEvent<string | number | number[] | string[]> ) => void
    handleChangeUbicacion: (value: any, name: string) => void
    getLocalidades: (state: string, department: string) => void
    removeTreatments: (idToDelete: number) => void
    pushTreatmentsTable: () => void
    handleChangeDatePicker: (value: string, name: string) => void
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    handleDeleteArchives: () => void
    provinciaSelected: DataProvincia | undefined
    departamentSelected: DataDepartamento | undefined
    localidadSelected: DataLocalidad | undefined
    localidadesFormat: any[]
    insuranceId: number
    filesTypeFile: File[]
}

const AttentionSheet = (props: Props) => {
    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [openConfirm, handleOpenConfirm] = useState(false)
    const [idToDelete, LoadIdToDelete] = useState(0)
    const [showMessage, setShowMessage] = useState(false)

    const circleInfo = props.state.userCircles.find(item => item.circleId === props.state.userCircleSelected)
    const insuranceInfo = props.state.dataInsurance.find(item => item.insurancesId === props.insuranceId)

    const handleConfirmDelete = () => {
        props.removeTreatments(idToDelete)
        handleOpenConfirm(false)
    }

    const handleDeleteArchive = () => {
        props.handleDeleteArchives()
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        props.saveDentalRecords(props.insuranceId)
    }
    
    let twoEnrollments = false
    if(props.state.professionalItems.nationalEnrollment && props.state.professionalItems.provincialEnrollment){
        twoEnrollments = true
    }
    //en el caso de que solamente venga la matricula provincial seteo el valor de la matricula en el estado enrollment
    if(props.state.professionalItems.provincialEnrollment && !props.state.professionalItems.nationalEnrollment){
        props.state.items.professionalDetail.enrollment = props.state.professionalItems.provincialEnrollment
    }

    let showPieceByAge = []
    if(props.state.affiliateData.age >= 13){
        showPieceByAge = props.state.dentalDetail.pieces.filter(item => item.isAdult === true)
    } else {
        showPieceByAge = props.state.dentalDetail.pieces.filter(item => item.isAdult === false)
    }

    if(Number(props.localidadSelected?.postalCode) !== 0){
        props.state.affiliateData.postalCode = props.localidadSelected?.postalCode ? props.localidadSelected.postalCode : ''
    }

    const queryString = window.location.search;
    const queryStringWithoutQuestionMark = queryString.slice(1);
    const queryParams = queryStringWithoutQuestionMark.split('&');
    
    const params: { [key: string]: string } = {};

    queryParams.forEach(queryParam => {
    const [key, value] = queryParam.split('=');
    params[key] = value;
    });

    props.state.items.bond = params['bond'];
    props.state.items.token = params['token'];
    props.state.items.createDate = params['createDate'];
    return <Container
        child={
            <Box 
                component='form' 
                onSubmit={handleSubmit}
            >
                <Box sx={{background: "#BEEBFF", padding: '15px', borderRadius: 2}}>
                    <Grid container justifyContent='space-between'>
                        <Grid item>
                            <Typography sx={{fontWeight: 'bold'}}>F.O.P.B.A</Typography>
                        </Grid>
                        <Grid item>
                            <Typography sx={{fontWeight: 'bold'}}>Fecha de Creación: {props.state.items.createDate}</Typography>
                        </Grid>
                        {props.state.items.bond ?
                        <Grid item>
                            <Typography sx={{fontWeight: 'bold'}}>N° de Bono: {props.state.items.bond}</Typography>
                        </Grid>
                        : null}
                        {props.state.items.token ? 
                        <Grid item>
                            <Typography sx={{fontWeight: 'bold'}}>Token: {props.state.items.token}</Typography>
                        </Grid>
                        : null}
                    </Grid>
                </Box>
                <h4>Datos Personales del Paciente</h4>
                <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                <Grid container spacing={3} mb={3}>
                    <Grid item container xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            label="Nombre"
                            name='name'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.name}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item container xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            label="Apellido"
                            name='lastname'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.lastname}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item container xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            label="N° Afiliado"
                            name='affiliateId'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.affiliateId}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item container xs={6} sm={6} md={2}> 
                        <FormControl fullWidth variant='outlined' required>
                            <InputLabelStyled>Tipo de Documento</InputLabelStyled>
                            <Select
                                label="Tipo de Documento"
                                name='documentTypeId'
                                value={props.state.affiliateData.documentType ? props.state.affiliateData.documentType : ''} 
                                onChange={props.handleChangeSelect}
                            >
                                {props.state.documentTypesList.map((item) => (
                                    <MenuItem key={item.documentTypeId} value={item.documentTypeId}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item container xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="N° de Documento"
                            name='documentValue'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.documentValue}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item container xs={6} sm={6} md={2}>
                        <FormControl fullWidth variant='outlined' required>
                            <InputLabelStyled>Sexo</InputLabelStyled>
                            <Select
                                label="Sexo"
                                name='sexTypeId'
                                value={props.state.affiliateData.sexTypeId ? props.state.affiliateData.sexTypeId : ''} 
                                onChange={props.handleChangeSelect}
                            >
                                {props.state.sexTypesList.map((item) => (
                                    <MenuItem key={item.sexTypeId} value={item.sexTypeId}>
                                        {item.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item container xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="Teléfono / Celular"
                            name='phone'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.phone}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item container xs={6} sm={3} md={2}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Fecha de Nacimiento"
                                value={props.state.affiliateData.bornDate ? props.state.affiliateData.bornDate : null}
                                onChange={(newValue) => props.handleChangeDatePicker(moment(newValue).format('YYYY-MM-DD'), 'bornDate')}
                                renderInput={(params) => <TextFieldStyled {...params} required variant='outlined' fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                        <FormControl fullWidth variant='outlined' required>
                            <InputLabelStyled>Condición Fiscal</InputLabelStyled>
                            <Select
                                label="Condición Fiscal"
                                name='ivaTypeId'
                                value={props.state.affiliateData.ivaTypeId ? props.state.affiliateData.ivaTypeId : ''} 
                                onChange={props.handleChangeSelect}
                            >
                                {props.state.ivaTypesData.map((item) => (
                                    <MenuItem key={item.ivaTypeId} value={item.ivaTypeId}>
                                        {item.description}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                value={{
                                    statesId: props.provinciaSelected?.statesId ? props.provinciaSelected.statesId : 0,
                                    name: props.provinciaSelected?.name ? props.provinciaSelected.name : ''
                                }}
                                onChange={(event, newValue) =>
                                    props.handleChangeUbicacion(newValue?.statesId ? newValue.statesId : 0, "state")
                                }
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                options={props.state.provincias}
                                renderInput={(params) => (
                                    <TextFieldStyled
                                        {...params}
                                        key={params.id}
                                        required
                                        label="Provincia"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                value={{
                                    departmentsId: props.departamentSelected?.departmentsId ? props.departamentSelected.departmentsId : 0,
                                    name: props.departamentSelected?.name ? props.departamentSelected.name : ''
                                }}
                                onChange={(event, newValue) =>
                                    props.handleChangeUbicacion(newValue?.departmentsId ? newValue.departmentsId : 0, "department")
                                }
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                options={props.state.departamentos}
                                renderInput={(params) => (
                                    <TextFieldStyled
                                        {...params}
                                        key={params.id}
                                        required
                                        label="Partido"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl fullWidth>
                            <Autocomplete
                                value={{
                                    townsId: props.localidadSelected?.townsId ? props.localidadSelected.townsId : 0,
                                    name: props.localidadSelected?.name ? props.localidadSelected.name.charAt(0) + props.localidadSelected.name.slice(1).toLowerCase() : ''
                                }}
                                onOpen={!props.state.localidades.length ? () => props.getLocalidades(props.state.affiliateData.state, props.state.affiliateData.department) : () => {}}
                                onChange={(event, newValue) =>
                                    props.handleChangeUbicacion(newValue?.townsId ? newValue.townsId : 0, "town")
                                }
                                getOptionLabel={(option) => {
                                    return option.name;
                                }}
                                options={props.localidadesFormat}
                                renderInput={(params) => (
                                    <TextFieldStyled
                                        {...params}
                                        key={params.id}
                                        required
                                        label="Localidad"
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item container xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            label="Calle"
                            name='street'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.street}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="Número"
                            name='streetNumber'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.streetNumber && props.state.affiliateData.streetNumber !== "0" ? props.state.affiliateData.streetNumber : ''}
                            onChange={props.handleChangeInputAdd}
                        />
                        <FormControlLabel control={<Checkbox onChange={props.handleClickCheck} checked={props.state.affiliateData.streetNumber === 'S/N'} />} label="Sin Número" />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="Piso"
                            name='floor'
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.floor && props.state.affiliateData.floor !== "0" ? props.state.affiliateData.floor : ''}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="Depto"
                            name='apartment'
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.apartment && props.state.affiliateData.apartment !== "0" ? props.state.affiliateData.apartment : ''}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={2}>
                        <TextFieldStyled
                            label="Código Postal"
                            name='postalCode'
                            required
                            variant="outlined"
                            fullWidth
                            value={props.state.affiliateData.postalCode ? props.state.affiliateData.postalCode : ' '}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                </Grid>

                <Box sx={{background: "#BEEBFF", padding: '15px', borderRadius: 2}} mb={3}>
                    <Grid container justifyContent='space-between'>
                        <Grid item container md={3}>
                            <TypographyStyled>Obra Social:</TypographyStyled>{insuranceInfo?.idFopbaInsider} - {insuranceInfo?.name}
                        </Grid>
                        <Grid item container md={6}>
                            <TypographyStyled>Entidad Primaria:</TypographyStyled>{circleInfo?.idFopbaInsider} - {circleInfo?.name}
                        </Grid>
                    </Grid>
                </Box>

                <Grid container spacing={2} mb={2}>
                <Grid item xs={6} sm={6} md={4}>
                        <FormControl fullWidth variant='outlined' required={!props.state.items.treatments.length}>
                            <InputLabelStyled>Nomenclador</InputLabelStyled>
                            <Select
                                label="Nomenclador"
                                name='code'
                                required={!props.state.items.treatments.length}
                                value={props.state.items.treatmentsItems.code ? props.state.items.treatmentsItems.code : ''} 
                                onChange={props.handleChangeSelect}
                            >
                                {
                                    props.state.plans.map(plan => (
                                        <MenuItem value={plan.codesId} key={plan.codesId}>{`(${plan?.textCode ?? ''}) - ${plan.description}`}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl fullWidth variant='outlined' required={!props.state.items.treatments.length}>
                            <InputLabelStyled>Pieza</InputLabelStyled>
                            <Select
                                label="Pieza"
                                name='pieceId'
                                value={props.state.items.treatmentsItems.pieceId ? props.state.items.treatmentsItems.pieceId : ''} 
                                onChange={props.handleChangeSelect}
                            >
                                {
                                    showPieceByAge.map(piece => (
                                        <MenuItem value={piece.piecesId} key={piece.piecesId}>{piece.pieceNumber} - {piece.description}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <FormControl fullWidth variant='outlined' required={!props.state.items.treatments.length}>
                            <InputLabelStyled>Cara</InputLabelStyled>
                            <Select
                                label="Cara"
                                name='faceId'
                                multiple={true}
                                value={props.state.items.treatmentsItems.faceId ? props.state.items.treatmentsItems.faceId : [] as any} 
                                onChange={props?.handleChangeSelect}
                            >
                                {
                                    props?.state?.dentalDetail?.faces?.map(face => (
                                        <MenuItem value={face.facesId} key={face.facesId}>{face.description} - {face.name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {props.state.items.treatments.length ? 
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>  
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Código</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Pieza</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Cara</StyledTableCell>
                                    <StyledTableCell sx={{padding: '12px'}} align="center">Acción</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                      
                                {(rowsPerPage > 0 
                                    ? props.state.items.treatments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    : props.state.items.treatments
                                ).map((row) => {
                                    const planArr = props.state.plans.find(item => item.codesId === row.code);
                                    return (
                                        <StyledTableRow
                                            key={row.treatmentsId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell sx={{padding: '5px'}} align="center">{`${planArr?.textCode ?? ''} - ${planArr?.description}`}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '5px'}} component="th" scope="row" align="center">{props.state.dentalDetail.pieces.find(item => item.piecesId === row.pieceId)?.description}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '5px'}} align="center">{row?.faceName}</StyledTableCell>
                                            <StyledTableCell sx={{padding: '5px'}} align="center">
                                                <DeleteIcon color="error" sx={{cursor: 'pointer'}} onClick={() => {handleOpenConfirm(true); LoadIdToDelete(row.treatmentsId)}}/>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: 'Completo', value: -1 }]}
                                        colSpan={3}
                                        count={props.state.items.treatments.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': 'Filas por pagina',
                                            },
                                            native: true,
                                        }}
                                        labelRowsPerPage='Filas por página'
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        ActionsComponent={() => <Pagination count={props.state.items.treatments.length} page={page} rowsPerPage={rowsPerPage} onPageChange={handleChangePage} />}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                : null}

                <Grid container justifyContent='space-between' alignItems='center' spacing={3} pt={1} pb={2}>
                    <Grid item xs={6} sm={6} md={6}>
                        <MuiButton onClick={props.pushTreatmentsTable} sx={{textTransform: 'inherit', justifyContent: 'flex-start'}}>
                            <AddCircleIcon />
                            <Typography sx={{marginLeft: 1}}>Agregar Nueva Fila</Typography>
                        </MuiButton>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <TextFieldStyled
                            label="Diagnóstico General"
                            multiline
                            rows={3}
                            name='observers'
                            variant="outlined"
                            fullWidth
                            sx={{mt: 3}}
                            value={props.state.items.observers}
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={12}>
                    <BoxStyled>
                        {props.filesTypeFile.length == 0 ? 
                        <Typography sx={{color: '#3B72FF'}}>
                            Presione o arrastre archivos a esta área para añadir documentos.
                        </Typography>
                        : 
                        <Box>
                        <Typography fontWeight={'700'}>Archivos Cargados: </Typography>
                            {props.filesTypeFile.map((file, index) => (
                                <Typography key={index}>{file.name}</Typography>
                            ))}
                        </Box>
                        }
                        <input
                            multiple
                            type="file" 
                            onChange={props.handleFileChange}
                            style={{
                                opacity: 0,
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                cursor: 'pointer'
                            }}
                        />
                    </BoxStyled>
                </Grid>
                <Grid>
                    {props.filesTypeFile.length > 0 ?
                    <Grid item mt={2}>
                        <MuiButton variant="contained" onClick={handleDeleteArchive}>
                            Eliminar Archivos
                        </MuiButton>
                    </Grid>
                    : null}
                </Grid>                
                <h4>Datos Personales del Profesional</h4>
                <Divider sx={{borderColor: '#64AAB2', mb: 2}} />
                <Grid container spacing={3} mb={5}>
                    <Grid item xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            label="Nombre"
                            variant="outlined"
                            fullWidth
                            required
                            value={props.state.items.professionalDetail.professionalName}
                            name='professionalName'
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} md={4}>
                        <TextFieldStyled
                            label="Apellido"
                            variant="outlined"
                            fullWidth
                            required
                            value={props.state.items.professionalDetail.professionalLastname}
                            name='professionalLastname'
                            onChange={props.handleChangeInputAdd}
                        />
                    </Grid>
                    {twoEnrollments ?
                        <Grid item xs={6} sm={6} md={4}>
                            <FormControl fullWidth variant='outlined' required>
                                <InputLabelStyled>Matricula</InputLabelStyled>
                                <Select
                                    label="Matricula"
                                    name='enrollmentTypeAdd'
                                    value={props.state.items.professionalDetail.enrollmentType ? props.state.items.professionalDetail.enrollmentType : ''} 
                                    onChange={props.handleChangeSelect}
                                >
                                    <MenuItem value={'national-' + props.state.professionalItems.nationalEnrollment}>{'Nacional - ' + props.state.professionalItems.nationalEnrollment}</MenuItem>
                                    <MenuItem value={'provincial-' + props.state.professionalItems.provincialEnrollment}>{'Provincial - ' + props.state.professionalItems.provincialEnrollment}</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    : 
                        <Grid item xs={6} sm={6} md={4}>
                            <TextFieldStyled
                                label="N° Matrícula"
                                name='enrollment'
                                variant="outlined"
                                required
                                fullWidth
                                value={props.state.professionalItems.provincialEnrollment}
                                onChange={props.handleChangeInputAdd}
                            />
                        </Grid>
                    }
                </Grid>

                <Grid container justifyContent='center' mt={4} spacing={2}>
                    <Grid item xs={4} sm={4} md={4}>
                        <Button 
                            type="submit"
                            child='Guardar' 
                            variant='contained' 
                            color='primary'
                        />
                    </Grid>
                </Grid>
                {showMessage ? <Typography color='error' align="center" mt={2}>Debes seleccionar al menos un tratamiento a realizar en el odontograma</Typography> : null}
                <Confirm
                    openConfirm={openConfirm}
                    handleCloseConfirm={() => handleOpenConfirm(false)}
                    handleConfirm={() => handleConfirmDelete()}
                    title='¿Estas seguro?'
                    description='Si eliminas este tratamiento no podrás volver a recuperarlo luego'
                />
            </Box>
        }
    />
}

export default AttentionSheet