import { createContext } from 'react'
import { SelectChangeEvent } from '@mui/material';
import { LoginState } from '../../../interfaces/Login';

export interface ContextProps {
    state: LoginState
    doLogin: (user: string, contraseña: string) => void
    changePassword: (password: string, newPassword: string, email?: string | number) => void
    handleChangeValues: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    getSecurityQuestions: () => void
    getSecurityQuestionsByUser: (email: string) => void
    validateSecurityQuestion: (email: string) => void
    handleChangeAnswerField: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    handleChangeSelect: (event: SelectChangeEvent<string>) => void
    questionSelectedId: (id: number) => void
    addSecurityQuestions: () => void
    handleChangeAnswers: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    clickRecoverPassword: () => void
    handleCloseInvalidIdentity: () => void
}

const LoginContext = createContext<ContextProps>({} as ContextProps);

export default LoginContext;