import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LoginContext from "../components/context/Login/LoginContext"
import Logo from '../img/logo.png'
import { useContext, useState } from "react"
import Loader from './Loader';

const NewPassword = () => {

    const { state, changePassword, handleChangeValues } = useContext(LoginContext)

    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const emailParameter = window.location.search
    const email = emailParameter.split("=")[1]

    const hasNumber = (str: string) => str.toString().search(/[\d]/)>=0;

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>)  => {
        e.preventDefault()
        if(hasNumber(state.password)){ 
            setShowMessage(false)
            changePassword(state.password, state.newPassword, email)
        } else {
            setShowMessage(true)
        }
    }

    if(state.cargando){
        return <Loader />
    }

    return (
        <Box>
            <Grid container justifyContent='space-evenly'>
                <Grid md={2} item textAlign='end' mt={2}>
                    <img src={Logo} alt='Logo' />
                </Grid>
                <Grid md={10} item>
                    <Paper elevation={3} sx={{ width: {sm: '90%', md: '70%'}, marginLeft: {sm: '0%', md: '6%'}, borderRadius: 10}}>
                        <Box textAlign='center' mb={6} mt={2} pt={2} pr={1} pl={1}>
                            <Typography variant="h5" mb={4} sx={{fontWeight: 700}}>Nueva Contraseña</Typography>
                            <Typography>Creá una nueva contraseña  teniendo en cuenta:</Typography>
                            <ul style={{display: 'table', margin: '0 auto', textAlign: 'left'}}>
                                <li>Debe tener como mínimo 6 caracteres</li>
                                <li>Debe contener al menos 1 número</li>
                            </ul>
                        </Box>
                            
                        <Box 
                            component='form' 
                            onSubmit={handleSubmit}
                            className='formContainer'
                        >
                            <FormControl sx={{ width: '70%' }} variant="standard">
                                <InputLabel htmlFor="standard-adornment-password">Contraseña</InputLabel>
                                <Input
                                    onChange={(e) => handleChangeValues(e)}
                                    type={showPassword ? 'text' : 'password'}
                                    inputProps={{ minLength: 6 }}
                                    name="password"
                                    required
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            <FormControl sx={{ width: '70%', mt: 4, mb: 2 }} variant="standard">
                                <InputLabel htmlFor="standard-adornment-password">Repetir contraseña</InputLabel>
                                <Input
                                    onChange={(e) => handleChangeValues(e)}
                                    type={showNewPassword ? 'text' : 'password'}
                                    inputProps={{ minLength: 6 }}
                                    name="newPassword"
                                    required
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowNewPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                            {state.passwordsDontMatch && <Typography color='error' variant="body1">Las contraseñas no coinciden</Typography>}
                            {showMessage && <Typography color='error' variant="body1">La contraseña debe contener al menos un número</Typography>}
                            <Button 
                                type='submit' 
                                variant='contained' 
                                sx={{
                                    background: '#42C5B7',
                                    '&:hover': {background: '#389b90'},
                                    borderRadius: 30,
                                    padding: '5px 40px 5px 40px',
                                    margin: '65px 0px 30px 0px',
                                }}
                            >
                                Siguiente
                            </Button> 
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default NewPassword