import { CircleItems, CircleState, UserCircles, CircleData } from '../../../interfaces/Circles';

type Action = {type: 'getCirculos', payload: CircleData[]}
    | {type: 'getCirculoById', payload: CircleItems}
    | {type: 'getUserCircles', payload: UserCircles[]}
    | {type: 'setUserCircle', payload: number}
    | {type: 'setLoading', payload: boolean}
    | {type: 'filtered', payload: boolean}
    | {type: 'handleChangeFilter', payload: {name: string, value: string}}
    | {type: 'clearFilter'}
    | {type: 'getProvincias', payload: []}
    | {type: 'getDepartamentos', payload: []}
    | {type: 'getLocalidades', payload: []}

export default function CirculoReducer (state: CircleState, action: Action): CircleState {
    switch (action.type) {
        case 'getCirculos':
            return { 
                ...state, 
                data: action.payload,
                loading: false
            }
        case 'getCirculoById':
            state.items = action.payload
            return { 
                ...state, 
                items: state.items,
                loading: false
            }
        case 'getUserCircles':
            const circleUsing = action.payload.filter(item => item.using === true)
            if(circleUsing.length){
                state.userCircleSelected = circleUsing[0].circleId
            }
            return { 
                ...state, 
                userCircles: action.payload
            }
        case 'setUserCircle':
            return {
                ...state,
                userCircleSelected: action.payload
            }
        case 'setLoading':
            return {
                ...state,
                loading: action.payload
            }
        case 'filtered':
            return { 
                ...state, 
                filtered: action.payload
            }
        case 'handleChangeFilter':
            return {
                ...state,
                [action.payload.name]: action.payload.value,
            }
        case 'clearFilter':
            return { 
                ...state, 
                filterCircleName: '',
                filterCircleId: ''
            }
        case 'getProvincias':
            return { 
                ...state, 
                provincias: action.payload
            }
        case 'getDepartamentos':
            return { 
                ...state, 
                departamentos: action.payload
            }
        case 'getLocalidades':
            return { 
                ...state, 
                localidades: action.payload
            }
        default:
            return state;
    }
}